import { createFocusTrap } from 'focus-trap'

import Component from '../../../assets/scripts/modules/component'

export default class OverlayDropdownFilterBarMobile extends Component {
  init() {
    this.overlay = document.querySelector('.overlay-dropdown-filter-bar-mobile')
    this.buttonClose = document.querySelector('.overlay-dropdown-filter-bar-mobile__dialog-close-button')
    this.form = document.querySelector('.overlay-dropdown-filter-bar__form')
    this.datepickerDropdownButton = document.querySelector('.datepicker-dropdown__overlay')
    this.multiselectDropdowns = document.querySelectorAll('.multiselect-dropdown-field__overlay')
    this.initOverlay()
    this.initAccessibility()

    this.form.addEventListener('submit', this.handleSubmit.bind(this))

    this.buttonClose.addEventListener('click', this.handleCloseButton.bind(this))

    document.addEventListener('keydown', event => {
      if (event.key === 'Escape') {
        if (this.multiselectDropdowns) {
          const multiselectDropdownsCLosed = Array.from(this.multiselectDropdowns).every(dropdown => !dropdown.classList.contains('multiselect-dropdown-field__overlay--active'))
          const datePickerDropdownClosed = !this.datepickerDropdownButton.classList.contains('datepicker-dropdown__overlay--active')

          if (multiselectDropdownsCLosed && datePickerDropdownClosed) {
            this.toggleOverlay()
          }
        }
      }
    })
  }

  initOverlay() {
    this.buttonClose.addEventListener('click', () => window.requestAnimationFrame(() => this.toggleOverlay()))
    this.element.addEventListener('open-mobile-filter-bar-overlay', () => window.requestAnimationFrame(() => this.toggleOverlay()))
  }

  initAccessibility() {
    this.setAriaHiddenOnOtherElements(false)
    this.hideOverlayTabbableElements(true)

    this.overlayFocusTrap = createFocusTrap(this.overlay, {
      onActivate: () => {
        this.overlay.focus()
        this.overlay.blur()
      },
      onDeactivate: () => {
        this.overlay.focus()
      },
      escapeDeactivates: true,
      clickOutsideDeactivates: true,
      returnFocusOnDeactivate: true,
      initialFocus: this.overlay,
    })
  }

  handleCloseButton(e) {
    e.preventDefault()
  }

  toggleOverlay(forceClose = false) {
    const closing = forceClose || document.documentElement.classList.contains('overlay-dropdown-filter-bar-mobile-visible')

    if (document.documentElement.classList.contains('overlay-dropdown-filter-bar-mobile-closing') || document.documentElement.classList.contains('overlay-dropdown-filter-bar-mobile-opening')) {
      return
    }

    document.documentElement.classList.toggle('overlay-dropdown-filter-bar-mobile-visible', !closing)
    document.documentElement.classList.toggle('prevent-scrolling', !closing)

    if (!closing) {
      this.setAriaHiddenOnOtherElements(true)
      this.hideOverlayTabbableElements(false)
      this.overlayFocusTrap.activate()
    } else {
      this.overlayFocusTrap.deactivate()
    }

    if (closing) {
      document.documentElement.classList.add('overlay-dropdown-filter-bar-mobile-closing')
      window.setTimeout(() => {
        document.documentElement.classList.remove('overlay-dropdown-filter-bar-mobile-closing')
        this.setAriaHiddenOnOtherElements(false)
        this.hideOverlayTabbableElements(true)
      }, 350)
    } else {
      document.documentElement.classList.add('overlay-dropdown-filter-bar-mobile-opening')
      window.setTimeout(() => {
        document.documentElement.classList.remove('overlay-dropdown-filter-bar-mobile-opening')
      })
    }
  }

  setAriaHiddenOnOtherElements(hidden = true) {
    const nonModalDialogVisibleElements = [...document.querySelectorAll('.container--content')]
    const modalDialogVisibleElements = [this.element]

    nonModalDialogVisibleElements.forEach(element => element.setAttribute('aria-hidden', hidden))
    modalDialogVisibleElements.forEach(element => element.setAttribute('aria-hidden', !hidden))
  }

  hideOverlayTabbableElements(hidden = true) {
    const focusableElements = [...this.element.querySelectorAll('a[href], button, input, select, textarea')]
    focusableElements.forEach(element => (hidden ? element.setAttribute('tabindex', -1) : element.removeAttribute('tabindex')))
  }

  handleSubmit(e) {
    this.toggleOverlay()
    document.documentElement.classList.remove('overlay-dropdown-filter-bar-mobile-visible')
    document.documentElement.classList.remove('prevent-scrolling')
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.overlay-dropdown-filter-bar-mobile').forEach(element => {
    element.instance = element.instance || new OverlayDropdownFilterBarMobile(element)
  })
)
