import Component from '../../../assets/scripts/modules/component'
// import { customEventNames } from '../../../utils/custom-event-names'

class SelectedFiltersBarComponent extends Component {
  init() {
    // const buttons = this.element.querySelectorAll('.selected-filters-bar__filter-btn')
    // const clearBtn = this.element.querySelector('#selected-filters-bar-clear-btn')
    // const rootElement = this.element

    // const removeFilterFromQueryString = filterToRemove => {
    //   const url = new URL(window.location.href)
    //   const searchParams = new URLSearchParams(url.search)
    //   const isDateFilter = filterToRemove.startsWith('date-')

    //   // For dates we need tor remove all date filters
    //   if (isDateFilter) {
    //     searchParams.delete('from_date')
    //     searchParams.delete('to_date')
    //     searchParams.delete('period')
    //   } else {
    //     // For all other filters we only remove the filter from the list
    //     searchParams.forEach((value, key) => {
    //       const values = value.split(',')
    //       if (values.includes(filterToRemove)) {
    //         values.splice(values.indexOf(filterToRemove), 1)
    //         searchParams.set(key, values.join(','))

    //         if (values.length === 0) {
    //           searchParams.delete(key)
    //         }
    //       }
    //     })
    //   }

    //   // Reset the query string
    //   url.search = searchParams.toString().replace(/%2C/g, ',')
    //   window.history.replaceState({}, '', url.toString())

    //   // Dispatch events
    //   if (isDateFilter) {
    //     dispatchEvent(new CustomEvent(customEventNames.whatsOnDateQueryRemoved))
    //   } else {
    //     dispatchEvent(new CustomEvent(customEventNames.whatsOnQueryFilterRemoved, { detail: { removedFilter: filterToRemove } }))
    //   }
    // }

    // const removeFilter = e => {
    //   e.preventDefault()
    //   const buttons = this.element.querySelectorAll('.selected-filters-bar__filter-btn')
    //   const btn = e.currentTarget
    //   const id = btn.id

    //   btn.remove()
    //   if (buttons.length === 2) {
    //     clearBtn.remove()
    //   }

    //   removeFilterFromQueryString(id)
    //   dispatchEvent(new CustomEvent(customEventNames.whatsOnUpdateGrid))
    // }

    // const clearAllFilters = e => {
    //   e.preventDefault()
    //   // remove all buttons in the UI
    //   const buttons = rootElement.querySelectorAll('.selected-filters-bar__filter-btn')
    //   clearBtn.remove()
    //   buttons.forEach(btn => {
    //     btn.remove()
    //   })
    //
    //   // clear the query param
    //   const url = new URL(window.location.href)
    //   url.search = ''
    //   window.history.replaceState({}, '', url.toString())
    //
    //   dispatchEvent(new Event(customEventNames.whatsOnClearedAllFilters))
    //   dispatchEvent(new CustomEvent(customEventNames.whatsOnUpdateGrid))
    // }
    //
    // if (clearBtn) {
    //   clearBtn.addEventListener('click', clearAllFilters)
    // }

    // if (buttons) {
    //   Array.from(buttons).forEach(btn => {
    //     btn.addEventListener('click', removeFilter)
    //   })
    // }
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.selected-filters-bar').forEach((element, index) => {
    element.instance = element.instance || new SelectedFiltersBarComponent(element, index)
  })
)
