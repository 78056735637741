import Component from '../../../assets/scripts/modules/component'
import scrollIfNeeded from '../../../assets/scripts/utilities/scroll-if-needed'

class FilterDateBarComponent extends Component {
  init() {
    this.isNMOQ = document.querySelector('body').classList.contains('subsite--nmoq')
    this.initFilterDisclosure()
    this.initDateDisclosure()
    this.initDatePicker()

    window.addEventListener('init-after-load', () => scrollIfNeeded(this.element))
  }

  initFilterDisclosure() {
    const button = this.element.querySelector('.filter-date-bar__disclosure-button--filter')
    const region = this.element.querySelector('.filter-date-bar__disclosure-content-container--filter')

    if (!button || !region) {
      return
    }

    button.addEventListener('click', () => this.toggleRegion(region, button))
    this.setTabIndexes(region, true)

    if (this.isNMOQ) {
      this.element.querySelector('.filter-date-bar__filter-buttons a.button:first-of-type').classList.add('is-first')
      this.element.querySelector('.filter-date-bar__filter-buttons a.button:last-of-type').classList.add('is-last')
    }
  }

  initDateDisclosure() {
    const button = this.element.querySelector('.filter-date-bar__disclosure-button--date')
    const region = this.element.querySelector('.filter-date-bar__disclosure-content-container--date')

    if (!button || !region) {
      return
    }

    button.addEventListener('click', () => this.toggleRegion(region, button))
    this.setTabIndexes(region, true)
  }

  toggleRegion(region, button) {
    if (!region || !button) {
      return
    }

    const isCollapsing = button.getAttribute('aria-expanded') === 'true'

    if (!isCollapsing && region.className.indexOf('date') !== -1) {
      // This datepicker is sometimes hard to initialize so THOR'S HAMMER TO THE RESCUE -EKL
      window.requestAnimationFrame(() => {
        if (this.element.querySelector('.datepicker')) {
          this.element.querySelector('.datepicker').dispatchEvent(new CustomEvent('activate'))
        }
        if (this.element.querySelector('.datepicker-range')) {
          this.element.querySelector('.datepicker-range').dispatchEvent(new CustomEvent('activate'))
        }
      })
      window.setTimeout(() => window.requestAnimationFrame(() => this.element.querySelector('.datepicker').dispatchEvent(new CustomEvent('activate'))), 1000)
    }

    region.setAttribute('aria-hidden', isCollapsing)
    button.setAttribute('aria-expanded', !isCollapsing)

    this.setTabIndexes(region, isCollapsing)

    this.element.classList.toggle('is-all-filters-visible')

    if (!this.isNMOQ) {
      return
    }

    const clickedDateFilter = button.classList.contains('filter-date-bar__disclosure-button--date')

    // Toggle classes for NMOQ -CMT
    if (!isCollapsing && !clickedDateFilter) {
      this.element.querySelector('.filter-date-bar__filter-buttons a.button.is-last').classList.remove('is-last')
      this.element.querySelector('.filter-date-bar__disclosure-content-container a.button:last-of-type').classList.add('is-last')
    } else if (isCollapsing && !clickedDateFilter) {
      this.element.querySelector('.filter-date-bar__filter-buttons a.button:last-of-type').classList.add('is-last')
      this.element.querySelector('.filter-date-bar__disclosure-content-container a.button.is-last').classList.remove('is-last')
    }
  }

  setTabIndexes(region, isCollapsed) {
    const focusableElements = [...region.querySelectorAll('a[href], button, input, select, textarea')]
    focusableElements.forEach(element => (isCollapsed ? element.setAttribute('tabindex', -1) : element.removeAttribute('tabindex')))
  }

  initDatePicker() {
    this.dateField = this.element.querySelector('#from-date')

    if (!this.dateField) {
      return
    }

    this.dateField.addEventListener('change', event => {
      if (!event.target.value) {
        return
      }

      const params = new URLSearchParams(location.search)
      params.set('date', event.target.value)
      window.history.pushState({}, '', `${location.pathname}?${params}`)
      window.location.reload()
    })
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.filter-date-bar').forEach(element => {
    element.instance = element.instance || new FilterDateBarComponent(element)
  })
)
