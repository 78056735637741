import { createFocusTrap } from 'focus-trap'
import breakpoints from '../../../assets/scripts/modules/breakpoints'
import Component from '../../../assets/scripts/modules/component'

class MultiselectDropdownFieldComponent extends Component {
  init() {
    this.dropdownLabel = this.element.querySelectorAll('.multiselect-dropdown-field__label')
    this.dropdownInput = this.element.querySelectorAll('.multiselect-dropdown-field__input')
    this.dropdownOptions = this.element.querySelectorAll('.multiselect-dropdown-field__options')
    this.clearButton = this.element.querySelector('.multiselect-dropdown-field__panel-clear-btn')
    this.submitButton = this.element.querySelector('.multiselect-dropdown-field__panel-submit-btn')
    this.dropdownButton = this.element.querySelector('.multiselect-dropdown-field__dropdown-button')
    this.dropdownPanel = this.element.querySelector('.multiselect-dropdown-field__panel')
    this.dropdownOverlay = this.element.querySelector('.multiselect-dropdown-field__overlay')

    this.datepickerPanel = document.querySelector('.datepicker-dropdown__panel')
    this.datepickerButton = document.querySelector('.datepicker-dropdown__button')

    this.selectedItems = []

    if (this.submitButton) {
      this.submitButton.addEventListener('click', this.handleSubmitButton.bind(this))
    }

    if (this.clearButton) {
      this.clearButton.addEventListener('click', this.handleClearButton.bind(this))
    }

    if (this.dropdownOverlay) {
      this.dropdownOverlay.addEventListener('click', this.handleOverlay.bind(this))
    }

    if (this.dropdownButton && this.dropdownPanel) {
      this.dropdownButton.addEventListener('click', this.handleDropdownButton.bind(this))
    }

    this.dropdownLabel.forEach(label => {
      label.addEventListener('keydown', this.handleLabelKeydown.bind(this))
    })

    this.dropdownInput.forEach(input => {
      input.addEventListener('change', this.handleInputChange.bind(this))
    })

    let lastIndex = -1

    document.addEventListener('keydown', event => {
      if (event.key === 'Escape') {
        if (document.activeElement.closest(`#${this.element.id}`) === this.element) {
          this.closeDropdown()
        }
      } else if (event.key === 'ArrowUp') {
        event.preventDefault()
        if (lastIndex !== -1) lastIndex--
        if (lastIndex === -1) lastIndex = this.dropdownInput.length - 1
        this.dropdownInput[lastIndex].focus()
      } else if (event.key === 'ArrowDown') {
        event.preventDefault()
        lastIndex++
        if (lastIndex === this.dropdownInput.length) lastIndex = 0
        this.dropdownInput[lastIndex].focus()
      }
    })

    this.initAccessibility()
    this.checkInitialCheckedState()

    // NOTE: forcefully close dropdown as now the whole element gets replaced by the new one but can't remove main-document open-class causing to click twice - WWA
    this.toggleDropdown(true)
  }

  initAccessibility() {
    this.setAriaHiddenOnOtherElements(false)
    this.hideOverlayTabbableElements(true)

    this.overlayFocusTrap = createFocusTrap(this.dropdownPanel, {
      onActivate: () => {
        this.dropdownPanel.focus()
        this.dropdownPanel.blur()
      },
      onDeactivate: () => {
        this.dropdownPanel.focus()
      },
      escapeDeactivates: true,
      clickOutsideDeactivates: true,
      returnFocusOnDeactivate: true,
      initialFocus: this.dropdownPanel,
    })
  }

  setAriaHiddenOnOtherElements(hidden = true) {
    const nonModalDialogVisibleElements = [...document.querySelectorAll('.container--content')]
    const modalDialogVisibleElements = [this.dropdownPanel]

    nonModalDialogVisibleElements.forEach(element => element.setAttribute('aria-hidden', hidden))
    modalDialogVisibleElements.forEach(element => element.setAttribute('aria-hidden', !hidden))
  }

  hideOverlayTabbableElements(hidden = true) {
    const focusableElements = [...this.dropdownPanel.querySelectorAll('a[href], button, input, select, textarea')]
    focusableElements.forEach(element => (hidden ? element.setAttribute('tabindex', -1) : element.removeAttribute('tabindex')))
  }

  closeDropdown() {
    document.documentElement.classList.remove('multiselect-dropdown-field__panel--open')

    this.dropdownPanel.classList.remove('multiselect-dropdown-field__panel--open')
    this.dropdownButton.classList.remove('multiselect-dropdown-field__button--active')
    this.dropdownOverlay.classList.remove('multiselect-dropdown-field__overlay--active')
    if (breakpoints.isLandscape()) {
      document.documentElement.classList.remove('prevent-scrolling')
    }
    this.overlayFocusTrap.deactivate()
    this.setAriaHiddenOnOtherElements(false)
    this.hideOverlayTabbableElements(true)
  }

  toggleDropdown(forceClose = false) {
    const closing = forceClose || document.documentElement.classList.contains('multiselect-dropdown-field__panel--open')

    document.documentElement.classList.toggle('multiselect-dropdown-field__panel--open', !closing)
    if (breakpoints.isLandscape()) {
      document.documentElement.classList.toggle('prevent-scrolling', !closing)
    }

    if (!closing) {
      this.setAriaHiddenOnOtherElements(true)
      this.hideOverlayTabbableElements(false)
      this.overlayFocusTrap.activate()
    } else {
      this.overlayFocusTrap.deactivate()
    }

    this.dropdownPanel.classList.toggle('multiselect-dropdown-field__panel--open', !closing)
    this.dropdownButton.classList.toggle('multiselect-dropdown-field__button--active', !closing)
    this.dropdownOverlay.classList.toggle('multiselect-dropdown-field__overlay--active', !closing)

    if (closing) {
      this.setAriaHiddenOnOtherElements(false)
      this.hideOverlayTabbableElements(true)
    }
  }

  handleDropdownButton(e) {
    e.preventDefault()

    if (this.dropdownPanel.classList.contains('multiselect-dropdown-field__panel--open')) {
      this.toggleDropdown(true)
    } else {
      this.toggleDropdown()
    }

    if (breakpoints.isLandscape()) {
      setTimeout(() => {
        this.element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }, 100)
    }

    this.dropdownInput.forEach(item => {
      const label = item.closest('.multiselect-dropdown-field__label')

      if (this.selectedItems.includes(item.value)) {
        item.checked = true
        label.classList.add('input--checked')
      } else {
        if (breakpoints.isLandscape()) {
          item.checked = false
          label.classList.remove('input--checked')
        }
      }
    })
  }

  handleSubmitButton(e) {
    const newData = []
    this.dropdownInput.forEach(item => {
      if (item.checked === true) {
        newData.push(item.value)
      }
    })

    this.selectedItems = newData
    this.selectedItems.length > 0 ? this.dropdownButton.classList.add('highlight--button') : this.dropdownButton.classList.remove('highlight--button')
    this.closeDropdown()
  }

  handleClearButton(e) {
    e.preventDefault()
    this.dropdownInput.forEach(item => {
      const label = item.closest('.multiselect-dropdown-field__label')

      if (this.selectedItems.includes(item.value)) {
        item.checked = true
        label.classList.add('input--checked')
      } else {
        item.checked = false
        label.classList.remove('input--checked')
      }
    })
    this.closeDropdown()
  }

  handleOverlay() {
    if (this.dropdownPanel.classList.contains('multiselect-dropdown-field__panel--open')) {
      this.toggleDropdown(true)
    } else {
      this.toggleDropdown()
    }
  }

  handleLabelKeydown(e) {
    if (e.key === 'Enter') {
      e.preventDefault()
      if (this.dropdownPanel.classList.contains('multiselect-dropdown-field__panel--open')) {
        this.closeDropdown()
      }
    }
  }

  handleInputChange(e) {
    const input = e.target
    const label = input.closest('.multiselect-dropdown-field__label')

    if (input.checked) {
      label.classList.add('input--checked')
    } else {
      label.classList.remove('input--checked')
    }
  }

  checkInitialCheckedState() {
    this.dropdownInput.forEach(input => {
      const label = input.closest('.multiselect-dropdown-field__label')
      if (input.checked) {
        label.classList.add('input--checked')
        this.selectedItems.push(input.value)
      } else {
        label.classList.remove('input--checked')
      }
    })
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.multiselect-dropdown-field').forEach(element => {
    element.instance = element.instance || new MultiselectDropdownFieldComponent(element)
  })
)
