import Component from '../../../assets/scripts/modules/component'
import scrollIfNeeded from '../../../assets/scripts/utilities/scroll-if-needed'

class LocationBarComponent extends Component {
  init() {
    this.disclosureButtonDate = this.element.querySelector('.location-bar__disclosure-button--date')
    this.disclosureButtonLocations = this.element.querySelector('.location-bar__disclosure-button--locations')

    this.disclosureRegionDate = this.element.querySelector('.location-bar__disclosure-content-container--date')
    this.disclosureRegionLocations = this.element.querySelector('.location-bar__disclosure-content-container--locations')

    if (this.disclosureButtonDate && this.disclosureRegionDate) {
      this.disclosureButtonDate.addEventListener('click', () => this.toggleRegion(this.disclosureRegionDate, this.disclosureButtonDate))
      this.setTabIndexes(this.disclosureRegionDate, !!this.disclosureButtonDate)
    }

    if (this.disclosureButtonLocations && this.disclosureRegionLocations) {
      this.disclosureButtonLocations.addEventListener('click', () => this.toggleRegion(this.disclosureRegionLocations, this.disclosureButtonLocations))
      this.setTabIndexes(this.disclosureRegionLocations, !!this.disclosureButtonLocations)
    }

    window.addEventListener('init-after-load', () => scrollIfNeeded(this.element))
  }

  toggleRegion(region, button, collapseOthers = true) {
    if (!region || !button) {
      return
    }

    const isCollapsing = button.getAttribute('aria-expanded') === 'true'

    if (collapseOthers && !isCollapsing) {
      if (region === this.disclosureRegionDate && this.disclosureRegionLocations.getAttribute('aria-hidden') === 'false') {
        this.toggleRegion(this.disclosureRegionLocations, this.disclosureButtonLocations, false)
      } else if (region === this.disclosureRegionLocations && this.disclosureRegionDate.getAttribute('aria-hidden') === 'false') {
        this.toggleRegion(this.disclosureRegionDate, this.disclosureButtonDate, false)
      }
    }

    if (!isCollapsing && region.className.indexOf('date') !== -1) {
      // This datepicker is sometimes hard to initialize so THOR'S HAMMER TO THE RESCUE -EKL
      window.requestAnimationFrame(() => this.element.querySelector('.datepicker-range').dispatchEvent(new CustomEvent('activate')))
      window.setTimeout(() => window.requestAnimationFrame(() => this.element.querySelector('.datepicker-range').dispatchEvent(new CustomEvent('activate'))), 1000)
    }

    region.setAttribute('aria-hidden', isCollapsing)
    button.setAttribute('aria-expanded', !isCollapsing)

    this.setTabIndexes(region, isCollapsing)
  }

  setTabIndexes(region, isCollapsed) {
    const focusableElements = [...region.querySelectorAll('a[href], button')]
    focusableElements.forEach(element => (isCollapsed ? element.setAttribute('tabindex', -1) : element.removeAttribute('tabindex')))
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.location-bar').forEach(element => {
    element.instance = element.instance || new LocationBarComponent(element)
  })
)
