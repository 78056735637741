/* eslint promise/prefer-await-to-then: 0 */

// Example data
//
// const ticketTableTestSet = [
//   { id: 1, title: 'Member +', description: 'Valid for up to 1 x adults and 3x children under 16.', price: 50, quantity: 5, maxQuantity: 24 },
//   { id: 2, title: 'Adult Resident of Qatar (Q-ID Holder)', price: 0, quantity: 0 },
//   { id: 3, title: 'Student Resident of Qatar (Q-ID Holder)', price: 0, quantity: 0 },
//   { id: 4, title: 'Child, 0-16', price: 0, quantity: 0 },
//   { id: 5, title: 'Adult Non-Resident', price: 0, quantity: 0 },
//   { id: 6, title: 'Student Non-Resident', price: 50, quantity: 0 }
// ]
//
// const eventData = {
//   Museum: { Id: 24, Name: 'National museum of Qatar' },
//   Exhibition: {},
//   Basket: {
//     Items: [
//       { Id: 1774, Name: 'Adult', Quantity: 2, Price: '40.00' },
//       { Id: 1775, Name: 'Child', Quantity: 1, Price: '30.00' }
//     ],
//     TotalPrice: '110.00'
//   }
// }
//
// -EKL

import { loaderHTML } from '../../molecules/loader/loader'

window.addEventListener('init-load', async () => {
  const element = document.querySelector('.tickets-table')

  if (!element) {
    return
  }

  const { React, ReactDOM } = (await import('../../../assets/scripts/plugins/react')).default()

  const TicketsItem = ({ item, updateQuantityHandler, dataSet }) => {
    const [quantity, setQuantity] = React.useState()
    const subtotal = Number((item?.price || 0) * (item?.quantity || 0))
    const itemQuantity = item?.quantity

    React.useEffect(() => {
      if (quantity === '' && Number(itemQuantity === 0)) {
        return
      }

      setQuantity(itemQuantity)
    }, [itemQuantity])

    const updateInputQuantity = event => {
      let cleanNumber = event.target.value.replace(/[^0-9]/g, '')

      // Remove leading 0 -RVP
      if (cleanNumber.lenth > 1 && cleanNumber.startsWith('0')) {
        cleanNumber = cleanNumber.substr(1)
      }

      // Value cannot be larger than 99 -RVP
      if (cleanNumber.length > 2) {
        return
      }

      setQuantity(cleanNumber)
    }

    const inputQuantityBlurHandler = event => {
      if (quantity === '') {
        setQuantity(0)
      } else {
        const cleanNumber = event.target.value.replace(/[^0-9]/g, '')
        const updateQuantity = Number(cleanNumber) - Number(itemQuantity)
        const maxUpdateQuantity = Number(item.maxQuantity) - Number(itemQuantity)

        if (updateQuantity > maxUpdateQuantity) {
          setQuantity(item.maxQuantity)
        }

        updateQuantityHandler(item, updateQuantity < maxUpdateQuantity ? updateQuantity : maxUpdateQuantity)
      }
    }

    const inputQuantityFocusHandler = () => {
      if (Number(quantity) === 0) {
        setQuantity('')
      }
    }

    return (
      <tr>
        <td className="tickets-table__type">
          <span className="tickets-table__title-span">{item.title}</span>

          {dataSet.description && <span className="tickets-table__description-span">{dataSet.description}</span>}

          {item.description && <span className="tickets-table__description-span">{item.description}</span>}

          <span className="tickets-table__price-span">{item.price && Number(item.price) > 0 ? `${dataSet.priceLabel} ${item.price}` : dataSet.freeLabel}</span>
        </td>

        <td className="tickets-table__quantity">
          <input value={quantity || 0} onChange={updateInputQuantity} onBlur={inputQuantityBlurHandler} onFocus={inputQuantityFocusHandler} />
        </td>

        <td className="tickets-table__buttons">
          <div className="tickets-table__buttons-wrapper">
            <button className="tickets-table__button tickets-table__button--plus" onClick={() => updateQuantityHandler(item, 1)} disabled={item.quantity === item.maxQuantity} />
            <button className="tickets-table__button tickets-table__button--minus" onClick={() => updateQuantityHandler(item, -1)} />
          </div>
        </td>

        <td className="tickets-table__subtotal">{subtotal === 0 ? '0' : `${dataSet.priceLabel} ${Number(subtotal)}`}</td>
      </tr>
    )
  }

  const TicketsTable = ({ dataSet, nextButton, ticketSummary }) => {
    const [items, setItems] = React.useState()
    const [selectedMuseum, setSelectedMuseum] = React.useState()
    const [loading, setLoading] = React.useState(false)
    const [fetching, setFetching] = React.useState(false)

    React.useEffect(() => {
      const divElement = document.getElementById('museum-table-section')

      const enableTableHandler = () => {
        const ticketsMuseumWhatsMore = document.getElementById('tickets-museum-whats-more')
        const ticketsActionBar = document.querySelector('.tickets-action-bar')

        if (ticketsMuseumWhatsMore && ticketsMuseumWhatsMore !== null) {
          ticketsMuseumWhatsMore.classList.add('tickets-museum-list__whats-more--hide')
        }

        if (ticketsActionBar && ticketsActionBar !== null) {
          ticketsActionBar.classList.add('tickets-action-bar--show')
        }
      }

      const radioChangeHandler = e => {
        const pickedItem = e.target.value
        dataSet.isExhibition = e.target.dataset.isExhibition === 'true'
        element.dataset.showExhibitionTitle = e.target.dataset.isExhibition === 'true'

        setSelectedMuseum(pickedItem)
        enableTableHandler()
        getTickets(pickedItem)
      }

      const scrollToTickets = () => {
        if (!document.querySelector('.notice--error')) {
          const museumLists = [...document.querySelectorAll('.tickets-museum-list')]
          const lastMuseumList = museumLists[museumLists.length - 1]
          let rect
          let targetPosition

          if (lastMuseumList) {
            rect = lastMuseumList.getBoundingClientRect()
            targetPosition = rect.top + rect.height
          } else {
            rect = divElement.getBoundingClientRect()
            targetPosition = rect.top
          }

          window.scrollTo({ top: targetPosition + window.pageYOffset, behavior: 'smooth' })
        }
      }

      const getTickets = async museum => {
        setLoading(true)
        setItems(null)

        try {
          const response = await window.fetch(`${window.baseApiUrl}/tickets/?${dataSet.isExhibition === 'true' ? 'exhibition' : 'museum'}=${museum}`, { credentials: 'include' })
          const responseData = await response.json()

          ticketSummary.dispatchEvent(new CustomEvent('refresh'))

          if (responseData.MuseumEmergency) {
            emergencyNotice.style.display = 'block'
          } else {
            emergencyNotice.style.display = 'none'
          }
          if (responseData.Products) {
            const ticketTableSet = responseData.Products.map(product => {
              return {
                id: product.Id,
                title: product.Name,
                price: Number(product.Price),
                quantity: Number(product.Quantity),
                description: product.Description,
                maxQuantity: Number(product.MaxQuantity),
              }
            })

            setItems(ticketTableSet)
            divElement.classList.add('tickets-section--visible')
            window.setTimeout(() => scrollToTickets(), 500)
          }

          setLoading(false)

          return true
        } catch (error) {
          setLoading(false)
          divElement.classList.remove('tickets-section--visible')
          console.log(error)
        }
      }

      // If a list-item is checked in pageload -> imediately show tickets-table -PVI
      const intialCheckedItem = document.querySelector(`input[type=radio][name="${dataSet.listName}"]:checked`)

      if (intialCheckedItem) {
        const pickedItem = intialCheckedItem.value
        dataSet.isExhibition = intialCheckedItem.dataset.isExhibition === 'true'
        dataSet.showExhibitionTitle = intialCheckedItem.dataset.isExhibition === 'true'

        setSelectedMuseum(pickedItem)
        enableTableHandler()
        getTickets(pickedItem)
      }

      // Add onChange listener for each list option -PVI
      const radioOptions = document.querySelectorAll(`input[type=radio][name="${dataSet.listName}"]`)
      radioOptions.forEach(radio => radio.addEventListener('change', radioChangeHandler))

      // Clean up listeners on unmount component -PVI
      return () => {
        radioOptions.forEach(radio => radio.removeEventListener('change', radioChangeHandler))
      }
    }, [])

    const updateQuantityHandler = async (item, quantity) => {
      if (fetching) return

      setFetching(true)

      const newItems = [...items]
      const selectedItem = newItems.find(i => i.id === item.id)

      if (quantity === -1 && selectedItem.quantity === 0) {
        setFetching(false)
        return
      }

      const apiData = quantity > 0 ? { inc: quantity } : { dec: 0 - quantity }

      selectedItem.quantity = selectedItem.quantity + quantity
      let savedQuantity = selectedItem.quantity

      // Check if new quantity doesn't exceed maxQuantity -RVP
      if (savedQuantity >= item.maxQuantity) {
        savedQuantity = item.maxQuantity
      }

      setItems(newItems)

      try {
        const params = { ...apiData, product: selectedItem.id }
        const response = await window.fetch(`${window.baseApiUrl}/change-ticket-quantity/?${dataSet.isExhibition === 'true' ? 'exhibition' : 'museum'}=${selectedMuseum}`, { method: 'POST', credentials: 'include', body: JSON.stringify(params) })
        const responseData = await response.json()

        if (responseData && responseData.Museum) {
          const detail = {
            data: responseData,
            type: quantity < 0 ? 'remove' : 'add',
          }
          ticketSummary.dispatchEvent(new CustomEvent('updateBasket', { detail: responseData }))
          ticketSummary.dispatchEvent(new CustomEvent('ticket-quantity-click', { detail }))
        }

        selectedItem.quantity = savedQuantity // Just in case, since changes while fetching are ignored -EKL
        setFetching(false)
      } catch (error) {
        selectedItem.quantity = savedQuantity // Just in case, since changes while fetching are ignored -EKL
        setFetching(false)
        console.log(error)
      }
    }

    const totalPrice = items?.reduce((total, nextItem) => total + Number(nextItem.price * nextItem.quantity), 0) || 0

    if (loading) {
      return <div dangerouslySetInnerHTML={{ __html: loaderHTML }} />
    }

    return (
      <>
        <table className={fetching ? 'tickets-table__table tickets-table__table--fetching' : 'tickets-table__table'}>
          <thead>
            <tr>
              <td>{dataSet.typeOfTicketLabel}</td>
              <td>{dataSet.quantityLabel}</td>
              <td className="tickets-table__filler-col" />
              <td>{dataSet.subtotalLabel}</td>
            </tr>
          </thead>

          <tbody>
            {items?.map(item => (
              <TicketsItem key={item.id} item={item} updateQuantityHandler={updateQuantityHandler} dataSet={dataSet} />
            ))}
          </tbody>
        </table>

        <div className="tickets-table__total">
          <div className="tickets-table__total-label">{dataSet.totalLabel}</div>
          <div className="tickets-table__total-price">{`${totalPrice ? dataSet.priceLabel : ''} ${totalPrice}`}</div>
        </div>
      </>
    )
  }

  const ticketSummary = document.querySelector('.tickets-summary')
  const nextButton = document.querySelector('.tickets-summary__button')
  const emergencyNotice = document.querySelector('.emergency-notice')

  ReactDOM.render(<TicketsTable dataSet={element.dataset} nextButton={nextButton} ticketSummary={ticketSummary} />, element)
})
