import Component from '../../../assets/scripts/modules/component'

class ArticleMicrioEmbedComponent extends Component {
  init() {
    this.initDynamicBackButton()
  }

  initDynamicBackButton() {
    this.buttonBack = this.element.querySelector('button.button--back')
    this.buttonBack?.addEventListener('click', () => window.history.back())
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.article-micrio-embed').forEach(element => {
    element.instance = element.instance || new ArticleMicrioEmbedComponent(element)
  })
)
