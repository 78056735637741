import Component from '../../../assets/scripts/modules/component'
import insertScript from '../../../assets/scripts/utilities/insert-script-async'
import randomHash from '../../../assets/scripts/utilities/random-hash'
import { getCookie } from '../../../assets/scripts/utilities/simple-cookies'

export default class HubspotEmbedComponent extends Component {
  init() {
    this.codeContainer = this.element.querySelector('.hubspot-embed__code-container')
    this.formContainer = this.element.querySelector('.hubspot-embed__form-container')

    this.hubspotRegion = this.element.getAttribute('data-hubspot-region') || ''
    this.hubspotPortalId = this.element.getAttribute('data-hubspot-portal-id') || ''
    this.hubspotFormId = this.element.getAttribute('data-hubspot-form-id') || ''

    if (!this.codeContainer || !this.formContainer || !this.hubspotRegion || !this.hubspotPortalId || !this.hubspotFormId) {
      return
    }

    this.hash = randomHash()

    this.buttonCookies = this.element.querySelector('.button--cookies')
    this.notice = this.element.querySelector('.hubspot-embed__cookies-notice')

    if (this.buttonCookies) {
      this.buttonCookies.addEventListener('click', event => {
        event.preventDefault()
        window.dispatchEvent(new CustomEvent('show-cookie-details'))
      })
    }

    window.addEventListener('cookie-consent', () => this.checkCookie())
    this.checkCookie()
  }

  checkCookie() {
    const cookie = getCookie('cookie-consent')

    if (!cookie || cookie.split(',').indexOf('embeds') === -1) {
      this.showCookieNotice()
    } else {
      this.hideCookieNotice()
    }
  }

  showCookieNotice() {
    if (this.notice) {
      this.notice.removeAttribute('hidden')
    }

    this.element.classList.add('hubspot-embed--cookies-not-accepted')
  }

  hideCookieNotice() {
    if (this.notice) {
      this.notice.setAttribute('hidden', 'hidden')
    }

    this.element.classList.remove('hubspot-embed--cookies-not-accepted')
    this.initExternalScript()
  }

  async initExternalScript() {
    await insertScript(`hubspot-script-${this.hash}`, `//js-${this.hubspotRegion}.hsforms.net/forms/embed/v2.js`, this.codeContainer)

    if (!window.hbspt) {
      return
    }

    this.formContainer.classList.add(`hubspot-form--${this.hash}`) // Hubspot doesn't recognize id selector -EKL
    window.hbspt.forms.create({ region: this.hubspotRegion, portalId: this.hubspotPortalId, formId: this.hubspotFormId, target: `.hubspot-form--${this.hash}` })
  }
}

window.addEventListener('init-delayed-load', () =>
  [...document.querySelectorAll('.hubspot-embed')].forEach(element => {
    element.instance = element.instance || new HubspotEmbedComponent(element)
  })
)
