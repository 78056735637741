class Obstacle {
  constructor(p5Instance, x, y, width, height, obstacleSprite) {
    this.x = x
    this.y = y
    this.width = width
    this.height = height
    this.sprite = obstacleSprite // Sprite dell'ostacolo
    this.p5Instance = p5Instance
  }

  display() {
    if (this.sprite && this.x && this.y && this.width && this.height) {
      this.p5Instance.image(this.sprite, this.x, this.y, this.width, this.height)
    }
  }
}

export default Obstacle
