import breakpoints from '../../../assets/scripts/modules/breakpoints'
import Component from '../../../assets/scripts/modules/component'

export default class CompositionCardGridComponent extends Component {
  init() {
    this.rows = [...this.element.querySelectorAll('.composition-card-grid__row')]
    this.reduceMotion = window.matchMedia('(prefers-reduced-motion: reduce)')

    if (!this.rows.length) {
      return
    }

    window.addEventListener('gsap-loaded', () => this.initScrollTween())
    window.addEventListener('resize', () => window.requestAnimationFrame(() => this.initScrollTween()))
  }

  initScrollTween() {
    if (!this.rows.length) {
      return
    }

    this.rows.forEach(row => {
      const cardWrappers = [...row.querySelectorAll('.composition-card-grid__card-wrapper')]

      if (!cardWrappers.length) {
        return
      }

      cardWrappers.forEach(cardWrapper => {
        let start = 0
        let end = 0
        let rowVariant = ''
        let cardVariant = ''

        if (breakpoints.isLandscape()) {
          if (cardWrapper.classList.contains('composition-card-grid__card-wrapper--card-row-composition-a')) {
            rowVariant = 'composition-a'
          } else if (cardWrapper.classList.contains('composition-card-grid__card-wrapper--card-row-composition-b')) {
            rowVariant = 'composition-b'
          }

          if (cardWrapper.classList.contains('composition-card-grid__card-wrapper--card-landscape-large')) {
            cardVariant = 'landscape-large'
          } else if (cardWrapper.classList.contains('composition-card-grid__card-wrapper--card-landscape')) {
            cardVariant = 'landscape'
          } else if (cardWrapper.classList.contains('composition-card-grid__card-wrapper--card-portrait')) {
            cardVariant = 'portrait'
          }

          if (cardVariant === 'landscape') {
            if (rowVariant === 'composition-b') {
              if (cardWrapper.parentNode.firstElementChild === cardWrapper) {
                start = 400
                end = -400
              } else {
                start = 200
                end = -200
              }
            } else {
              start = 300
              end = -300
            }
          } else if (cardVariant === 'portrait') {
            start = 100
            end = -100
          } else if (cardVariant === 'landscape-large') {
            start = 50
            end = -50
          }
        }

        const card = cardWrapper.querySelector('.composition-card')

        if (!card) {
          return
        }

        cardWrapper.setAttribute('data-pos', `${start},${end}`)

        if (!this.reduceMotion.matches) {
          window.GSAP.set(card, { force3D: true }) // We're gonna be needing the GPU real soon -EKL
          window.GSAP.fromTo(card, { y: start }, { y: end, scrollTrigger: { trigger: cardWrapper, scrub: 0 }, ease: 'linear' })
        }

        window.ScrollTrigger.create({
          trigger: card,
          start: '100px bottom',
          onEnter: () => {
            card.classList.add('composition-card--inview')
            var video = card.querySelector('.video__video')
            if (video) {
              video.setAttribute('preload', 'auto')
            }
          },
        })
      })
    })
  }
}

window.addEventListener('init-immediate', () =>
  document.querySelectorAll('.composition-card-grid').forEach(element => {
    element.instance = element.instance || new CompositionCardGridComponent(element)
  })
)
