import Component from '../../../assets/scripts/modules/component'

export default class DocumentComponent extends Component {
  async init() {
    await this.loadGSAP()
  }

  async loadGSAP() {
    const { gsap, ScrollTrigger } = (await import('../../../assets/scripts/plugins/gsap')).default()

    window.GSAP = window.gsap ?? gsap
    window.ScrollTrigger = window.ScrollTrigger ?? ScrollTrigger

    window.GSAP.registerPlugin(window.ScrollTrigger)
    window.GSAP.ticker.sleep() // Prevent GSAP ticker from leaking memory when not in use -EKL

    // Check for re-layout and recalculate, otherwise ScrollTrigger can lose track -EKL
    const observer = new ResizeObserver(() => window.ScrollTrigger.refresh())
    observer.observe(document.body)

    window.dispatchEvent(new CustomEvent('gsap-loaded'))
  }
}

window.addEventListener('init-immediate', () => {
  document.documentElement.instance = new DocumentComponent(document.documentElement)
})
