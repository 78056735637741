import Component from '../../../assets/scripts/modules/component'
export default class CardComponent extends Component {
  init() {
    this.overlayId = this.element.getAttribute('data-overlay-id') || ''

    if (this.overlayId) {
      this.element.addEventListener('click', event => this.onClickHandler(event))
    }
  }

  onClickHandler(event) {
    event?.preventDefault()

    const cards = [...document.querySelectorAll('.card-overlay')]
    cards.forEach(card => card.classList.remove('card-overlay--active'))

    document.getElementById(this.overlayId)?.dispatchEvent(new CustomEvent('toggle-overlay'))
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.card').forEach(element => {
    element.instance = element.instance || new CardComponent(element)
  })
)
