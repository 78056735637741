import Component from '../../../assets/scripts/modules/component'

const SLIDE_TIMEOUT_MS = 1500

// Allows modulo with negative numbers -EKL
const modulo = (a, b) => {
  const c = a % b
  return c < 0 ? c + b : c
}

export default class StorytellingCarouselComponent extends Component {
  init() {
    this.observer = null
    this.currentIndex = 0
    this.enableKeyHandler = false
    this.isSliding = false
    this.slideTimeout = null

    this.imageSlides = [...this.element.querySelectorAll('.storytelling-carousel__slide--image, .storytelling-carousel__slide--cover-image')]
    this.contentSlides = [...this.element.querySelectorAll('.storytelling-carousel__slide--content, .storytelling-carousel__slide--cover-content')]

    this.buttonPrevious = this.element.querySelector('.button--previous')
    this.buttonNext = this.element.querySelector('.button--next')

    this.initSlides()
    this.initButtons()
    this.initKeys()
    this.initObserver()
    this.initSeparators()
  }

  initSlides() {
    if (this.imageSlides.length < 2 || this.contentSlides.length < 2) {
      this.element.querySelectorAll('.button--previous, .button--next').forEach(button => button.parentNode.removeChild(button))
      return
    }

    this.slideTo(0)
  }

  slideTo(index, indexIsRelative = false) {
    if (this.isSliding) {
      return
    }

    this.isSliding = true

    if (indexIsRelative && index !== 0) {
      index = modulo(this.currentIndex + index, this.contentSlides.length)
    }

    if (!this.contentSlides[index]) {
      return
    }

    this.currentIndex = index

    // Content slides are leading -EKL
    this.contentSlides.forEach(slide => {
      const slideIndex = this.contentSlides.indexOf(slide)

      if (slideIndex === this.currentIndex) {
        slide.scrollTo(0, 0)
        this.activateSlide(slide)
        this.activateSlide(this.imageSlides[slideIndex], true)
      } else {
        this.deactivateSlide(slide)
        this.deactivateSlide(this.imageSlides[slideIndex])
      }
    })

    // window.clearTimeout(this.slideTimeout) // Why is clearing the timeout disabled? -EKL
    this.slideTimeout = window.setTimeout(() => {
      this.isSliding = false
    }, SLIDE_TIMEOUT_MS)
  }

  activateSlide(slide, lookForPicture = false) {
    if (!slide) {
      return
    }

    slide.setAttribute('aria-hidden', false)

    if (lookForPicture) {
      const picture = slide.querySelector('.picture')

      if (picture && !picture.instance && window.PictureComponent) {
        picture.instance = new window.PictureComponent(picture)
      }
    }

    const cover = slide.querySelector('.storytelling-carousel__coverslide')

    if (cover) {
      cover.setAttribute('aria-hidden', false)
    }
  }

  deactivateSlide(slide) {
    if (!slide) {
      return
    }

    slide.setAttribute('aria-hidden', true)

    const cover = slide.querySelector('.storytelling-carousel__coverslide')

    if (cover) {
      cover.setAttribute('aria-hidden', true)
    }
  }

  initButtons() {
    this.buttonPrevious?.addEventListener('click', () => this.slideTo(-1, true))
    this.buttonNext?.addEventListener('click', () => this.slideTo(1, true))
  }

  initKeys() {
    document.addEventListener('keydown', event => this.onKeydownHandler(event))
  }

  initObserver() {
    this.observer = new IntersectionObserver(entries => this.observeHandler(entries))
    this.observer.observe(this.element)
  }

  observeHandler(entries) {
    if (entries[0].isIntersecting) {
      this.enableKeyHandler = true
    } else {
      this.enableKeyHandler = false
    }
  }

  onKeydownHandler(event) {
    if (!this.enableKeyHandler) {
      return
    }

    if (event.key === 'ArrowLeft' && this.buttonPrevious) {
      this.buttonPrevious.click()
    } else if (event.key === 'ArrowRight' && this.buttonNext) {
      this.buttonNext.click()
    }
  }

  initSeparators() {
    const separators = [...this.element.querySelectorAll('.storytelling-carousel__slide-count-word')]
    const characterEN = document.body.classList.contains('subsite--mathaf') || document.body.classList.contains('subsite--firestation') ? '|' : '/'
    const character = document.documentElement.dir === 'rtl' ? 'من' : characterEN

    separators.forEach(separator => {
      separator.innerText = character
    })
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.storytelling-carousel').forEach(element => {
    element.instance = element.instance || new StorytellingCarouselComponent(element)
  })
)
