class Enemy {
  constructor(p5Instance, x, y, width, height, sharkLeftSprite, sharkRightSprite, collideRectRect) {
    this.x = x
    this.y = y
    this.width = width
    this.height = height
    this.speed = 1.5 // Velocità dei nemici
    this.sprite = sharkLeftSprite // Sprite iniziale
    this.leftSprite = sharkLeftSprite
    this.rightSprite = sharkRightSprite
    this.facingRight = false // Indica se il nemico sta guardando a destra o sinistra
    this.p5Instance = p5Instance
    this.collideRectRect = collideRectRect
    this.minMoveDistance = 2 // min distance for making a movement
    this.isWaiting = false // flag for delay after a collision
  }

  display() {
    if (this.sprite && this.x && this.y && this.width && this.height) {
      this.p5Instance.image(this.sprite, this.x, this.y, this.width, this.height)
    }
  }

  move(player, enemies, obstacles) {
    if (this.isWaiting) return //  don't move enemy if it is waiting

    const targetX = player.x
    const targetY = player.y

    // calculate distance from player
    const distanceX = targetX - this.x
    const distanceY = targetY - this.y

    // calculate direction towards player
    const directionX = distanceX > 0 ? 1 : -1
    const directionY = distanceY > 0 ? 1 : -1

    // first attempt of moving towards player
    const newX = this.x + directionX * this.speed
    const newY = this.y + directionY * this.speed

    // check if movement will lead to a collision
    if (!this.wouldCollide(newX, newY, enemies, obstacles)) {
      this.x = newX
      this.y = newY
    } else {
      // if would collide try other directions
      const alternativeDirections = [
        { x: this.x, y: this.y + directionY * this.speed }, // up or down
        { x: this.x + directionX * this.speed, y: this.y }, // right or left
        { x: this.x + this.speed, y: this.y + this.speed }, // down-right
        { x: this.x - this.speed, y: this.y + this.speed }, // down-left
        { x: this.x + this.speed, y: this.y - this.speed }, // up-right
        { x: this.x - this.speed, y: this.y - this.speed }, // up-left
      ]

      for (const dir of alternativeDirections) {
        if (!this.wouldCollide(dir.x, dir.y, enemies, obstacles)) {
          // move only if you can do at least a minMoveDistance pixel distance
          if (this.p5Instance.dist(this.x, this.y, dir.x, dir.y) >= this.minMoveDistance) {
            this.x = dir.x
            this.y = dir.y
            break
          }
        }
      }

      // 100 delay if a collision happend
      this.isWaiting = true
      setTimeout(() => {
        this.isWaiting = false
      }, 400)
    }

    this.sprite = distanceX > 0 ? this.rightSprite : this.leftSprite
  }

  wouldCollide(x, y, enemies, obstacles) {
    // Controlla la collisione con gli ostacoli

    for (const obstacle of obstacles) {
      if (this.collideRectRect(x, y, this.width, this.height, obstacle.x, obstacle.y, obstacle.width, obstacle.height)) {
        return true
      }
    }

    // Controlla la collisione con altri nemici

    for (const enemy of enemies) {
      if (enemy !== this && this.collideRectRect(x, y, this.width, this.height, enemy.x, enemy.y, enemy.width, enemy.height)) {
        return true
      }
    }

    return false
  }
}

export default Enemy
