import breakpoints from '../../../assets/scripts/modules/breakpoints'

window.addEventListener('init-load', async () => {
  const CUSTOM_FORMAT = 'DD-MM-YYYY' // Datepicker uses this format internally -RVP
  const element = document.querySelector('.datepicker-range')

  if (!element) {
    return
  }

  const { React, ReactDOM } = (await import('../../../assets/scripts/plugins/react')).default()
  const { DayPickerRangeController, toMomentObject, moment } = (await import('../../../assets/scripts/plugins/react-dates-and-moment')).default()

  const queryString = location.search
  const urlParams = new URLSearchParams(queryString)

  // Helper function to determine if date is inside given date range -RVP
  const isDayOutsideRange = (day, fromDate, toDate) => {
    return !moment(day, CUSTOM_FORMAT).isBetween(fromDate, toDate, 'day', '[]')
  }

  const setInitialVisibleMonth = (focusedDate, initialDate, toDate) => {
    return focusedDate === 'endDate' ? moment(toDate) || moment(initialDate) || null : moment(initialDate) || null
  }

  class RangeDatepickerControllerWrapper extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
        isRTL: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl',
      }

      this.responsivityHandler = this.responsivityHandler.bind(this)
    }
    
    componentDidMount() {
      this.responsivityHandler()
      window.addEventListener('resize', this.responsivityHandler)
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.responsivityHandler)
    }

    responsivityHandler() {
      if (window.innerWidth < breakpoints.LANDSCAPE) {
        this.setState({ responsiveState: 'small' })
      } else if (window.innerWidth < breakpoints.NOTEBOOK) {
        this.setState({ responsiveState: 'medium' })
      } else {
        this.setState({ responsiveState: 'large' })
      }
    }

    render() {
      const { responsiveState, isRTL } = this.state
      const { labelFrom, labelTo, inputIdFrom, inputIdTo, numberOfMonths, clearLabel, applyLabel, dateRangeFrom, dateRangeTo, showButtons, focusedDate } = this.props.dataSet
      const dateFormat = this.props.dataSet.dateFormat || CUSTOM_FORMAT
      const initialDate = urlParams.get('from_date')
      const toDate = urlParams.get('to_date')

      // Sets limit range for datepicker
      const limitFrom = toMomentObject(dateRangeFrom || moment(), CUSTOM_FORMAT) // Limit start date or today -RVP
      const limitTo = toMomentObject(dateRangeTo || moment().add(2, 'year'), CUSTOM_FORMAT) // Limit end date or today + 2 years -RVP

      const pickerProps = {
        initialDate,
        toDate,
        clearLabel,
        applyLabel,
        labelFrom,
        labelTo,
        limitFrom,
        limitTo,
        inputIdFrom,
        inputIdTo,
        isRTL,
        dateFormat,
        initialVisibleMonth: initialDate || toDate ? () => setInitialVisibleMonth(focusedDate, initialDate, toDate): null,
        showButtons: showButtons === "true",
        numberOfMonths: numberOfMonths ?? (!responsiveState || responsiveState === 'large') ? 2 : 1,
        noBorder: true,
        orientation: 'horizontal',
        daySize: responsiveState === 'small' ? 40 : 50,
        hideKeyboardShortcutsPanel: true,
        isOutsideRange: day => isDayOutsideRange(day, limitFrom, limitTo),
      }

      return <RangeDatepicker {...pickerProps} />
    }
  }

  moment.locale('en-gb')

  // Created functional component to be up to date with new React Development guidelines. Refactoring the whole Wrapper component was too much time -RVP
  function RangeDatepicker({ initialDate, toDate, labelFrom, labelTo, limitFrom, limitTo, applyLabel, clearLabel, inputIdFrom, inputIdTo, dateFormat, showButtons, focusedDate, ...pickerProps }) {
    const fromDateInputElement = document.getElementById(inputIdFrom)
    const toDateInputElement = document.getElementById(inputIdTo)

    const [startDate, setStartDate] = React.useState(initialDate ? moment(initialDate) : null)
    const [endDate, setEndDate] = React.useState(toDate ? moment(toDate) : null)
    const [focusedInput, setFocusedInput] = React.useState(focusedDate || 'startDate')

    const onDatesChange = ({ startDate, endDate }) => {
      setStartDate(startDate)
      setEndDate(endDate)
    }

    const onSearchClick = ({ event, startDate, endDate }) => {
      event.preventDefault()
      if (startDate && endDate) {
        fromDateInputElement.value = startDate.format(dateFormat || CUSTOM_FORMAT)
        toDateInputElement.value = endDate.format(dateFormat || CUSTOM_FORMAT)

        urlParams.set('from_date', moment(startDate).year() + '-' + (parseInt(moment(startDate).month()) + 1) + '-' + moment(startDate).date())
        urlParams.set('to_date', moment(endDate).year() + '-' + (parseInt(moment(endDate).month()) + 1) + '-' + moment(endDate).date())

        window.history.pushState({}, '', `${location.pathname}?${urlParams}`)

        const datePickerRangeInput = window.document.querySelector('.datepicker-range-field')
        if (datePickerRangeInput) {
          window.requestAnimationFrame(() => datePickerRangeInput.dispatchEvent(new CustomEvent('disableFocusTrap'))) 
        }
      }
    }

    // Clear the button and inputfields -BJK
    const onReset = () => {
      setStartDate(null)
      setEndDate(null)
      setFocusedInput('startDate')
    }

    // Update accessibility input fields on dates change -RVP
    React.useEffect(() => {
      fromDateInputElement.value = startDate ? startDate.format(dateFormat || CUSTOM_FORMAT) : ''
      toDateInputElement.value = endDate ? endDate.format(dateFormat || CUSTOM_FORMAT) : ''
    }, [startDate, endDate])

    // Listening to change event of accessibility input elements -RVP
    React.useEffect(() => {
      const startDateHandler = event => {
        if (!event.target.value) return

        if (isDayOutsideRange(event.target.value, limitFrom, limitTo)) {
          // Should show error? -RVP
          return
        }

        setStartDate(toMomentObject(event.target.value, CUSTOM_FORMAT))
      }

      const endDateHandler = event => {
        if (!event.target.value) return

        if (isDayOutsideRange(event.target.value, limitFrom, limitTo)) {
          // Should show error? -RVP
          return
        }

        setEndDate(toMomentObject(event.target.value, CUSTOM_FORMAT))
      }
      fromDateInputElement.addEventListener('change', startDateHandler)
      toDateInputElement.addEventListener('change', endDateHandler)

      return () => {
        fromDateInputElement.removeEventListener('change', startDateHandler)
        toDateInputElement.removeEventListener('change', endDateHandler)
      }
    }, [fromDateInputElement, toDateInputElement])

    if (!fromDateInputElement || !toDateInputElement) return null

    return (
      <>
        <div className='datepicker-range__calendar-wrapper'>
          <DayPickerRangeController {...pickerProps} minimumNights={0} onDatesChange={onDatesChange} onFocusChange={focusedInput => setFocusedInput(!focusedInput ? 'startDate' : focusedInput)} focusedInput={focusedInput} startDate={startDate} endDate={endDate} />
        </div>
        {showButtons && <div className="datepicker-range__buttons-wrapper">
          <button type="button" className="button button--clean" onClick={() => onReset()}>
            <span className="button__span">{clearLabel}</span>
          </button>

          <button className="button button--tertiary" onClick={(event) => onSearchClick({ event, startDate, endDate })} disabled={!startDate || !endDate}>
            <span className="button__span">{applyLabel}</span>
          </button>
        </div>}
      </>
    )
  }

  document.querySelectorAll('.datepicker-range').forEach(element => {
    moment.locale('en-gb')
    if (element.dataset.locale === 'ar') {
      moment.locale('ar')
    }

    element.addEventListener('activate', () => {
      ReactDOM.render(<RangeDatepickerControllerWrapper dataSet={element.dataset} element={element} />, element)
    })
  })
})
