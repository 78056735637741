import { createFocusTrap } from 'focus-trap'
import Component from '../../../assets/scripts/modules/component'

class FigureOverlayComponent extends Component {

  init() {
    this.wrapper = this.element.querySelector('.figure-overlay__wrapper')
    this.buttonClose = this.element.querySelector('.figure-overlay__overlay-close')
    this.expandButton = document.querySelector('.exhibition-header__figure-overlay-button')
    console.log(this.expandButton)
    this.initAccessibility()
    this.initOverlay()
  }

  initOverlay() {
    this.buttonClose.addEventListener('click', () => window.requestAnimationFrame(() => this.toggleOverlay()))
    
    this.element.addEventListener('toggle-figure-overlay', () => window.requestAnimationFrame(() => this.toggleOverlay()))
  }

  toggleOverlay(forceClose = false) {
    const closing = forceClose || document.documentElement.classList.contains('figure-overlay-visible')

    if (document.documentElement.classList.contains('figure-overlay-closing') || document.documentElement.classList.contains('figure-overlay-opening')) {
      return
    }

    document.documentElement.classList.toggle('figure-overlay-visible', !closing)
    document.documentElement.classList.toggle('prevent-scrolling', !closing)
    this.element.classList.toggle('figure-overlay--active', !closing)
    this.overlayFocusTrap.deactivate()

    if (!closing) {
      this.setAriaHiddenOnOtherElements(true)
      this.hideOverlayTabbableElements(false)
      this.overlayFocusTrap.activate()
    } else {
      this.overlayFocusTrap.deactivate()
    }


    if (closing) {
      document.documentElement.classList.add('figure-overlay--active')
      window.setTimeout(() => {
        document.documentElement.classList.remove('figure-overlay--active')
        this.setAriaHiddenOnOtherElements(false)
        this.hideOverlayTabbableElements(true)
      }, 350)
    } else {
      document.documentElement.classList.add('figure-overlay--active')
      window.setTimeout(() => {
        document.documentElement.classList.remove('figure-overlay--active')
      })
    }
  }

  initAccessibility() {
    this.setAriaHiddenOnOtherElements(false)
    this.hideOverlayTabbableElements(true)

    this.overlayFocusTrap = createFocusTrap(this.wrapper, {
      onActivate: () => {
        console.log('onActivate', this.buttonClose)
        this.buttonClose.focus()
        this.buttonClose.blur()
      },
      onDeactivate: () => {
        this.toggleOverlay(true)
        this.expandButton.focus()
      },
      escapeDeactivates: true,
      clickOutsideDeactivates: true,
      returnFocusOnDeactivate: false,
      initialFocus: this.overlayCloseButton,
    })
  }

  setAriaHiddenOnOtherElements(hidden = true) {
    const nonModalDialogVisibleElements = [...document.querySelectorAll('.container--content'), ...document.querySelectorAll('.container--footer')]
    const modalDialogVisibleElements = [this.element]

    nonModalDialogVisibleElements.forEach(element => element.setAttribute('aria-hidden', hidden))
    modalDialogVisibleElements.forEach(element => element.setAttribute('aria-hidden', !hidden))
  }

  hideOverlayTabbableElements(hidden = true) {
    const focusableElements = [...this.element.querySelectorAll('a[href], button, input, select, textarea')]
    console.log(focusableElements)
    focusableElements.forEach(element => (hidden ? element.setAttribute('tabindex', -1) : element.removeAttribute('tabindex')))
  }
}

window.FigureOverlayComponent = FigureOverlayComponent

window.addEventListener('init-load', () =>
  document.querySelectorAll('.figure-overlay').forEach(element => {
    element.instance = element.instance || new FigureOverlayComponent(element)
  })
)
