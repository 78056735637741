import Component from '../../../assets/scripts/modules/component'

export default class WhatsOnControllerComponent extends Component {
  init() {
    this.form = document.querySelector('.dropdown-filter-bar__form')
    this.mobileForm = document.querySelector('.overlay-dropdown-filter-bar__form')
    this.selectedFiltersBarButtons = document.querySelectorAll('.selected-filters-bar__filter-btn')
    this.mostPopularSelectedFiltersBarButtons = document.querySelectorAll('.most-popular-selected-filters-bar__filter-btn')
    this.clearAllButton = document.querySelector('.selected-filters-bar__clear-btn')
    this.cardGridPaginations = document.querySelectorAll('.number-button')
    this.cardGridClearFilterButton = document.querySelector('.card-grid__text')
    this.dropDownFilterBar = document.querySelector('.dropdown-filter-bar')
    this.overlayDropdownFilterBarMobile = document.querySelector('.overlay-dropdown-filter-bar-mobile')

    this.initDataLayer()
    this.initCurrentUrlSearchParams()

    if (this.cardGridClearFilterButton && this.cardGridClearFilterButton.childNodes) {
      this.cardGridClearFilterButton.childNodes.forEach(item => {
        if (item.tagName === 'A') {
          item.addEventListener('click', this.handleTagBarButtons.bind(this))
        }
      })
    }

    if (this.mostPopularSelectedFiltersBarButtons) {
      this.mostPopularSelectedFiltersBarButtons.forEach(item => {
        item.addEventListener('click', this.handleTagBarButtons.bind(this))
      })
    }

    if (this.cardGridPaginations) {
      this.cardGridPaginations.forEach(item => {
        item.addEventListener('click', this.handleTagBarButtons.bind(this))
      })
    }

    if (this.dropDownFilterBar) {
      this.dropDownFilterBar.querySelector('.dropdown-filter-bar-mobile__button').addEventListener('click', () => {
        this.overlayDropdownFilterBarMobile.dispatchEvent(new CustomEvent('open-mobile-filter-bar-overlay'))
      })
    }

    if (this.clearAllButton) {
      this.clearAllButton.addEventListener('click', this.handleTagBarButtons.bind(this))
    }

    // TODO fix me, this is nasty but does the trick for now - WWA (sorry EKL)
    if (!this.cardHighlightsGridButton) {
      this.cardHighlightsGridButton = document.querySelector('.card-highlights-grid__button')
      if (this.cardHighlightsGridButton) {
        this.cardHighlightsGridButton.addEventListener('click', this.handleTagBarButtons.bind(this))
      }
    }

    if (this.selectedFiltersBarButtons) {
      this.selectedFiltersBarButtons.forEach(item => {
        item.addEventListener('click', this.handleTagBarButtons.bind(this))
      })
    }

    if (this.form) {
      this.form.addEventListener('submit', this.handleFormSubmit.bind(this))
    }

    if (this.mobileForm) {
      this.mobileForm.addEventListener('submit', this.handleFormSubmit.bind(this))
    }
  }

  initCurrentUrlSearchParams() {
    this.currentUrlSearchParams = window.location.href
  }

  initDataLayer() {
    window.dataLayer = window.dataLayer || []
  }

  pushDataLayer(event, filterType, filters, startDate, endDate) {
    const newDataLayer = {
      event: event,
      filterType: filterType,
      filters: filters,
      startDate: startDate,
      endDate: endDate,
    }

    if (!endDate) {
      delete newDataLayer.endDate
    }

    if (!filterType) {
      delete newDataLayer.filterType
    }

    // Flush all data layer variables as described at https://www.simoahava.com/gtm-tips/remember-to-flush-unused-data-layer-variables/
    window.dataLayer.push({
      event: null,
      filterType: null,
      filters: null,
      startDate: null,
      endDate: null,
    })
    window.dataLayer.push(newDataLayer)
  }

  async fetchWhatsOnData(filterUrl) {
    try {
      let url = new URL(filterUrl)

      if ([...new Set(url.searchParams.keys())].length > 0) {
        url = filterUrl + '&format=json'
      } else {
        url = filterUrl + '?format=json'
      }

      const whatsOnLoader = document.querySelector('.whats-on-loader')
      if (whatsOnLoader) {
        whatsOnLoader.classList.add('whats-on-loader--loading')
      }

      const response = await fetch(url)
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      const data = await response.json()

      if (whatsOnLoader) {
        whatsOnLoader.classList.remove('whats-on-loader--loading')
      }

      const whatsOnCalendar = document.getElementById('whats-on-controller')

      if (whatsOnCalendar) {
        setTimeout(() => {
          whatsOnCalendar.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }, 100)
      }

      return data
    } catch (error) {
      console.error('Error fetching data:', error)

      return false
    }
  }

  async handleFetchData(slug, datalayerFilterType) {
    await this.fetchWhatsOnData(slug)
      .then(data => {
        if (data) {
          const selectedFilters = document.querySelector('.selected-filters-bar')
          const cardGrid = document.querySelector('.card-grid')
          const mostPopularSelectedFiltersBar = document.querySelector('.most-popular-selected-filters-bar')
          const dropDownFilterBar = document.querySelector('.dropdown-filter-bar')
          const overlayDropdownFilterBarMobile = document.querySelector('.overlay-dropdown-filter-bar-mobile')
          const overlaysContainer = document.querySelector('.container--overlays')

          if (selectedFilters) {
            selectedFilters.outerHTML = data['selected-filters']
          }

          if (cardGrid) {
            cardGrid.outerHTML = data['card-grid']
          }

          if (mostPopularSelectedFiltersBar) {
            mostPopularSelectedFiltersBar.outerHTML = data['popular-filters']
          }

          if (dropDownFilterBar) {
            dropDownFilterBar.outerHTML = data['dropdown-filters']
          }

          if (overlayDropdownFilterBarMobile) {
            overlayDropdownFilterBarMobile.outerHTML = data['overlay-dropdown-filters']
          }

          if (overlaysContainer) {
            Array.from(overlaysContainer.getElementsByClassName('card-overlay')).forEach(e => e.remove())
            Array.from(data['card-overlays']).forEach(e => overlaysContainer.insertAdjacentHTML('beforeend', e))
          }

          window.dispatchEvent(new Event('init-load'))

          this.handleDataLayer(slug, datalayerFilterType)
          this.setUrl(slug)

          return true
        } else {
          const whatsOnLoader = document.querySelector('.whats-on-loader')

          if (whatsOnLoader) {
            whatsOnLoader.classList.remove('whats-on-loader--loading')
          }
        }
        return false
      })
      .catch(error => {
        console.log(error)
        return false
      })
  }

  handleDataLayer(filterUrl, filterType = '') {
    const url = new URL(filterUrl)
    const currentParams = new URLSearchParams(url.search)
    url.search = currentParams.toString().replace(/%2C/g, ',')

    const currentArray = []
    const newArray = []

    const params = new URLSearchParams(new URL(window.location.href).search)

    Array.from(params.entries()).forEach(([key, value]) => currentArray.push(`${key} ${value}`))

    Array.from(currentParams.entries()).forEach(([key, value]) => newArray.push(`${key} ${value}`))

    const removeParam = currentArray.filter(param => !newArray.includes(param))
    const addedParam = newArray.filter(param => !currentArray.includes(param))

    const addedFilters = {}
    const removedFilters = {}
    let startDate = ''
    let endDate = ''
    const currentDate = new Date().toJSON().slice(0, 10)

    const date = new Date(currentDate)
    date.setDate(date.getDate() + 7)
    const newEndDate = date.toISOString().split('T')[0]

    if (removeParam.length > 0) {
      if (!currentArray.join().includes('date ')) {
        startDate = currentDate
        if (currentArray.join().includes('today')) {
          endDate = currentDate
        } else if (currentArray.join().includes('next-7-days')) {
          endDate = newEndDate
        } else {
          endDate = null
        }
      }

      removeParam.forEach(param => {
        const [key, value] = param.split(' ')

        if (key !== 'date') {
          if (key !== 'period' || (key === 'period' && addedFilters && !(key in addedFilters))) {
            if (!removedFilters[key]) {
              removedFilters[key] = []
            }

            removedFilters[key].push(value)
          }
        } else {
          startDate = value
          endDate = value
        }
      })

      if (Object.keys(removedFilters).length > 0) {
        this.pushDataLayer('remove_filter', filterType, removedFilters, startDate, endDate)
      }

      // Reset for possible re-use later
      startDate = null
      endDate = null
    }

    if (addedParam.length > 0) {
      if (!newArray.join().includes('date ')) {
        startDate = currentDate
        if (newArray.join().includes('today')) {
          endDate = currentDate
        } else if (newArray.join().includes('next-7-days')) {
          endDate = newEndDate
        } else {
          endDate = null
        }
      }

      addedParam.forEach(param => {
        const [key, value] = param.split(' ')

        if (key !== 'date') {
          if (!addedFilters[key]) {
            addedFilters[key] = []
          }

          addedFilters[key].push(value)
        } else {
          startDate = value
          endDate = value
        }
      })

      this.pushDataLayer('add_filter', filterType, addedFilters, startDate, endDate)

      // Reset for possible re-use later
      startDate = null
      endDate = null
    }
  }

  setUrl(filterUrl) {
    const url = new URL(filterUrl)
    const currentParams = new URLSearchParams(url.search)

    url.search = currentParams.toString().replace(/%2C/g, ',')
    window.history.pushState({}, '', url.toString())
  }

  handleTagBarButtons(e) {
    e.preventDefault()

    let filterType = ''

    if (e.currentTarget.classList.contains('card-highlights-grid__button')) {
      filterType = 'header_link'
    } else {
      filterType = 'refinement_chip'
    }

    this.handleFetchData(e.currentTarget.href, filterType)
  }

  handleFormSubmit(e) {
    e.target.querySelector('.datepicker-dropdown').dispatchEvent(new CustomEvent('update-datepicker-dropdown', e.target))
    e.preventDefault()
    const formData = new FormData(e.target)
    const params = new URLSearchParams(formData)

    const GetURL = `${e.target.action}?${params.toString()}`

    this.handleFetchData(GetURL, 'dropdown_filter')
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.whats-on-controller').forEach(element => {
    if (typeof element !== 'undefined' && Object.prototype.hasOwnProperty.call(element, 'instance')) {
      element.instance.init()
    } else {
      element.instance = new WhatsOnControllerComponent(element)
    }
  })
)
