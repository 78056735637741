import { createFocusTrap } from 'focus-trap'
import moment from 'moment'
import Component from '../../../assets/scripts/modules/component'

const CUSTOM_FORMAT = 'DD-MM-YYYY' // Datepicker uses this format internally -RVP

class DatePickerSingleFieldComponent extends Component {
  init() {
    this.calendar = document.querySelector('.datepicker-single-field__calendar-container')
    this.input = this.element.querySelector('.date__input')
    this.calendarButton = this.element.querySelector('.datepicker-single-field__calendar-icon')
    this.datePicker = this.calendar.querySelector('.datepicker')

    this.initAccessibility()
    this.initCalendar()
  }

  initCalendar() {
    const dateFormat = this.datePicker.dataset.dateFormat
    this.datePicker.dataset.locale === 'ar' ? moment.locale('ar') : moment.locale('en-gb')

    const urlParams = new URLSearchParams(window.location.search)
    const date = urlParams.get('date')
    if (date) {
      this.input.value = moment(date).format(dateFormat || CUSTOM_FORMAT)
      this.calendarButton.ariaLabel = `Change date, ${this.input.value}`
    }

    this.calendarButton.addEventListener('click', event => this.onClickHandler(event))
    this.input.addEventListener('click', event => this.onClickHandler(event))
    this.element.addEventListener('disableFocusTrap', () => {
      this.datePickerFocusTrap.deactivate()
    })
    window.requestAnimationFrame(() => this.element.querySelector('.datepicker').dispatchEvent(new CustomEvent('activate')))
  }

  initAccessibility() {
    this.datePickerFocusTrap = createFocusTrap(this.element, {
      onActivate: () => {
        window.requestAnimationFrame(() => this.element.querySelector('.datepicker').dispatchEvent(new CustomEvent('activate')))
        this.preventArrowScrollingWindow(true)
      },
      onDeactivate: () => {
        this.calendar.ariaHidden = 'true'
        this.input.classList.remove('date__input--active')
        if (this.input.value) {
          this.calendarButton.ariaLabel = `Change date, ${this.input.value}`
        } else {
          this.calendarButton.ariaLabel = `Pick a date`
        }
        this.calendarButton.focus()
        this.preventArrowScrollingWindow(false)
      },
      escapeDeactivates: true,
      clickOutsideDeactivates: true,
      returnFocusOnDeactivate: true,
      initialFocus: this.todayField,
    })
  }

  stopWindowScrolling(e) {
    if (['Space', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].indexOf(e.code) > -1) {
      e.preventDefault()
    }
  }

  preventArrowScrollingWindow(prevent = true) {
    if (prevent) {
      window.addEventListener('keydown', this.stopWindowScrolling, false)
    } else {
      window.removeEventListener('keydown', this.stopWindowScrolling, false)
    }
  }

  onClickHandler(event) {
    event.preventDefault()
    if (this.calendar.ariaHidden === 'true') {
      this.calendar.ariaHidden = 'false'
      this.input.classList.add('date__input--active')
      window.requestAnimationFrame(() => this.element.querySelector('.datepicker').dispatchEvent(new CustomEvent('activate')))
      this.datePickerFocusTrap.activate()
    } else {
      this.datePickerFocusTrap.deactivate()
      this.calendarButton.focus()
    }
  }

  disableFocusTrap() {
    this.datePickerFocusTrap.deactivate()
  }
}

window.addEventListener('mouseup', function (event) {
  const calendar = document.querySelector('.datepicker-single-field__calendar-container')
  if (!calendar) return
  const input = document.querySelector('.datepicker-single-field__datepicker-field-wrapper .date__input')
  const calendarIcon = document.querySelector('.datepicker-single-field__calendar-icon')
  if (!calendarIcon) {
    return
  }
  const calendarElements = [...calendar.querySelectorAll('*'), calendar, input, ...calendarIcon.querySelectorAll('*')]

  if (!calendarElements.includes(event.target)) {
    calendar.ariaHidden = 'true'
    input.classList.remove('date__input--active')
  }
})

window.addEventListener('init-load', () =>
  document.querySelectorAll('.datepicker-single-field').forEach(element => {
    element.instance = element.instance || new DatePickerSingleFieldComponent(element)
  })
)
