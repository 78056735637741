import Component from '../../../assets/scripts/modules/component'

class AddToCalendarButtonComponent extends Component {
  init() {
    this.name = this.element.dataset.name || ''
    this.description = this.element.dataset.description || ''
    this.startDate = this.element.dataset.startDate || ''
    this.endDate = this.element.dataset.endDate || ''
    this.startTime = this.element.dataset.startTime || null
    this.endTime = this.element.dataset.endTime || null
    this.timezone = this.element.dataset.timezone || 'Asia/Qatar'
    this.location = this.element.dataset.location || ''
    this.icalFilename = this.element.dataset.icalFilename || 'reminder'
    this.language = this.element.dataset.language || 'en'

    if (!this.name || !this.startDate) {
      return
    }

    this.initClickHandler()
  }

  async initClickHandler() {
    this.addToCalendarButton = (await import('../../../assets/scripts/plugins/add-to-calendar-button')).default()
    this.element.addEventListener('click', event => this.onClickHandler(event))
  }

  onClickHandler(event) {
    event.preventDefault()

    this.addToCalendarButton(
      {
        name: this.name,
        description: this.description,
        startDate: this.startDate,
        endDate: this.endDate,
        startTime: this.startTime,
        endTime: this.endTime,
        timeZone: this.timezone,
        location: this.location,
        iCalFileName: this.icalFilename,
        language: this.language,
        options: ['apple', 'google', 'ical'], // Options: 'apple', 'google', 'ical', 'Microsoft365', 'Outlook.com', 'MicrosoftTeams', 'Yahoo'
        listStyle: 'dropdown', // Options: 'inline', 'modal', 'dropdown'
        background: false,
        trigger: 'hover', // Options: 'hover', 'click'
      },
      this.element
    )
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.add-to-calendar-button').forEach(element => {
    element.instance = element.instance || new AddToCalendarButtonComponent(element)
  })
)
