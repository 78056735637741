
export default function scrollIfNeeded (element, predicate, delay = 150) {
  if (predicate === undefined) { predicate = window.location.search.toString().match(/(period|date|location|tags|page|event_type|audience)/) }
  if (predicate) {
    const scrollPositionTarget = window.pageYOffset + element.getBoundingClientRect().top
    setTimeout(() => {
      window.scrollTo(0, 0) // Quick n dirty fix for wrong scroll target when scrolled past the bar
      window.scroll({ top: scrollPositionTarget, left: 0, behavior: 'smooth' })
      element.querySelector('.button--selected')?.focus()
    }, delay)
  }
}
