import Component from '../../../assets/scripts/modules/component'

export default class CelebrationHeaderComponent extends Component {
  init() {
    this.theme = this.element.dataset.theme
    this.isTextContrastDark = this.theme === 'yellow' || this.theme === 'white'

    this.menuBar = document.querySelector('.menu-bar--transparent')
    this.menuBarWrapper = document.querySelector('.menu-bar__wrapper')

    if (!this.menuBar || !this.menuBarWrapper) return

    this.contrastTransparentMenuColor()
  }

  contrastTransparentMenuColor() {
    this.menuBar.style.color = '#000'
    this.menuBarWrapper.style.borderBlockEndColor = '#000'
  }
}

window.addEventListener('init-immediate', () =>
  document.querySelectorAll('.celebration-header').forEach(element => {
    element.instance = element.instance || new CelebrationHeaderComponent(element)
  })
)
