import Component from '../../../assets/scripts/modules/component'

export default class TicketsActionBar extends Component {
  init() {
    this.observer = null

    this.initLogin()
  }

  initLogin() {
    this.buttonsWrapper = this.element.querySelector('.tickets-action-bar__buttons')
    this.loginButton = this.element.querySelector('.tickets-action-bar__login-button')
    this.loginForm = this.element.querySelector('.tickets-auth-form--login')

    if (!this.loginForm) {
      return
    }

    this.loginFormError = this.loginForm.querySelector('.text-field--error')

    const toggle = event => {
      if (event) {
        event.preventDefault()
      }

      this.loginForm.classList.add('tickets-auth-form--visible')

      if (this.buttonsWrapper) {
        this.buttonsWrapper.classList.add('tickets-action-bar__buttons--hide')
      }
    }

    // Show form on pageload if backend renders an error -PVI
    if (this.loginFormError) {
      toggle()
    }

    if (this.loginButton) {
      this.loginButton.addEventListener('click', event => toggle(event))
    }
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.tickets-action-bar--not-logged-in').forEach(element => {
    element.instance = element.instance || new TicketsActionBar(element)
  })
)
