import Component from '../../../assets/scripts/modules/component'

class ArticleHeaderHubsComponent extends Component {
  init() {
    this.initCaption()
  }

  initCaption() {
    const button = this.element.querySelector('.article-header-hubs__picture-caption-icon')
    const wrapper = this.element.querySelector('.article-header-hubs__picture-caption')

    if (!button || !wrapper) {
      return
    }

    this.captionActive = false

    button.addEventListener('click', () => {
      wrapper.classList.toggle('article-header-hubs__picture-caption--expanded', !this.captionActive)
      this.captionActive = !this.captionActive
    })
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.article-header-hubs').forEach(element => {
    element.instance = element.instance || new ArticleHeaderHubsComponent(element)
  })
)
