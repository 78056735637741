import Component from '../../../assets/scripts/modules/component'

// FIXME: Quick and easy fix for the sticky button. It is not working optimal (Refactor to inserting one button instead of 2 buttons) -KLI

export default class DropdownFilterBarComponent extends Component {
  init() {
    this.form = this.element.querySelector('.dropdown-filter-bar__form')
    this.dropdownFilterBarMobileButton = this.element.querySelector('.dropdown-filter-bar-mobile__button')
    this.dropdownFilterBarMobile = this.element.querySelector('.dropdown-filter-bar__filter-button-mobile')
    this.dropdownFilterBar = document.querySelector('.dropdown-filter-bar')
    this.cardGrid = document.querySelector('.card-grid')

    this.dropdownFilterBarMobileButton.addEventListener('click', () => window.dispatchEvent(new CustomEvent('open-mobile-filter-bar-overlay')))

    // this.dropdownFilterBar.style.height = `${this.dropdownFilterBarMobileButton.offsetHeight}px`

    // if (!this.dropdownFilterBar) {

    // }

    // if (this.dropdownFilterBarSecond) {
    //   this.dropdownFilterBarSecond.style.height = `${this.dropdownFilterBarMobileButton.offsetHeight}px`
    // }

    // document.addEventListener('scroll', () => {
    //   this.observer.observe(this.dropdownFilterBar)
    //   this.observerSecondaryDropdownFilterBar.observe(this.cardGrid)
    // })

    // const previousY = 0
    // const previousRatio = 0

    //   this.observer = new IntersectionObserver(
    //     ([entry]) => {
    //       const currentY = entry.boundingClientRect.y
    //       const currentRatio = entry.intersectionRatio
    //       const isIntersecting = entry.isIntersecting

    //       if (currentRatio < previousRatio && !isIntersecting) {
    //         // console.log('Scrolling up leave')
    //         this.dropdownFilterBar.classList.add('dropdown-filter-bar__relative-container')
    //       }

    //       if (currentY > previousY && isIntersecting) {
    //         if (currentRatio < previousRatio && !isIntersecting) {
    //           // console.log('Scrolling up leave')
    //           this.dropdownFilterBarMobile.classList.remove('fixed--button')
    //         } else {
    //           // console.log('Scrolling up enter')
    //           this.dropdownFilterBarMobile.classList.add('fixed--button')
    //           this.dropdownFilterBar.classList.remove('dropdown-filter-bar__relative-container')
    //         }
    //       } else if (currentY < previousY) {
    //         if (currentRatio > previousRatio && isIntersecting) {
    //           // console.log('Scrolling down enter')
    //           this.dropdownFilterBarMobile.classList.add('fixed--button')
    //           this.dropdownFilterBar.classList.remove('dropdown-filter-bar__relative-container')
    //         } else {
    //           // console.log('Scrolling down leave')
    //           this.dropdownFilterBar.classList.remove('dropdown-filter-bar__relative-container')
    //         }
    //       }

    //       previousY = currentY
    //       previousRatio = currentRatio
    //     },
    //     {
    //       root: null,
    //       threshold: 0.1, // set offset 0.1 means trigger if atleast 10% of element in viewport
    //     }
    //   )

    //   this.observerSecondaryDropdownFilterBar = new IntersectionObserver(
    //     ([entry]) => {
    //       const currentY = entry.boundingClientRect.y
    //       const currentRatio = entry.intersectionRatio
    //       const isIntersecting = entry.isIntersecting

    //       if (currentY > previousY && isIntersecting) {
    //         if (currentRatio < previousRatio && !isIntersecting) {
    //           // console.log('observerSecondaryDropdownFilterBar Scrolling up leave')
    //         } else {
    //           // console.log('observerSecondaryDropdownFilterBar Scrolling up enter')
    //           this.dropdownFilterBarMobile.classList.add('fixed--button')
    //         }
    //       } else if (currentY < previousY) {
    //         if (currentRatio > previousRatio && isIntersecting) {
    //           // console.log('observerSecondaryDropdownFilterBar Scrolling down enter')
    //           this.dropdownFilterBarMobile.classList.add('fixed--button')
    //         } else {
    //           // console.log('observerSecondaryDropdownFilterBar Scrolling down leave')
    //           this.dropdownFilterBarMobile.classList.remove('fixed--button')
    //         }
    //       }

    //       previousY = currentY
    //       previousRatio = currentRatio
    //     },
    //     {
    //       root: null,
    //       threshold: 0.1, // set offset 0.1 means trigger if atleast 10% of element in viewport
    //     }
    //   )
  }

  // handleSubmit(e) {
  //   const formData = new FormData(e.target)

  //   const formObject = {}
  //   formData.keys().forEach(key => {
  //     formObject[key] = formData.getAll(key)
  //   })
  //   console.log('Form Data:', formObject)
  // }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.dropdown-filter-bar').forEach(element => {
    element.instance = element.instance || new DropdownFilterBarComponent(element)
  })
)
