import { createFocusTrap } from 'focus-trap'

import throttle from 'lodash/throttle'
import breakpoints from '../../../assets/scripts/modules/breakpoints'
import Component from '../../../assets/scripts/modules/component'
import Enemy from '../../../assets/scripts/modules/game-mal-lawal-2024/enemy.js'
import Obstacle from '../../../assets/scripts/modules/game-mal-lawal-2024/obstacle.js'
import Pearl from '../../../assets/scripts/modules/game-mal-lawal-2024/pearl.js'
import Player from '../../../assets/scripts/modules/game-mal-lawal-2024/player.js'
import konamiCode from '../../../assets/scripts/utilities/konami-code.js'

class MalLawalGameComponent extends Component {
  async init() {
    konamiCode(() => this.doKonamiCode())

    this.reduceMotion = window.matchMedia('(prefers-reduced-motion: reduce)')
    this.staticPath = this.element.dataset.staticPath

    try {
      const { P5 } = (await import('../../../assets/scripts/plugins/p5')).default()
      this.initializeP5(P5)
      // new P5sound()
    } catch (error) {
      // console.log('error:', error)
    }

    this.initAccessibility()
  }

  initializeP5(P5) {
    if (!this.p5Instance) {
      this.p5Instance = new P5(this.testP5.bind(this))
    }
  }

  doKonamiCode = () => {
    this.pearlSprite = this.p5Instance.loadImage(`${this.staticPath}/dumpling.png`)
  }

  vh = v => {
    return (window.innerHeight * 0.5 * v) / 100
  }

  vw = v => {
    return (window.innerWidth * 0.5 * v) / 100
  }

  handleStart = () => {
    const malLawalGameContainer = document.querySelector('.mal-lawal-game__mal-lawal-wrapper')
    malLawalGameContainer.classList.add('mal-lawal-game__show-buttons')
  }

  startGame = () => {
    this.setAriaHiddenOnOtherElements(true)
    this.hideOverlayTabbableElements(false)
    this.overlayFocusTrap.activate()

    this.gameStarted = true
    this.score = 0
    this.timeLeft = 60

    const malLawalGameContainer = document.querySelector('.mal-lawal-game__game-container')

    if (malLawalGameContainer) {
      document.documentElement.classList.add('prevent-scrolling')

      setTimeout(() => {
        malLawalGameContainer.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }, 100)
    }

    document.getElementById('start-screen').style.display = 'none'
    document.getElementById('end-screen').style.display = 'none'
    document.getElementById('game-area').style.display = 'block'
    document.getElementById('score-total').innerText = ` ${this.score}`
    document.getElementById('score-timer').innerText = ` ${this.timeLeft}`

    // Genera perle
    this.spawnPearls(10)

    // Avvia il timer
    this.timerInterval = setInterval(this.decreaseTimer, 1000)

    // Avvia la musica di background se attiva
    if (this.isSoundOn) {
      this.backgroundMusic.loop()
    }
  }

  restartGame = () => {
    const malLawalGameContainer = document.querySelector('.mal-lawal-game__mal-lawal-wrapper')

    if (malLawalGameContainer.classList.contains('mal-lawal-game__show-buttons')) {
      malLawalGameContainer.classList.remove('mal-lawal-game__show-buttons')
    }

    this.setAriaHiddenOnOtherElements(false)
    this.hideOverlayTabbableElements(true)
    this.overlayFocusTrap.deactivate()

    // Reset delle variabili di gioco
    document.documentElement.classList.remove('prevent-scrolling')
    this.score = 0
    this.timeLeft = 60

    // Reimposta gli ostacoli
    this.obstacles = []
    this.enemies = []

    if (breakpoints.isPortrait()) {
      this.player = new Player(this.p5Instance, this.p5Instance.width / 2 - (10 * this.vh(1)) / 2, 10 * this.vh(1), 10 * this.vh(1), 20 * this.vh(1), this.ominoLeftSprite, this.ominoRightSprite, this.collideRectRect, this.ominoDownRightSprite, this.ominoDownLeftSprite)

      // Create instances of the Obstacle class and add them to the obstacles array
      this.obstacles.push(new Obstacle(this.p5Instance, this.p5Instance.width / 2 - 30 * this.vh(1) - 20 * this.vh(1), this.p5Instance.height / 2 - 15 * this.vh(1), 40 * this.vh(1), 30 * this.vh(1), this.obstacleSprite))
      this.obstacles.push(new Obstacle(this.p5Instance, this.p5Instance.width / 2 + 30 * this.vh(1), this.p5Instance.height / 2 - 15 * this.vh(1), 40 * this.vh(1), 30 * this.vh(1), this.obstacleSprite))
      this.obstacles.push(new Obstacle(this.p5Instance, 20 * this.vh(1), 3 + 20 * this.vh(1), 40 * this.vh(1), 30 * this.vh(1), this.obstacleSprite))
      this.obstacles.push(new Obstacle(this.p5Instance, this.p5Instance.width - 20 * this.vh(1), this.p5Instance.height - 60 * this.vh(1), 40 * this.vh(1), 30 * this.vh(1), this.obstacleSprite))

      this.enemies.push(new Enemy(this.p5Instance, 10 * this.vh(1), this.p5Instance.height - 10 * this.vh(1) - 10 * this.vh(1), 20 * this.vh(1), 10 * this.vh(1), this.sharkLeftSprite, this.sharkRightSprite, this.collideRectRect))
      this.enemies.push(new Enemy(this.p5Instance, this.p5Instance.width - 30 * this.vh(1), this.p5Instance.height - 10 * this.vh(1) - 10 * this.vh(1), 20 * this.vh(1), 10 * this.vh(1), this.sharkLeftSprite, this.sharkRightSprite, this.collideRectRect))
    } else {
      this.player = new Player(this.p5Instance, this.p5Instance.width / 2 - (5 * this.vh(1)) / 2, 5 * this.vh(1), 15 * this.vw(1), 30 * this.vw(1), this.ominoLeftSprite, this.ominoRightSprite, this.collideRectRect, this.ominoDownRightSprite, this.ominoDownLeftSprite)

      // Create instances of the Obstacle class and add them to the obstacles array
      this.obstacles.push(new Obstacle(this.p5Instance, this.p5Instance.width / 2 - 40 * this.vw(1), this.p5Instance.height / 2 - 15 * this.vw(1) - 5 * this.vh(1), 50 * this.vw(1), 33 * this.vw(1), this.obstacleSprite))
      this.obstacles.push(new Obstacle(this.p5Instance, 20 * this.vw(1), 3 + 30 * this.vw(1), 50 * this.vw(1), 33 * this.vw(1), this.obstacleSprite))
      this.obstacles.push(new Obstacle(this.p5Instance, this.p5Instance.width - 70 * this.vw(1), this.p5Instance.height - 50 * this.vw(1) - 20 * this.vh(1), 50 * this.vw(1), 33 * this.vw(1), this.obstacleSprite))

      this.enemies.push(new Enemy(this.p5Instance, 5 * this.vh(1), this.p5Instance.height - 5 * this.vw(1) - 10 * this.vh(1), 30 * this.vw(1), 15 * this.vw(1), this.sharkLeftSprite, this.sharkRightSprite, this.collideRectRect))
      this.enemies.push(new Enemy(this.p5Instance, this.p5Instance.width - 25 * this.vw(1), this.p5Instance.height - 25 * this.vw(1) - 10 * this.vh(1), 30 * this.vw(1), 15 * this.vw(1), this.sharkLeftSprite, this.sharkRightSprite, this.collideRectRect))
    }

    this.collisionSquare = null

    // Reimposta le perle
    this.spawnPearls(10)

    // Mostra la schermata di inizio
    document.getElementById('start-screen').style.display = 'block'
    document.getElementById('end-screen').style.display = 'none'
    document.getElementById('game-area').style.display = 'none'

    // Ferma il timer
    clearInterval(this.timerInterval)

    // Ferma la musica di background se attiva
    if (this.isSoundOn) {
      this.backgroundMusic.stop()
    }
  }

  endGame = endGameCondition => {
    const malLawalGameContainer = document.querySelector('.mal-lawal-game__mal-lawal-wrapper')

    if (malLawalGameContainer.classList.contains('mal-lawal-game__show-buttons')) {
      malLawalGameContainer.classList.remove('mal-lawal-game__show-buttons')
    }

    this.setAriaHiddenOnOtherElements(false)
    this.hideOverlayTabbableElements(true)
    this.overlayFocusTrap.deactivate()

    document.documentElement.classList.remove('prevent-scrolling')

    this.gameStarted = false

    // add bonus if collecting all pearls
    let bonusTimeLeft = 20 * this.timeLeft
    if (endGameCondition !== 'gameCompleted') {
      bonusTimeLeft = 0
    }

    let endMessage = 'Game Over!'
    let endGameMessages = {}
    const endGameMesagesEndGameConditionMapping = {
      completed: 'gameCompleted',
      gameover: 'gameOver',
      timesup: 'timesUp',
    }

    const playAgainButton = document.getElementById('play-again-button')
    let playAgainButtonTitle = 'Play Again'
    let playAgainButtonTitles = {}

    for (const gameCondition of ['completed', 'gameover', 'timesup']) {
      let datasetId = `endGame${gameCondition[0].toUpperCase() + gameCondition.slice(1)}Title`
      if (datasetId in document.getElementById('end-message').dataset) {
        endGameMessages[endGameMesagesEndGameConditionMapping[gameCondition]] = document.getElementById('end-message').dataset[datasetId]
      }

      if (playAgainButton) {
        let playAgainButtonDatasetId = `endGame${gameCondition[0].toUpperCase() + gameCondition.slice(1)}RestartButtonTitle`
        if (playAgainButtonDatasetId in playAgainButton.dataset) {
          playAgainButtonTitles[endGameMesagesEndGameConditionMapping[gameCondition]] = playAgainButton.dataset[playAgainButtonDatasetId]
        }
      }
    }

    document.getElementById('end-screen').style.display = 'block'
    document.getElementById('end-message').innerText = endGameCondition in endGameMessages ? endGameMessages[endGameCondition] : endMessage
    document.getElementById('final-score-total').innerText = `${this.score + bonusTimeLeft}`
    playAgainButton.innerText = endGameCondition in playAgainButtonTitles ? playAgainButtonTitles[endGameCondition] : playAgainButtonTitle

    if (endGameCondition == 'gameCompleted') {
      document.querySelector('.mal-lawal-game__game-over-text').style.display = 'none'
      document.querySelector('.mal-lawal-game__game-won-text').style.display = 'block'

      if (breakpoints.isMobileLandscape()) {
        document.querySelector('.mal-lawal-game__game-over-text').style.display = 'none'
        document.querySelector('.mal-lawal-game__game-won-text').style.display = 'none'
      }
    } else if (endGameCondition == 'timesUp') {
      document.querySelector('.mal-lawal-game__game-over-text').style.display = 'block'
      document.querySelector('.mal-lawal-game__game-won-text').style.display = 'none'

      if (breakpoints.isMobileLandscape()) {
        document.querySelector('.mal-lawal-game__game-over-text').style.display = 'none'
        document.querySelector('.mal-lawal-game__game-won-text').style.display = 'none'
      }
    } else {
      document.querySelector('.mal-lawal-game__game-over-text').style.display = 'block'
      document.querySelector('.mal-lawal-game__game-won-text').style.display = 'none'

      if (breakpoints.isMobileLandscape()) {
        document.querySelector('.mal-lawal-game__game-over-text').style.display = 'none'
        document.querySelector('.mal-lawal-game__game-won-text').style.display = 'none'
      }
    }

    clearInterval(this.timerInterval)

    // Ferma la musica di background se attiva
    if (this.isSoundOn) {
      this.backgroundMusic.stop()
    }
  }

  // preventOverlap = (entity, obstacle) => {
  //   // Gestisce la collisione tra l'entità e l'ostacolo
  //   if (entity.y < obstacle.y) {
  //     entity.y = obstacle.y - entity.height
  //   } else if (entity.y > obstacle.y + obstacle.height) {
  //     entity.y = obstacle.y + obstacle.height
  //   }

  //   if (entity.x < obstacle.x) {
  //     entity.x = obstacle.x - entity.width
  //   } else if (entity.x > obstacle.x + obstacle.width) {
  //     entity.x = obstacle.x + obstacle.width
  //   }
  // }

  spawnPearls = count => {
    this.pearls = []
    const maxAttempts = 1000 // Numero massimo di tentativi prima di ridurre la distanza
    let minDistance = null
    let reducedDistance = null

    if (breakpoints.isPortrait()) {
      minDistance = 30 * this.vw(1)
      reducedDistance = 10 * this.vw(1)
    } else {
      minDistance = 40 * this.vh(1)
      reducedDistance = 20 * this.vh(1)
    }

    for (let i = 0; i < count; i++) {
      let pearl
      let overlap = false
      let attempts = 0 // Tentativi fatti per posizionare una perla

      do {
        overlap = false

        if (breakpoints.isPortrait()) {
          pearl = new Pearl(this.p5Instance, this.p5Instance.random(10 * this.vh(1), this.p5Instance.width - 10 * this.vh(1)), this.p5Instance.random(10, this.p5Instance.height - 10), 10 * this.vh(1), this.pearlSprite)
        } else {
          pearl = new Pearl(this.p5Instance, this.p5Instance.random(10 * this.vh(1), this.p5Instance.width - 10 * this.vh(1)), this.p5Instance.random(10, this.p5Instance.height - 10), 15 * this.vw(1), this.pearlSprite)
        }

        // Controllo collisioni con ostacoli

        for (const obstacle of this.obstacles) {
          if (this.collideRectCircle(obstacle.x, obstacle.y, obstacle.width, obstacle.height, pearl.x, pearl.y, pearl.size)) {
            overlap = true
            break
          }
        }

        // Controllo distanza con altre perle
        for (const otherPearl of this.pearls) {
          const distance = this.p5Instance.dist(pearl.x, pearl.y, otherPearl.x, otherPearl.y)
          if (distance < minDistance) {
            overlap = true
            break
          }
        }

        attempts++

        // Se abbiamo fatto troppi tentativi con minDistance di 40vh, riduciamo a 20vh
        if (attempts >= maxAttempts && minDistance === 40 * this.vh(1)) {
          minDistance = reducedDistance
          attempts = 0 // Resetta i tentativi per provare con la nuova distanza
        }

        // Se anche con 20vh non troviamo una posizione, accettiamo qualsiasi posizione valida (senza sovrapposizione)
        if (attempts >= maxAttempts && minDistance === 20 * this.vh(1)) {
          minDistance = 0 // Disabilita il controllo della distanza minima
          attempts = 0 // Resetta i tentativi per tentare di posizionare senza limiti di distanza
        }
      } while (overlap && attempts < maxAttempts * 2) // Condizione di uscita per evitare loop infinito

      // Alla fine aggiungiamo la perla, indipendentemente da quanti tentativi sono stati fatti
      this.pearls.push(pearl)
    }
  }

  collideRectRect = (x1, y1, w1, h1, x2, y2, w2, h2) => {
    return x1 < x2 + w2 && x1 + w1 > x2 && y1 < y2 + h2 && y1 + h1 > y2
  }

  collideRectCircle = (rx, ry, rw, rh, cx, cy, diameter) => {
    let testX = cx
    let testY = cy

    if (cx < rx) testX = rx
    else if (cx > rx + rw) testX = rx + rw
    if (cy < ry) testY = ry
    else if (cy > ry + rh) testY = ry + rh

    const distX = cx - testX
    const distY = cy - testY
    const distance = Math.sqrt(distX * distX + distY * distY)

    return distance <= diameter / 2
  }

  collideCircleCircle = (x1, y1, d1, x2, y2, d2) => {
    const distance = this.p5Instance.dist(x1, y1, x2, y2)
    return distance < d1 / 2 + d2 / 2
  }

  decreaseTimer = () => {
    if (this.timeLeft > 0) {
      this.timeLeft--
      document.getElementById('score-timer').innerText = `${this.timeLeft}`
    }
  }

  constrainEntity = entity => {
    entity.x = this.p5Instance.constrain(entity.x, 0, this.p5Instance.width - entity.width)
    entity.y = this.p5Instance.constrain(entity.y, 0, this.p5Instance.height - entity.height)
  }

  toggleSound = () => {
    this.isSoundOn = !this.isSoundOn
    const soundButton = document.getElementById('sound-button')
    if (this.isSoundOn) {
      soundButton.innerText = soundButton.dataset.soundOnButtonTitle
      if (this.gameStarted) {
        this.backgroundMusic.loop()
      }
    } else {
      soundButton.innerText = soundButton.dataset.soundOffButtonTitle
      if (this.backgroundMusic.isPlaying()) {
        this.backgroundMusic.stop()
      }
    }
  }

  setAriaHiddenOnOtherElements(hidden = true) {
    const nonModalDialogVisibleElements = [...document.querySelectorAll('.container--content')]
    const modalDialogVisibleElements = [this.element]

    nonModalDialogVisibleElements.forEach(element => element.setAttribute('aria-hidden', hidden))
    modalDialogVisibleElements.forEach(element => element.setAttribute('aria-hidden', !hidden))
  }

  hideOverlayTabbableElements(hidden = true) {
    const focusableElements = [...this.element.querySelectorAll('a[href], button, input, select, textarea')]
    focusableElements.forEach(element => (hidden ? element.setAttribute('tabindex', -1) : element.removeAttribute('tabindex')))
  }

  initAccessibility() {
    this.setAriaHiddenOnOtherElements(false)
    this.hideOverlayTabbableElements(true)

    const buttonClose = document.getElementById('cancel-game-button')
    const gameContainer = document.querySelector('.mal-lawal-game__game-container')

    this.overlayFocusTrap = createFocusTrap(gameContainer, {
      onActivate: () => {
        buttonClose.focus()
        buttonClose.blur()
      },
      onDeactivate: () => {
        buttonClose.focus()
      },
      escapeDeactivates: true,
      clickOutsideDeactivates: true,
      returnFocusOnDeactivate: true,
      initialFocus: buttonClose,
    })
  }

  testP5(p) {
    // Variabili globali
    this.player
    this.enemies = []
    this.pearls = []
    this.obstacles = []
    this.score = 0
    this.timeLeft = 60
    this.timerInterval
    this.gameStarted = false
    this.collisionSquare = null // Per il cerchio rosso della collisione
    this.backgroundMusic = null
    this.successSound = null
    this.gameoverSound = null
    this.crashSound
    this.coinSound
    this.isSoundOn = false // Default senza suono

    // sprites
    this.sharkRightSprite
    this.sharkLeftSprite
    this.pearlSprite
    this.obstacleSprite
    this.ominoRightSprite
    this.ominoLeftSprite
    this.ominoDownLeftSprite
    this.ominoDownRightSprite

    this.reduceMotionText = document.querySelector('.mal-lawal-game__reduce-motion-text')
    this.startButton = document.getElementById('start-button')

    // read more pop up, start screen
    document.getElementById('read-more-button').addEventListener('click', function () {
      document.querySelector('.mal-lawal-game__info-pop-up').classList.add('active')
    })

    document.querySelector('.mal-lawal-game__close-info-pop-up').addEventListener('click', function () {
      document.querySelector('.mal-lawal-game__info-pop-up').classList.remove('active')
    })

    p.preload = () => {
      // Precarica i suoni
      p.soundFormats('mp3', 'ogg')
      this.backgroundMusic = p.loadSound(`${this.staticPath}/sounds/bg`)
      this.successSound = p.loadSound(`${this.staticPath}/sounds/win-2`)
      this.gameoverSound = p.loadSound(`${this.staticPath}/sounds/gameover`)
      this.crashSound = p.loadSound(`${this.staticPath}/sounds/crash`)
      this.coinSound = p.loadSound(`${this.staticPath}/sounds/coin`)

      // Precarica le sprite
      this.sharkRightSprite = p.loadImage(`${this.staticPath}/shark-r.png`)
      this.sharkLeftSprite = p.loadImage(`${this.staticPath}/shark-l.png`)
      this.pearlSprite = p.loadImage(`${this.staticPath}/pearl.png`)
      this.obstacleSprite = p.loadImage(`${this.staticPath}/obstacle.png`)
      this.ominoRightSprite = p.loadImage(`${this.staticPath}/diver-r.png`)
      this.ominoLeftSprite = p.loadImage(`${this.staticPath}/diver-l.png`)
      this.ominoDownLeftSprite = p.loadImage(`${this.staticPath}/diver-d-l.png`)
      this.ominoDownRightSprite = p.loadImage(`${this.staticPath}/diver-d-r.png`)
    }

    p.setup = () => {
      const gameArea = document.getElementById('game-area')

      // Ottieni la larghezza e l'altezza del parent 'gameArea'
      const canvas = p.createCanvas(gameArea.offsetWidth, gameArea.offsetHeight)
      canvas.parent(gameArea)

      this.pearl = new Pearl(p, p.random(20 * this.vh(1), p.width - 20 * this.vh(1)), p.random(100, p.height - 100), 10 * this.vh(1), this.pearlSprite)

      // Imposta ostacoli al centro dello schermo con uno spazio di 40vh tra loro
      if (breakpoints.isPortrait()) {
        // Imposta l'omino (player) al centro in alto dello schermo a 10vh dal top
        this.player = new Player(p, p.width / 2 - (10 * this.vh(1)) / 2, 10 * this.vh(1), 10 * this.vh(1), 20 * this.vh(1), this.ominoLeftSprite, this.ominoRightSprite, this.collideRectRect, this.ominoDownRightSprite, this.ominoDownLeftSprite)

        if (this.obstacleSprite) {
          // Create instances of the Obstacle class and add them to the obstacles array
          this.obstacles.push(new Obstacle(p, p.width / 2 - 30 * this.vh(1) - 20 * this.vh(1), p.height / 2 - 15 * this.vh(1), 40 * this.vh(1), 30 * this.vh(1), this.obstacleSprite))
          this.obstacles.push(new Obstacle(p, p.width / 2 + 30 * this.vh(1), p.height / 2 - 15 * this.vh(1), 40 * this.vh(1), 30 * this.vh(1), this.obstacleSprite))
          this.obstacles.push(new Obstacle(p, 20 * this.vh(1), 3 + 20 * this.vh(1), 40 * this.vh(1), 30 * this.vh(1), this.obstacleSprite))
          this.obstacles.push(new Obstacle(p, p.width - 20 * this.vh(1), p.height - 60 * this.vh(1), 40 * this.vh(1), 30 * this.vh(1), this.obstacleSprite))
        } else {
          console.error('Sprite image not loaded correctly')
        }

        // Imposta nemici negli angoli in basso a sinistra e in basso a destra
        if (this.sharkLeftSprite || this.sharkRightSprite) {
          this.enemies.push(new Enemy(p, 10 * this.vh(1), p.height - 10 * this.vh(1) - 10 * this.vh(1), 20 * this.vh(1), 10 * this.vh(1), this.sharkLeftSprite, this.sharkRightSprite, this.collideRectRect))
          this.enemies.push(new Enemy(p, p.width - 30 * this.vh(1), p.height - 10 * this.vh(1) - 10 * this.vh(1), 20 * this.vh(1), 10 * this.vh(1), this.sharkLeftSprite, this.sharkRightSprite, this.collideRectRect))
        }
      } else {
        this.player = new Player(p, p.width / 2 - (5 * this.vh(1)) / 2, 5 * this.vh(1), 15 * this.vw(1), 30 * this.vw(1), this.ominoLeftSprite, this.ominoRightSprite, this.collideRectRect, this.ominoDownRightSprite, this.ominoDownLeftSprite)

        if (this.obstacleSprite) {
          // Create instances of the Obstacle class and add them to the obstacles array
          this.obstacles.push(new Obstacle(p, p.width / 2 - 40 * this.vw(1), p.height / 2 - 15 * this.vw(1) - 5 * this.vh(1), 50 * this.vw(1), 33 * this.vw(1), this.obstacleSprite))
          this.obstacles.push(new Obstacle(p, 20 * this.vw(1), 3 + 30 * this.vw(1), 50 * this.vw(1), 33 * this.vw(1), this.obstacleSprite))
          this.obstacles.push(new Obstacle(p, p.width - 70 * this.vw(1), p.height - 50 * this.vw(1) - 20 * this.vh(1), 50 * this.vw(1), 33 * this.vw(1), this.obstacleSprite))
        } else {
          console.error('Sprite image not loaded correctly')
        }

        if (this.sharkLeftSprite || this.sharkRightSprite) {
          this.enemies.push(new Enemy(p, 5 * this.vh(1), p.height - 5 * this.vw(1) - 10 * this.vh(1), 30 * this.vw(1), 15 * this.vw(1), this.sharkLeftSprite, this.sharkRightSprite, this.collideRectRect))
          this.enemies.push(new Enemy(p, p.width - 25 * this.vw(1), p.height - 25 * this.vw(1) - 10 * this.vh(1), 30 * this.vw(1), 15 * this.vw(1), this.sharkLeftSprite, this.sharkRightSprite, this.collideRectRect))
        }
      }

      if (!this.reduceMotion.matches) {
        document.getElementById('start-button').addEventListener('touchstart', event => {
          event.preventDefault()
          this.handleStart()
        })

        document.getElementById('start-button').addEventListener('pointerdown', this.startGame)

        document.getElementById('cancel-game-button').addEventListener('click', this.restartGame)
        document.getElementById('play-again-button').addEventListener('click', this.restartGame)
        document.getElementById('sound-button').addEventListener('click', this.toggleSound)

        // document.addEventListener('DOMContentLoaded', () => {

        this.reduceMotionText.classList.add('mal-lawal-game__hidden')
        this.startButton.classList.add('mal-lawal-game__visible')
        // })
      } else {
        this.reduceMotionText.classList.add('mal-lawal-game__visible')
        this.startButton.classList.add('mal-lawal-game__hidden')
      }

      // Aggiungi eventi per i pulsanti

      document.addEventListener('DOMContentLoaded', () => {
        document.getElementById('start-screen').style.display = 'block'
        document.getElementById('end-screen').style.display = 'none'
        document.getElementById('game-area').style.display = 'none'
      })
    }

    p.draw = () => {
      if (!this.gameStarted) return

      p.clear()
      // Disegna ostacoli
      for (const obstacle of this.obstacles) {
        obstacle.display()
      }

      // Disegna e muovi i nemici
      for (const enemy of this.enemies) {
        if (enemy instanceof Enemy) {
          enemy.move(this.player, this.enemies, this.obstacles) // Passa l'array degli altri nemici
          enemy.display()
          this.constrainEntity(enemy)

          if (this.collideRectRect(this.player.x, this.player.y, this.player.width, this.player.height, enemy.x, enemy.y, enemy.width, enemy.height)) {
            if (this.isSoundOn) this.crashSound.play()
            this.collisionSquare = {
              x: (this.player.x + enemy.x + this.player.width) / 2 - 15 * this.vh(1),
              y: (this.player.y + enemy.y + this.player.height) / 2 - 15 * this.vh(1),
              size: 30 * this.vh(1),
            }
            this.endGame('gameOver')
            if (this.isSoundOn) this.gameoverSound.play()
            return // Interrompe l'esecuzione della funzione draw
          }
        } else {
          // console.error('Object in enemies array is not an instance of Enemy:', enemy)
        }
      }

      // Disegna perle e controllo raccolta perle
      for (let i = this.pearls.length - 1; i >= 0; i--) {
        const pearl = this.pearls[i]
        pearl.display()

        if (this.player.x && this.player.y && this.player.width && this.player.height && pearl.x && pearl.y && pearl.size) {
          if (this.collideRectCircle(this.player.x, this.player.y, this.player.width, this.player.height, pearl.x, pearl.y, pearl.size)) {
            this.pearls.splice(i, 1)
            this.score += 100
            document.getElementById('score-total').innerText = ` ${this.score}`
            if (this.isSoundOn) this.coinSound.play()
          }
        }
      }

      // Disegna omino
      this.player.display()
      this.player.move(this.obstacles)
      this.constrainEntity(this.player)

      // Disegna il cerchio rosso della collisione se esiste
      if (this.collisionSquare) {
        fill(255, 0, 0) // rosso
        noStroke()
        // ellipse(this.collisionSquare.x + this.collisionSquare.size / 2, this.collisionSquare.y + this.collisionSquare.size / 2, this.collisionSquare.size)
        this.collisionSquare = null // Nasconde il cerchio dopo la collisione
      }

      // Controlla se il tempo è finito
      if (this.timeLeft <= 0) {
        this.endGame('timesUp')
        return // Interrompe l'esecuzione della funzione draw
      }

      // Controlla se tutte le perle sono state raccolte
      if (this.pearls.length === 0) {
        if (this.isSoundOn) this.successSound.play()
        this.endGame('gameCompleted')
        // Interrompe l'esecuzione della funzione draw
      }
    }

    p.windowResized = throttle(() => {
      let newWidth = window.innerWidth
      let newHeight = breakpoints.isPortrait() ? window.innerHeight * 0.7 : window.innerHeight * 0.6

      p.resizeCanvas(newWidth, newHeight)

      p.clear()
    }, 1000)
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.mal-lawal-game').forEach(element => {
    element.instance = element.instance || new MalLawalGameComponent(element)
  })
)
