import Component from '../../../assets/scripts/modules/component'
import { getCookie } from '../../../assets/scripts/utilities/simple-cookies'

export default class IframeEmbedComponent extends Component {
  init() {
    this.iframe = this.element.querySelector('.iframe-embed__iframe')

    if (!this.iframe) {
      return
    }

    this.buttonCookies = this.element.querySelector('.button--cookies')
    this.notice = this.element.querySelector('.iframe-embed__cookies-notice')

    if (this.buttonCookies) {
      this.buttonCookies.addEventListener('click', event => {
        event.preventDefault()
        window.dispatchEvent(new CustomEvent('show-cookie-details'))
      })
    }

    window.addEventListener('cookie-consent', () => this.checkCookie())
    this.checkCookie()
  }

  initIframe() {
    if (!this.iframe.getAttribute('data-src')) {
      return
    }

    this.iframe.setAttribute('src', this.iframe.getAttribute('data-src'))
    this.iframe.removeAttribute('data-src')
  }

  checkCookie() {
    const cookie = getCookie('cookie-consent')

    if (!cookie || cookie.split(',').indexOf('embeds') === -1) {
      this.showCookieNotice()
    } else {
      this.hideCookieNotice()
    }
  }

  showCookieNotice() {
    if (this.notice) {
      this.notice.removeAttribute('hidden')
    }

    this.iframe.setAttribute('hidden', 'hidden')
    this.element.classList.add('iframe-embed--cookies-not-accepted')
  }

  hideCookieNotice() {
    if (this.notice) {
      this.notice.setAttribute('hidden', 'hidden')
    }

    this.iframe.removeAttribute('hidden')
    this.element.classList.remove('iframe-embed--cookies-not-accepted')

    this.initIframe()
  }
}

window.addEventListener('init-delayed-load', () =>
  [...document.querySelectorAll('.iframe-embed')].forEach(element => {
    element.instance = element.instance || new IframeEmbedComponent(element)
  })
)
