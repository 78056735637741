import { createFocusTrap } from 'focus-trap'
import moment from 'moment'
import breakpoints from '../../../assets/scripts/modules/breakpoints'
import Component from '../../../assets/scripts/modules/component'

class DatePickerDropdownComponent extends Component {
  init() {
    this.radioButtons = this.element.querySelectorAll('.datepicker-dropdown__input')
    this.clearButton = this.element.querySelector('.datepicker-dropdown__panel-clear-btn')
    this.dropdownLabel = this.element.querySelectorAll('.datepicker-dropdown__label')
    this.submitButton = this.element.querySelector('.datepicker-dropdown__panel-submit-btn')
    this.dropdownButton = this.element.querySelector('.datepicker-dropdown__dropdown-button')
    this.dropdownPanel = this.element.querySelector('.datepicker-dropdown__panel')
    this.dropdownOverlay = this.element.querySelector('.datepicker-dropdown__overlay')

    this.calendar = this.element.querySelector('.datepicker-dropdown__calendar-container')
    this.datePicker = this.calendar.querySelector('.datepicker')

    this.selectedItems = []
    this.initialSelectedInput = null
    this.inputElement = this?.datePicker?.dataset?.inputId ? document.getElementById(this.datePicker.dataset.inputId) : null

    this.submitButton.addEventListener('click', this.handleSubmitButton.bind(this))
    this.clearButton.addEventListener('click', this.handleClearButton.bind(this))
    this.dropdownOverlay.addEventListener('click', this.handleOverlay.bind(this))
    // window.addEventListener('update-datepicker-dropdown', e => this.handleSubmitButton(e))
    this.element.addEventListener('update-datepicker-dropdown', e => this.handleSubmitButton(e))

    if (this.inputElement) {
      this.inputElement.addEventListener('change', e => {
        this.inputElement.value = moment(e.target.value)
          .locale('en-gb')
          .format(this?.datePicker?.dataset?.dateFormat || 'DD-MM-YYYY')
      })
    }

    this.radioButtons.forEach(radioButton => {
      if (radioButton.checked) {
        radioButton.closest('.datepicker-dropdown__label').classList.add('input--selected')
        this.initialSelectedInput = radioButton
      }

      const handleChange = () => {
        document.querySelectorAll('.datepicker-dropdown__label').forEach(label => {
          label.classList.remove('input--selected')
        })

        radioButton.closest('.datepicker-dropdown__label').classList.add('input--selected')
        if (radioButton.checked && radioButton.value === 'select-date' && this.inputElement && !this.inputElement.value) {
          this.submitButton.disabled = true
        } else if (radioButton.checked && radioButton.value === 'select-date' && this.inputElement && this.inputElement.value) {
          window.dispatchEvent(new Event('reset-select-date-selection-preset'))
          this.submitButton.disabled = false
        } else if (radioButton.checked && radioButton.value !== 'select-date') {
          window.dispatchEvent(new Event('clear-select-date-selection'))
          this.submitButton.disabled = false
        }
      }

      radioButton.addEventListener('change', handleChange)
      radioButton.addEventListener('touchstart', handleChange)
    })

    this.dropdownLabel.forEach(label => {
      label.addEventListener('touchstart', e => {
        e.preventDefault()
        label.querySelector('.datepicker-dropdown__input').click()
      })
    })

    if (this.dropdownButton && this.dropdownPanel) {
      this.dropdownButton.addEventListener('click', this.handleDropdownButton.bind(this))
    }

    if (this.datePicker) {
      this.datePicker.addEventListener('click', this.handleCalendar.bind(this))
      this.datePicker.addEventListener('keydown', this.handleKeydownCalendar.bind(this))
    }

    this.dropdownLabel.forEach(label => {
      label.addEventListener('keydown', this.handleLabelKeydown.bind(this))
    })

    document.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        this.closeDropdown()
      }
    })

    this.initAccessibility()
    this.initCalendar()

    // NOTE: forcefully close dropdown as now the whole element gets replaced by the new one but can't remove main-document open-class causing to click twice - WWA
    this.toggleDropdown(true)
  }

  initAccessibility() {
    this.setAriaHiddenOnOtherElements(false)
    this.hideOverlayTabbableElements(true)

    this.overlayFocusTrap = createFocusTrap(this.dropdownPanel, {
      onActivate: () => {
        this.dropdownPanel.focus()
        this.dropdownPanel.blur()
      },
      onDeactivate: () => {
        this.dropdownPanel.focus()
      },
      escapeDeactivates: true,
      clickOutsideDeactivates: true,
      returnFocusOnDeactivate: true,
      initialFocus: this.dropdownPanel,
    })
  }

  initCalendar() {
    this.datePicker.dataset.locale === 'ar' ? moment.locale('ar') : moment.locale('en-gb')

    // NOTE: disable for now as this causes issues - WWA
    // window.requestAnimationFrame(() => this.element.querySelector('.datepicker').dispatchEvent(new CustomEvent('activate')))
  }

  onClickHandler(e) {
    e.preventDefault()
    this.calendar.ariaHidden = 'false'
    window.requestAnimationFrame(() => this.element.querySelector('.datepicker').dispatchEvent(new CustomEvent('activate')))
  }

  setAriaHiddenOnOtherElements(hidden = true) {
    const nonModalDialogVisibleElements = [...document.querySelectorAll('.container--content')]
    const modalDialogVisibleElements = [this.dropdownPanel]

    nonModalDialogVisibleElements.forEach(element => element.setAttribute('aria-hidden', hidden))
    modalDialogVisibleElements.forEach(element => element.setAttribute('aria-hidden', !hidden))
  }

  hideOverlayTabbableElements(hidden = true) {
    const focusableElements = [...this.dropdownPanel.querySelectorAll('a[href], button, input, select, textarea')]
    focusableElements.forEach(element => (hidden ? element.setAttribute('tabindex', -1) : element.removeAttribute('tabindex')))
  }

  closeDropdown() {
    document.documentElement.classList.remove('datepicker-dropdown__panel--open')

    this.dropdownPanel.classList.remove('datepicker-dropdown__panel--open')
    this.dropdownButton.classList.remove('datepicker-dropdown__button--active')
    this.dropdownOverlay.classList.remove('datepicker-dropdown__overlay--active')

    if (breakpoints.isLandscape()) {
      document.documentElement.classList.remove('prevent-scrolling')
    }
    this.overlayFocusTrap.deactivate()
    this.setAriaHiddenOnOtherElements(false)
    this.hideOverlayTabbableElements(true)
  }

  toggleDropdown(forceClose = false) {
    const closing = forceClose || document.documentElement.classList.contains('datepicker-dropdown__panel--open')

    document.documentElement.classList.toggle('datepicker-dropdown__panel--open', !closing)
    if (breakpoints.isLandscape()) {
      document.documentElement.classList.toggle('prevent-scrolling', !closing)
    }

    if (!closing) {
      this.setAriaHiddenOnOtherElements(true)
      this.hideOverlayTabbableElements(false)
      this.overlayFocusTrap.activate()
    } else {
      this.overlayFocusTrap.deactivate()
    }

    this.dropdownPanel.classList.toggle('datepicker-dropdown__panel--open', !closing)
    this.dropdownButton.classList.toggle('datepicker-dropdown__button--active', !closing)
    this.dropdownOverlay.classList.toggle('datepicker-dropdown__overlay--active', !closing)

    if (closing) {
      this.setAriaHiddenOnOtherElements(false)
      this.hideOverlayTabbableElements(true)
    }
  }

  handleDropdownButton(e) {
    e.preventDefault()

    if (this.dropdownPanel.classList.contains('datepicker-dropdown__panel--open')) {
      this.toggleDropdown(true)
    } else {
      this.toggleDropdown()
    }

    if (breakpoints.isLandscape()) {
      setTimeout(() => {
        this.element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }, 100)
    }

    this.onClickHandler(e)

    if (this.initialSelectedInput) {
      this.radioButtons.forEach(radioButton => {
        radioButton.closest('.datepicker-dropdown__label').classList.remove('input--selected')

        if (radioButton === this.initialSelectedInput && radioButton.value === 'select-date' && this.inputElement && this.inputElement.value) {
          window.dispatchEvent(new Event('reset-select-date-selection-preset'))
        } else if (radioButton === this.initialSelectedInput && radioButton.value !== 'select-date') {
          window.dispatchEvent(new Event('clear-select-date-selection'))
          this.submitButton.disabled = false
        }
      })
      this.initialSelectedInput.closest('.datepicker-dropdown__label').classList.add('input--selected')
    }
  }

  handleSubmitButton(e) {
    const newData = []
    let removeInputElementName = false
    let setInputElementName = false

    this.selectedItems = newData

    this.radioButtons.forEach(radioButton => {
      if (radioButton.checked === true) {
        radioButton.checked = true
        radioButton.setAttribute('checked', 'checked')
        newData.push(radioButton.value)

        if (radioButton.value === 'select-date') {
          removeInputElementName = false
          setInputElementName = true
        } else {
          removeInputElementName = true
          setInputElementName = false
        }
      } else {
        radioButton.checked = false
        radioButton.removeAttribute('checked')
      }

      if (removeInputElementName && this.inputElement) {
        this.inputElement?.removeAttribute('name')
      }

      if (setInputElementName && this.inputElement && this.inputElement.dataset?.name) {
        this.inputElement?.setAttribute('name', this.inputElement.dataset?.name)
      }
    })

    newData.length > 0 ? this.dropdownButton.classList.add('highlight--button') : this.dropdownButton.classList.remove('highlight--button')
    // console.log(newData)
    this.closeDropdown()
  }

  handleKeydownCalendar(e) {
    if (e.key === 'Enter' || e.key === ' ') {
      this.handleCalendar(e)
    }
  }

  handleCalendar(e) {
    if (e.target.getAttribute('role') === 'button') {
      this.radioButtons.forEach(radioButton => {
        const label = radioButton.closest('.datepicker-dropdown__label')
        if (radioButton.value === 'select-date') {
          radioButton.checked = true
          label.classList.add('input--selected')
        } else {
          radioButton.checked = false
          label.classList.remove('input--selected')
        }
      })
    }
  }

  handleClearButton(e) {
    e.preventDefault()

    this.closeDropdown()

    if (this.initialSelectedInput) {
      this.radioButtons.forEach(radioButton => {
        radioButton.closest('.datepicker-dropdown__label').classList.remove('input--selected')

        if (radioButton === this.initialSelectedInput && radioButton.value === 'select-date' && this.inputElement && this.inputElement.value) {
          window.dispatchEvent(new Event('reset-select-date-selection-preset'))
        } else if (radioButton === this.initialSelectedInput && radioButton.value !== 'select-date') {
          window.dispatchEvent(new Event('clear-select-date-selection'))
          this.submitButton.disabled = false
        }
      })
      this.initialSelectedInput.closest('.datepicker-dropdown__label').classList.add('input--selected')
    }
  }

  handleOverlay() {
    if (this.dropdownPanel.classList.contains('datepicker-dropdown__panel--open')) {
      this.toggleDropdown(true)
    } else {
      this.toggleDropdown()
    }
  }

  handleLabelKeydown(e) {
    if (e.key === 'Enter') {
      e.preventDefault()
      if (this.dropdownPanel.classList.contains('datepicker-dropdown__panel--open')) {
        this.closeDropdown()
      }
    }
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.datepicker-dropdown').forEach(element => {
    element.instance = element.instance || new DatePickerDropdownComponent(element)
  })
)
