import Component from '../../../assets/scripts/modules/component'

export default class OverviewHeader extends Component {
  init() {
    window.addEventListener('gsap-loaded', () => this.initHeaderTween())
  }

  initHeaderTween() {
    if (!document.querySelector('body').classList.contains('subsite--nmoq')) {
      return
    }

    let animation = null
    const reduceMotion = window.matchMedia('(prefers-reduced-motion: reduce)')

    if (reduceMotion.matches) {
      animation = window.GSAP.to(this.element.querySelector('.picture'), {
        clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 27% 85%, 0 80%)',
        duration: 0,
      })
    } else {
      animation = window.GSAP.to(this.element.querySelector('.picture'), {
        clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 27% 85%, 0 80%)',
      })
    }

    window.ScrollTrigger.create({ trigger: this.element, start: `top+=20% 50%`, end: `bottom 100%`, scrub: true, animation })
  }
}

window.addEventListener('init-immediate', () =>
  document.querySelectorAll('.overview-header').forEach(element => {
    element.instance = element.instance || new OverviewHeader(element)
  })
)
