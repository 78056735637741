import Component from '../../../assets/scripts/modules/component'

class TranscriptionBlockComponent extends Component {
  init() {
    this.initDisclosure()
  }

  initDisclosure() {
    this.disclosureButton = this.element.querySelector('.transcription-block__disclosure-button')
    this.disclosureRegion = this.element.querySelector('.transcription-block__disclosure-content')
    this.contentButtonReadMore = this.element.querySelector('.button--read-more')
    this.contentButtonReadLess = this.element.querySelector('.button--read-less')
    this.shortText = this.element.querySelector('.transcription-block__short-text')

    if (!this.disclosureButton || !this.disclosureRegion || !this.contentButtonReadMore || !this.contentButtonReadLess || !this.shortText) {
      return
    }

    this.disclosureButton.addEventListener('click', () => this.toggleRegion())
    this.setTabIndexes(this.disclosureRegion, true)
  }

  toggleRegion() {
    const isCollapsing = this.disclosureButton.getAttribute('aria-expanded') === 'true'

    this.disclosureRegion.setAttribute('aria-hidden', isCollapsing)
    this.disclosureButton.setAttribute('aria-expanded', !isCollapsing)
    this.contentButtonReadLess.setAttribute('aria-hidden', isCollapsing)
    this.contentButtonReadMore.setAttribute('aria-hidden', !isCollapsing)
    this.shortText.setAttribute('aria-hidden', !isCollapsing)

    this.setTabIndexes(this.disclosureRegion, isCollapsing)
  }

  setTabIndexes(region, isCollapsed) {
    const focusableElements = [...region.querySelectorAll('a[href], button')]
    focusableElements.forEach(element => (isCollapsed ? element.setAttribute('tabindex', -1) : element.removeAttribute('tabindex')))
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.transcription-block').forEach(element => {
    element.instance = element.instance || new TranscriptionBlockComponent(element)
  })
)
