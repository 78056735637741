import breakpoints from '../../../assets/scripts/modules/breakpoints'
import Component from '../../../assets/scripts/modules/component'

class ExhibitionHeaderComponent extends Component {
  init() {
    this.reduceMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches

    this.initDisclosure()
    this.initDynamicBackButton()

    if (document.body.classList.contains('subsite--m7')) {
      this.initM7Animation()
    }
    this.overlayButton = this.element.querySelector('.exhibition-header__figure-overlay-button')
    if (this.overlayButton) {
      console.log('overlay button found')
      this.overlayId = this.overlayButton.getAttribute('data-overlay-id') || ''
      this.overlayButton.addEventListener('click', event => this.onClickOverlayButtonHandler(event))
    }
  }

  onClickOverlayButtonHandler(event) {
    event?.preventDefault()
    console.log('overlay button clicked')
    console.log(document.getElementById(this.overlayId))
    document.getElementById(this.overlayId)?.dispatchEvent(new CustomEvent('toggle-figure-overlay'))
  }

  initDisclosure() {
    this.disclosureButton = this.element.querySelector('.exhibition-header__disclosure-button')
    this.disclosureRegion = this.element.querySelector('.exhibition-header__disclosure-content-container')

    if (!this.disclosureButton || !this.disclosureRegion) {
      return
    }

    this.disclosureButton.addEventListener('click', () => this.toggleRegion(this.disclosureRegion, this.disclosureButton))
    this.toggleRegion(this.disclosureRegion, this.disclosureButton, true)
  }

  initDynamicBackButton() {
    this.buttonBack = this.element.querySelector('button.button--back')
    this.buttonBack?.addEventListener('click', () => window.history.back())
  }

  toggleRegion(region, button, forceClose) {
    if (!region || !button) {
      return
    }

    const isCollapsing = forceClose || button.getAttribute('aria-expanded') === 'true'

    region.setAttribute('aria-hidden', isCollapsing)
    button.setAttribute('aria-expanded', !isCollapsing)
    this.element.classList.toggle('exhibition-header--disclosure-expanded', !isCollapsing)
    this.setTabIndexes(region, isCollapsing)

    if (isCollapsing) {
      return
    }

    const animation = window.GSAP.timeline()
    let duration = 1

    this.reduceMotion = window.matchMedia('(prefers-reduced-motion: reduce)')

    if (this.reduceMotion.matches) {
      duration = 0
    }

    animation.to(document.querySelector('html'), duration, {
      scrollTop: () => {
        const element = this.element.querySelector('.exhibition-header__disclosure-button')
        return window.pageYOffset + element.getBoundingClientRect().top
      },
    })
  }

  setTabIndexes(region, isCollapsed) {
    const focusableElements = [...region.querySelectorAll('a[href], button, input, select, textarea')]
    focusableElements.forEach(element => (isCollapsed ? element.setAttribute('tabindex', -1) : element.removeAttribute('tabindex')))
  }

  initM7Animation() {
    this.metadataWrapper = this.element.querySelector('.exhibition-header__metadata-wrapper')
    this.animated = false

    if (!this.metadataWrapper || this.reduceMotion) return

    const animationHandler = () => {
      if (!breakpoints.isLandscape()) return
      this.metadataWrapper.classList.toggle('exhibition-header__metadata-wrapper-animated', true)
      this.animated = true
      document.removeEventListener('resize', animationHandler)
    }

    document.addEventListener('resize', animationHandler)
    animationHandler()
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.exhibition-header').forEach(element => {
    element.instance = element.instance || new ExhibitionHeaderComponent(element)
  })
)
