import Component from '../../../assets/scripts/modules/component'

const SLIDESHOW_INTERVAL = 2000
const HIDE_DELAY = 750

export default class CompositionCardComponent extends Component {
  init() {
    this.video = this.element.querySelector('.video__video')
    this.slideshow = this.element.querySelector('.composition-card__slideshow')
    this.reduceMotion = window.matchMedia('(prefers-reduced-motion: reduce)')

    this.initVideo()
    this.initSlideshow()
  }

  initVideo() {
    if (!this.video) {
      return
    }
    if (!this.reduceMotion.matches) {
      window.addEventListener('touchmove', () => this.initVideoScroll())
      this.element.addEventListener('mouseover', () => {
        this.video.play()
      })

      this.element.addEventListener('mouseleave', () => {
        this.video.pause()
        this.video.currentTime = 0
      })
    }
  }

  initVideoScroll() {
    const rect = this.video.getBoundingClientRect()
    const isInViewport = rect.top <= window.innerHeight && rect.top + this.video.offsetHeight >= 0

    if (isInViewport) {
      this.video.play()
    } else {
      this.video.pause()
      this.video.currentTime = 0
    }
  }

  initSlideshow() {
    if (!this.slideshow) {
      return
    }

    this.timeout = null
    this.timeout2 = null
    this.isPlaying = false
    this.pictures = [...this.element.querySelectorAll('.picture')]
    this.currentIndex = -1

    if (!this.pictures.length) {
      return
    }

    this.nextSlide(true)

    this.element.closest('.composition-card').addEventListener('mouseenter', () => {
      this.isPlaying = true
    })
    this.element.closest('.composition-card').addEventListener('mouseout', () => {
      this.isPlaying = false
    })
  }

  nextSlide(force = false) {
    if (this.isPlaying || force) {
      const oldIndex = this.currentIndex

      this.currentIndex = this.currentIndex === this.pictures.length - 1 ? 0 : this.currentIndex + 1
      this.pictures[this.currentIndex]?.classList.add('picture--active')

      window.clearTimeout(this.timeout2)
      this.timeout2 = window.setTimeout(() => {
        this.pictures[oldIndex]?.classList.remove('picture--active')
      }, HIDE_DELAY)
    }

    window.clearTimeout(this.timeout)
    this.timeout = window.setTimeout(() => this.nextSlide(), SLIDESHOW_INTERVAL)
  }
}

window.addEventListener('init-immediate', () =>
  document.querySelectorAll('.composition-card').forEach(element => {
    element.instance = element.instance || new CompositionCardComponent(element)
  })
)
