import Component from '../../../assets/scripts/modules/component'

const LOCALIZATIONS = {
  en: {
    buttonLabel: 'Choose date',
    placeholder: ' by date',
    selectedDateMessage: 'Selected date is',
    prevMonthLabel: 'Previous month',
    nextMonthLabel: 'Next month',
    monthSelectLabel: 'Month',
    yearSelectLabel: 'Year',
    closeLabel: 'Close date window',
    calendarHeading: 'Choose a date',
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    locale: 'en',
  },

  ar: {
    buttonLabel: 'اختر التاريخ',
    placeholder: ' حسب التاريخ',
    selectedDateMessage: 'التاريخ المحدد هو',
    prevMonthLabel: 'الشهر السابق',
    nextMonthLabel: 'الشهر التالي',
    monthSelectLabel: 'الشهر',
    yearSelectLabel: 'السنة',
    closeLabel: 'أغلق نافذة التاريخ',
    calendarHeading: 'اختر تاريخًا',
    dayNames: ['الأحد', 'الاثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت'],
    monthNames: ['يناير', 'فبراير', 'مارس', 'أبريل', 'مايو', 'يونيو', 'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'],
    monthNamesShort: ['ينا', 'فبر', 'مار', 'أبر', 'ماي', 'يون', 'يول', 'أغس', 'سبت', 'أكت', 'نوف', 'ديس'],
    locale: 'ar',
  },
}

export default class DuetDatepickerComponent extends Component {
  async init() {
    this.locale = document.documentElement.lang || 'en'
    this.isOpen = false

    this.updateSearchParams = !!(this.element.getAttribute('data-update-search-params') === 'true')
    this.buttonOpenID = this.element.getAttribute('data-button-open-id') || ''
    this.buttonCloseID = this.element.getAttribute('data-button-close-id') || ''
    this.buttonOpenTitle = this.element.getAttribute('data-button-open-title') || ''
    this.buttonCloseTitle = this.element.getAttribute('data-button-close-title') || ''

    this.buttonOpen = this.buttonOpenID ? document.getElementById(this.buttonOpenID) : this.element.querySelector('.mah-button--open-datepicker')
    this.buttonClose = this.buttonCloseID ? document.getElementById(this.buttonCloseID) : this.element.querySelector('.mah-button--close-datepicker')
    this.buttonOpenSpan = this.buttonOpen?.querySelector('.mah-navigation-button__span')
    this.buttonCloseSpan = this.buttonClose?.querySelector('.mah-navigation-button__span')
    this.form = this.element.closest('form')
    this.picker = this.element.querySelector('duet-date-picker')

    if (!this.buttonOpen || !this.buttonClose || !this.picker) {
      return
    }

    this.inputName = this.picker.getAttribute('identifier')

    await this.loadPicker()
    this.setInitialDate()
  }

  async loadPicker() {
    if (!this.picker) {
      return
    }

    const { DuetDatePicker } = (await import('../../../assets/scripts/plugins/duet-date-picker')).default()

    // Load the datepicker
    try {
      customElements.define('duet-date-picker', DuetDatePicker)
      await window.customElements.whenDefined('duet-date-picker')
    } catch (e) {
      //
    }

    // Set the language
    if (LOCALIZATIONS[this.locale]) {
      this.picker.localization = LOCALIZATIONS[this.locale]
    }

    this.picker.addEventListener('duetChange', event => this.onChangeHandler(event))

    this.element.picker = this.picker
  }

  setDate(value = '') {
    this.picker.value = value
    this.applyDate()
  }

  setInitialDate() {
    const params = new URLSearchParams(window.location.search)
    const value = params.get(this.inputName) || ''

    this.setDate(value)
  }

  applyDate(updateButtons = true) {
    if (!this.picker.value) {
      this.buttonCloseSpan.innerText = this.buttonCloseTitle
      this.buttonOpenSpan.innerText = this.buttonOpenTitle

      this.buttonClose.setAttribute('hidden', 'hidden')
      this.buttonClose.setAttribute('aria-hidden', true)
      this.buttonClose.setAttribute('tabindex', '-1')
    } else {
      const date = this.picker.valueAsDate || new Date(this.picker.value)
      const title = date.toLocaleDateString(this.locale, { dateStyle: 'medium' })

      this.buttonCloseSpan.innerText = title

      this.buttonClose.removeAttribute('hidden')
      this.buttonClose.setAttribute('aria-hidden', 'false')
      this.buttonClose.removeAttribute('tabindex')
    }
  }

  onOpenHandler(event) {
    if (!event) {
      event.preventDefault()
    }

    if (!this.picker) {
      return
    }

    if (this.isOpen) {
      this.picker.hide()
      this.isOpen = false
    } else {
      this.picker.show()
      this.isOpen = true
    }
  }

  onChangeHandler(event) {
    if (event) {
      event.preventDefault()
    }

    if (this.updateSearchParams) {
      const params = new URLSearchParams(window.location.search)
      params.set(this.inputName, this.picker.value)
      window.location.search = params.toString()
    } else {
      this.applyDate()
    }
  }
}

window.addEventListener('init-load', () =>
  [...document.querySelectorAll('.mah-datepicker')].forEach(element => {
    element.instance = element.instance || new DuetDatepickerComponent(element)
  })
)
