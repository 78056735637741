/* eslint promise/prefer-await-to-then: 0 */

import Component from '../../../assets/scripts/modules/component'
// import initGooglePay from '../../../assets/scripts/modules/google_pay' // Disabled Google Pay -MKR
import LoaderComponent from '../../molecules/loader/loader'

export default class TicketSummaryComponent extends Component {
  async init() {
    this.observer = null
    this.button = this.element.querySelector('.tickets-summary__button')
    this.box = this.element.querySelector('.tickets-summary__box')
    this.Basket = this.element.querySelector('.tickets-summary__basket')
    this.orderTotalAmount = this.element.querySelectorAll('.tickets-summary__total-amount')
    this.viewOrderButton = this.element.querySelector('.tickets-summary__view-order')
    this.xLabel = this.element.dataset.xLabel
    this.totalLabel = this.element.dataset.totalLabel
    this.currencyLabel = this.element.dataset.currencyLabel
    this.membership = this.element.dataset.membership
    this.buttonOnly = this.element.dataset.buttonOnly
    this.scrollTimeout = null

    // initGooglePay() // Disabled Google Pay -MKR

    const updateBasketHandler = event => {
      if (event.detail?.detail) {
        console.warn('updateBasketHandler: detail object is still nested, fixme!')
        event.detail = event.detail.detail
      }

      let element = document.querySelector('.tickets-table')
      if (!element) {
        element = this.element
      }

      if (element && element.id === 'tickets-summary' && event?.detail) {
        element.dataset.ticketDateTime = event.detail?.SelectedPeriodISO !== '' ? event.detail.SelectedPeriodISO : null
      }

      const title = element.dataset.showExhibitionTitle === 'true' ? event?.detail?.Exhibition?.Name : event?.detail?.Museum?.Name

      let basketHTMLcontent = title ? `<h5>${title}</h5>` : ''
      basketHTMLcontent += '<p>'

      if (Array.isArray(event.detail.Basket?.Items) && event.detail.Basket?.Items.length > 0) {
        event.detail.Basket.Items.forEach(item => {
          basketHTMLcontent += `${item.Quantity} ${this.xLabel} ${item.Name}</br>`
        })
      }

      if (event.detail.SelectedPeriod && !event.detail.SkipPickADate && !event.detail.IsGeneralAdmission) {
        basketHTMLcontent += `</br>${event.detail.SelectedPeriod}</br>`
      }

      basketHTMLcontent += '</p>'
      this.Basket.innerHTML = basketHTMLcontent

      if (event.detail.Basket?.TotalPrice) {
        this.orderTotalAmount.forEach(element => {
          element.innerHTML = `${this.totalLabel} ${this.currencyLabel} ${event.detail.Basket.TotalPrice}`
        })
      } else {
        this.orderTotalAmount.forEach(element => {
          element.innerHTML = ``
        })
      }
    }

    const toggleMobileSummary = e => {
      e.preventDefault()

      if (this.box.classList.contains('tickets-summary__box--expanded')) {
        this.box.classList.remove('tickets-summary__box--expanded')
      } else {
        this.box.classList.add('tickets-summary__box--expanded')
      }
    }

    const addLoader = () => {
      if (!this.loader) {
        this.loader = new LoaderComponent(this.element)
      }
    }

    const removeLoader = () => {
      if (this.loader) {
        this.loader.destroy()
        this.loader = null
      }
    }

    const refresh = async () => {
      addLoader()

      try {
        const response = await window.fetch(`${window.baseApiUrl}/basket/`, { credentials: 'include' })
        const responseData = await response.json()

        if (responseData.Basket) {
          const updateEvent = new CustomEvent('updateBasket', { detail: responseData })
          this.element.dispatchEvent(new CustomEvent('updateBasket', updateEvent))
        }

        removeLoader()

        return true
      } catch (error) {
        removeLoader()
        console.log(error)
      }
    }

    this.viewOrderButton.addEventListener('click', toggleMobileSummary)

    if (!this.membership && !this.buttonOnly) {
      addLoader()

      this.element.addEventListener('updateBasket', updateBasketHandler)
      this.element.addEventListener('refresh', refresh)
      this.element.addEventListener('addLoader', addLoader)
      this.element.addEventListener('removeLoader', removeLoader)

      setTimeout(() => {
        refresh()
      }, 200)
    }

    this.scrollToElement = () => {
      this.scrollTimeout = window.clearTimeout(this.scrollTimeout)
      this.scrollTimeout = window.setTimeout(() => {
        const elements = [...document.querySelectorAll('.container--content .notice--error, td.tickets-table__type')]

        if (!elements.length) {
          return
        }

        const element = elements[0]
        const rect = element.getBoundingClientRect()
        const scrollPositionTarget = rect.top - rect.height
        const offset = -30
        window.scroll({ top: window.pageYOffset + scrollPositionTarget - offset, left: 0, behavior: 'smooth' })
        element?.focus()
      }, 500)
    }

    this.scrollToElement()
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.tickets-summary--app').forEach(element => {
    element.instance = element.instance || new TicketSummaryComponent(element)
  })
)
