import Component from '../../../assets/scripts/modules/component'

export default class TicketsReservedComponent extends Component {
  init() {
    this.time = this.element.querySelector('.tickets-reserved__time')
    this.timeup = this.element.querySelector('.tickets-reserved__timeup')
    this.ticker = this.element.querySelector('.tickets-reserved__ticker')
    this.infoText = this.element.querySelector('.tickets-reserved__info-text')

    this.dataTime = this.element.dataset.time
    this.minutesLabel = this.element.dataset.minutesLabel
    this.secondsLabel = this.element.dataset.secondsLabel

    this.start()
  }

  async start() {
    const { moment } = (await import('../../../assets/scripts/plugins/react-dates-and-moment')).default()

    if (this.element.dataset.locale === 'ar') {
      moment.locale('ar')
    }

    this.nowPlusMinutes = moment().add(Number(this.dataTime || 0), 'm')

    const timeLoop = () => {
      const duration = moment.duration(this.nowPlusMinutes.diff(moment()))
      const timeLeftSeconds = Math.round(Number(duration.asSeconds()))
      const timeLeftMinutes = Math.ceil(Number(duration.asMinutes()))

      if (timeLeftSeconds > 1) {
        this.time.innerHTML = ` ${timeLeftSeconds > 60 ? `${timeLeftMinutes} ${this.minutesLabel}` : `${timeLeftSeconds} ${this.secondsLabel}`}`

        setTimeout(() => timeLoop(), 1000)
      } else {
        this.timeup.classList.add('tickets-reserved__timeup--visible')
        this.ticker.classList.add('tickets-reserved__ticker--hide')
        this.infoText.classList.add('tickets-reserved__ticker--hide')
      }
    }

    timeLoop()
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.tickets-reserved').forEach(element => {
    element.instance = element.instance || new TicketsReservedComponent(element)
  })
)
