// There are 3 ways to use this loader/spinner:
// 1. Render straight from backend with nunjucks template
// 2. Render dynamically from regular javascript component:
//    for example:  this.loader = new LoaderComponent(this.element) (shows loader imediately)
//                  this.loader.destroy() (removes loader component)
// 3. Render dynamically from React component:
//    for example:  import { loaderHTML } from '../../molecules/loader/loader'
//                  <div dangerouslySetInnerHTML={{ __html: loaderHTML }} />
// -PVI

import Component from '../../../assets/scripts/modules/component'

export const loaderHTMLInner = `<div class="loader" aria-hidden="true"><div class="loader__sq"><div class="loader__complex"></div><div class="loader__complex"></div><div class="loader__complex"></div><div class="loader__complex"></div></div></div>`
export const loaderHTML = `<div class="loader__wrap" role="alertdialog" aria-busy="true" aria-live="polite">${loaderHTMLInner}</div>`

export default class LoaderComponent extends Component {
  constructor(element, isOverlay = false) {
    super(element)

    if (isOverlay) {
      this.overlayContainerSupport()
    }
  }

  init() {
    this.loader = document.createElement('div')
    this.loader.className = 'loader__wrap'
    this.loader.setAttribute('role', 'alertdialog')
    this.loader.setAttribute('aria-busy', 'true')
    this.loader.setAttribute('aria-live', 'polite')
    this.loader.setAttribute('aria-label', 'Loading…')
    this.loader.innerHTML = loaderHTMLInner
    this.element.appendChild(this.loader)
  }

  destroy() {
    this.element.removeChild(this.loader)
  }

  overlayContainerSupport() {
    if (!this.loader) return

    this.loader.style.position = 'fixed'
    this.loader.style.inset = 0
    this.loader.style.backgroundColor = 'rgba(255, 255, 255, 0.8)'
    this.loader.style.zIndex = 1000
  }
}
