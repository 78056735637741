import Component from '../../../assets/scripts/modules/component'

class SelectedFiltersBarNewComponent extends Component {
  init() {}
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.selected-filters-bar-new').forEach((element, index) => {
    element.instance = element.instance || new SelectedFiltersBarNewComponent(element, index)
  })
)
