import Component from '../../../assets/scripts/modules/component'

export default class TicketsControllerComponent extends Component {
  init() {
    const baseApiUrl = this.element.getAttribute('data-base-api-url') || ''
    window.baseApiUrl = baseApiUrl ?? window.baseApiUrl
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.tickets-controller').forEach(element => {
    element.instance = element.instance || new TicketsControllerComponent(element)
  })
)
