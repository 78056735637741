class Pearl {
  constructor(p5Instance, x, y, size, pearlSprite) {
    this.x = x
    this.y = y
    this.size = size
    this.sprite = pearlSprite // Sprite della perla
    this.p5Instance = p5Instance
  }

  display() {
    if (this.sprite && this.x && this.y && this.size) {
      this.p5Instance.image(this.sprite, this.x, this.y, this.size, this.size)
    }
  }
}

export default Pearl
