import Component from '../../../assets/scripts/modules/component'

export default class CallCardComponent extends Component {
  init() {
    this.video = this.element.querySelector('.video__video')
    this.reduceMotion = window.matchMedia('(prefers-reduced-motion: reduce)')

    this.initVideo()
  }

  initVideo() {
    if (!this.video || this.reduceMotion.matches) {
      return
    }

    this.element.addEventListener('mouseover', () => this.video.play())
    this.element.addEventListener('mouseleave', () => this.video.pause())
    this.element.addEventListener('focus', () => this.video.play())
    this.element.addEventListener('blur', () => this.video.pause())
  }
}

window.addEventListener('init-immediate', () =>
  document.querySelectorAll('.call-card').forEach(element => {
    element.instance = element.instance || new CallCardComponent(element)
  })
)
