import { createFocusTrap } from 'focus-trap'

import Component from '../../../assets/scripts/modules/component'

export default class MenuOverlayComponent extends Component {
  init() {
    this.overlay = document.querySelector('.menu-overlay')
    this.buttonClose = this.overlay.querySelector('.button--close')
    this.firstMenuItem = this.overlay.querySelector('.menu-overlay__link') || this.overlay.querySelector('.link')

    this.initAccessibility()
    this.initOverlay()
    this.hideNonNativeFromScreenreaders()

    if (document.body.classList.contains('subsite--tasweer')) {
      this.initTasweerTween()
    }

    this.venueButtons = [...this.element.querySelectorAll('.button--venues')]
    this.venueButtons.forEach(button =>
      button.addEventListener('click', event => {
        event.preventDefault()
        window.dispatchEvent(new CustomEvent('open-network-map'))
        this.buttonClose.click()
      })
    )
  }

  initOverlay() {
    this.buttonClose.addEventListener('click', () => window.requestAnimationFrame(() => this.toggleOverlay()))
    window.addEventListener('open-menu-overlay', () => window.requestAnimationFrame(() => this.toggleOverlay()))
  }

  toggleOverlay(forceClose = false) {
    const closing = forceClose || document.documentElement.classList.contains('overlay-visible')

    if (document.documentElement.classList.contains('overlay-closing') || document.documentElement.classList.contains('overlay-opening')) {
      return
    }

    document.documentElement.classList.toggle('overlay-visible', !closing)
    document.documentElement.classList.toggle('prevent-scrolling', !closing)

    if (!closing) {
      this.setAriaHiddenOnOtherElements(true)
      this.hideOverlayTabbableElements(false)
      this.overlayFocusTrap.activate()
    } else {
      this.overlayFocusTrap.deactivate()
    }

    if (closing) {
      document.documentElement.classList.add('overlay-closing')
      window.setTimeout(() => {
        document.documentElement.classList.remove('overlay-closing')
        this.setAriaHiddenOnOtherElements(false)
        this.hideOverlayTabbableElements(true)
      }, 350)
    } else {
      document.documentElement.classList.add('overlay-opening')
      window.setTimeout(() => {
        document.documentElement.classList.remove('overlay-opening')
      })
    }
  }

  initAccessibility() {
    this.setAriaHiddenOnOtherElements(false)
    this.hideOverlayTabbableElements(true)

    this.overlayFocusTrap = createFocusTrap(this.overlay, {
      onActivate: () => {
        this.firstMenuItem.focus()
        this.firstMenuItem.blur()
      },
      onDeactivate: () => {
        this.toggleOverlay(true)
        this.buttonClose.focus()
      },
      escapeDeactivates: true,
      clickOutsideDeactivates: true,
      returnFocusOnDeactivate: true,
      initialFocus: this.firstMenuItem,
    })
  }

  setAriaHiddenOnOtherElements(hidden = true) {
    const nonModalDialogVisibleElements = [...document.querySelectorAll('.container--content')]
    const modalDialogVisibleElements = [this.element]

    nonModalDialogVisibleElements.forEach(element => element.setAttribute('aria-hidden', hidden))
    modalDialogVisibleElements.forEach(element => element.setAttribute('aria-hidden', !hidden))
  }

  hideOverlayTabbableElements(hidden = true) {
    const focusableElements = [...this.element.querySelectorAll('a[href], button, input, select, textarea')]
    focusableElements.forEach(element => (hidden ? element.setAttribute('tabindex', -1) : element.removeAttribute('tabindex')))
  }

  hideNonNativeFromScreenreaders() {
    const documentLanguage = document.documentElement.getAttribute('lang')

    if (!documentLanguage) {
      return
    }

    const items = [...this.element.querySelectorAll(`.menu-overlay__link-text[lang]:not([lang='${documentLanguage}'])`)]
    items.forEach(item => item.setAttribute('aria-hidden', true))
  }

  initTasweerTween() {
    this.reduceMotion = window.matchMedia('(prefers-reduced-motion: reduce)')

    if (!this.reduceMotion.matches) {
      const links = this.element.querySelectorAll('.menu-overlay__link')

      if (!links || !links.length) return

      Array.from(links).forEach((link, index) => {
        link.addEventListener('mouseenter', () => {
          if (links[index - 1]) {
            links[index - 1].classList.add('sibling')
          }

          link.classList.add('active')

          if (links[index + 1]) {
            links[index + 1].classList.add('sibling')
          }
        })

        link.addEventListener('mouseleave', () => {
          if (links[index - 1]) {
            links[index - 1].classList.remove('sibling')
          }

          link.classList.remove('active')

          if (links[index + 1]) {
            links[index + 1].classList.remove('sibling')
          }
        })
      })
    }
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.menu-overlay').forEach(element => {
    element.instance = element.instance || new MenuOverlayComponent(element)
  })
)
