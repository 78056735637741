/* eslint promise/prefer-await-to-then: 0 */

import breakpoints from '../../../assets/scripts/modules/breakpoints'
import { loaderHTML } from '../../molecules/loader/loader'

window.addEventListener('init-load', async () => {
  const element = document.querySelector('.tickets-datetime-picker__app')

  if (!element) {
    return
  }

  const { React, ReactDOM } = (await import('../../../assets/scripts/plugins/react')).default()
  const { DayPickerSingleDateController, moment } = (await import('../../../assets/scripts/plugins/react-dates-and-moment')).default()

  class TicketsDateTimePicker extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
        focused: true,
        date: props.dataSet.initialDate ? moment(props.dataSet.initialDate) : null,
        time: props.dataSet.initialTime || null,
        error: false,
        isRTL: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl',
      }

      this.onDateChange = this.onDateChange.bind(this)
      this.onFocusChange = this.onFocusChange.bind(this)
      this.responsivityHandler = this.responsivityHandler.bind(this)
    }

    componentDidMount() {
      this.responsivityHandler()
      window.addEventListener('resize', this.responsivityHandler)

      this.fetchDates(moment())
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.responsivityHandler)
    }

    responsivityHandler() {
      if (window.innerWidth < breakpoints.LANDSCAPE) {
        this.setState({ responsiveState: 'small' })
      } else if (window.innerWidth < breakpoints.NOTEBOOK) {
        this.setState({ responsiveState: 'medium' })
      } else {
        this.setState({ responsiveState: 'large' })
      }
    }

    async fetchDates(fromDate) {
      try {
        const params = { museum: this.props.dataSet.museum, exhibition: this.props.dataSet.exhibition, from: fromDate.format('YYYY-MM-DDTHH:mm:ss[Z]') }
        const response = await window.fetch(`${window.baseApiUrl}/periods/?museum=${params.museum}&exhibition=${params.exhibition}&from=${params.from}`, { credentials: 'include' })
        const responseData = await response.json()

        if (!responseData && !responseData.Periods) {
          this.setState({ dates: [] })
          return false
        }

        const dates = []

        responseData.Periods.forEach(day => {
          if (day.length > 0) {
            dates.push({
              date: day[0].fromDate,
              periods: day.map(period => ({
                value: period.id,
                label: period.twelve_hour_time_slot,
                full: !period.occupancy.remaining,
                filling: false,
                status: period.occupancy.status,
                remaining: period.occupancy.remaining,
                current: period.occupancy.current,
                maximum: period.occupancy.maximum,
              })),
            })
          }
        })

        const selectedDate = this.state.date ? dates.find(date => this.state.date.isSame(date.date, 'date')) : null
        let time = this.state.time

        if (selectedDate && time && !!selectedDate.periods.find(period => period.id === time)) {
          // If both intialDate & initialTime are set and available, enable next button - no need to do a setPeriod call -PVI
        } else if (selectedDate && selectedDate.periods.length === 1) {
          // Immediately set time if there is only 1 option -PVI
          time = selectedDate.periods[0].value

          this.setPeriod(time)
        }

        this.setState({
          dates,
          date: selectedDate ? this.state.date : null,
          time,
        })

        this.scrollToTimeslots()

        return true
      } catch (error) {
        this.setState({ dates: [] })
        console.log(error)
      }
    }

    onDateChange(date) {
      const selectedDate = this.state.dates.find(d => date.isSame(d.date, 'date'))

      if (selectedDate && selectedDate.periods.length === 1) {
        this.setState({ date, error: false })
        // Immediately set time if there is only 1 option -PVI
        this.setPeriod(selectedDate.periods[0].value)
      } else {
        this.setState({ date, error: false }) // Set the date, so we can show times also -PVI
      }

      this.scrollToTimeslots()
    }

    onFocusChange() {
      // Force the focused states to always be truthy so that date is always selectable -PVI
      this.setState({ focused: true })
    }

    onMonthNav(newCurrentMonth) {
      this.fetchDates(newCurrentMonth.date(1))
    }

    onTimeChange(e) {
      this.setState({ time: e.target.value, error: false })
      this.setPeriod(e.target.value)
    }

    async setPeriod(period) {
      this.setState({ loading: true })

      try {
        const params = { PeriodId: period }
        const response = await window.fetch(`${window.baseApiUrl}/periods/set/`, { method: 'POST', credentials: 'include', body: JSON.stringify(params) })
        const responseData = await response.json() // eslint-disable-line no-unused-vars

        this.setState({ loading: false })
        this.updateSummary() // Update the card information on chosen date -PVI

        return true
      } catch (error) {
        this.setState({ time: null, error: true, loading: false })
        console.log(error)
      }
    }

    updateSummary() {
      const { ticketSummary } = this.props

      ticketSummary.dispatchEvent(new CustomEvent('refresh'))
    }

    scrollToTimeslots() {
      window.requestAnimationFrame(() => {
        const element = document.querySelector('.tickets-datetime-picker__time-item')

        if (!element) {
          return
        }

        const rect = element.getBoundingClientRect()
        const scrollPositionTarget = rect.top - rect.height
        const offset = -30
        window.scroll({ top: window.pageYOffset + scrollPositionTarget - offset, left: 0, behavior: 'smooth' })
        element?.focus()
      })
    }

    // this function was used to show the loader animation in the basket component. Left in for reference purposes -EKL
    // setSummaryLoading (summaryLoading) {
    //  const { ticketSummary, nextButton } = this.props
    //
    //  if (summaryLoading) {
    //    ticketSummary.dispatchEvent(new CustomEvent('addLoader'))
    //    nextButton.classList.add('button--disabled')
    //  } else {
    //    ticketSummary.dispatchEvent(new CustomEvent('removeLoader'))
    //    if (this.state.time) {
    //      nextButton.classList.remove('button--disabled')
    //    }
    //  }
    // }

    render() {
      const { focused, date, responsiveState, dates, time, error, isRTL, loading } = this.state
      const { dataSet } = this.props

      if (!dates) {
        return <div dangerouslySetInnerHTML={{ __html: loaderHTML }} />
      }

      const selectedDate = date && dates.find(day => date.isSame(day.date, 'date'))

      return (
        <>
          <DayPickerSingleDateController
            onDateChange={this.onDateChange}
            onFocusChange={this.onFocusChange}
            onPrevMonthClick={this.onMonthNav.bind(this)}
            onNextMonthClick={this.onMonthNav.bind(this)}
            numberOfMonths={!responsiveState || responsiveState === 'large' ? 2 : 1}
            focused={focused}
            date={date}
            noBorder={true}
            orientation="horizontal"
            daySize={responsiveState === 'small' ? 40 : 50}
            hideKeyboardShortcutsPanel={true}
            isOutsideRange={day => !dates.find(date => day.isSame(date.date, 'date'))}
            isRTL={isRTL}
            isDayBlocked={day => {
              const date = dates.find(date => day.isSame(date.date, 'date'))

              if (!date || !date.periods || !date.periods.length) {
                return false
              }

              const isEveryPeriodSoldOut = [...date.periods].every(period => period.remaining === 0)

              return isEveryPeriodSoldOut
            }}
            isDayHighlighted={day => {
              const date = dates.find(date => day.isSame(date.date, 'date'))
              const popularPercentage = parseInt(dataSet.popularPercentage, 10) || 0

              if (!date || !date.periods || !date.periods.length || !popularPercentage) {
                return false
              }

              const periods = date.periods
              const totalMaximum = periods.reduce((previous, current) => previous + current.maximum, 0)
              const totalCurrent = periods.reduce((previous, current) => previous + current.current, 0)
              return (totalCurrent / totalMaximum) * 100 >= popularPercentage
            }}
          />

          {selectedDate && selectedDate.periods.length > 1 && (
            <div className="tickets-datetime-picker__timepicker">
              <h2 className="tickets-datetime-picker__title">{dataSet.timeHeader}</h2>
              <ul className="tickets-datetime-picker__time-list" aria-label={dataSet.timeListAriaLabel}>
                {error && <p className="tickets-datetime-picker__error">{dataSet.errorMessage}</p>}
                {selectedDate.periods?.map(item => {
                  return (
                    <li key={item.value} className={item.status === 'soldout' ? 'tickets-datetime-picker__time-item tickets-datetime-picker__time-item--soldout' : item.status === 'popular' ? 'tickets-datetime-picker__time-item tickets-datetime-picker__time-item--popular' : 'tickets-datetime-picker__time-item'}>
                      <input type="radio" id={`ticket-time-${item.value}`} name="tickets-time" value={item.value} checked={time === item.value} onChange={this.onTimeChange.bind(this)} />
                      <label className="tickets-datetime-picker__time-item-label" htmlFor={`ticket-time-${item.value}`}>
                        {item.label}
                        {item.filling && <span>{dataSet.fillingUp}</span>}
                      </label>
                    </li>
                  )
                })}
              </ul>
              <p className="tickets-datetime-picker__footer">{dataSet.timeFooter}</p>
            </div>
          )}

          {selectedDate && selectedDate.periods.length === 1 && <input type="hidden" name="tickets-time" value={time} />}

          {loading && (
            <div className="tickets-datetime-picker__loader">
              <div dangerouslySetInnerHTML={{ __html: loaderHTML }} />
            </div>
          )}
        </>
      )
    }
  }

  document.querySelectorAll('.tickets-datetime-picker__app').forEach(element => {
    if (element.dataset.locale === 'ar') {
      moment.locale('ar')
    }

    const nextButton = document.querySelector('.tickets-summary__button')
    const ticketSummary = document.querySelector('.tickets-summary')

    ReactDOM.render(<TicketsDateTimePicker dataSet={element.dataset} nextButton={nextButton} ticketSummary={ticketSummary} />, element)
  })
})
