import Component from '../../../assets/scripts/modules/component'

export default class CardGridComponent extends Component {
  init() {
    this.prevWidth = window.innerWidth

    this.isRegularCardGrid = this.element.classList.contains('card-grid--squares')

    if (!this.isRegularCardGrid && document.body.classList.contains('subsite--nmoq')) {
      const MAX_VARIANTS = 3
      const randomNr = Math.floor(Math.random() * MAX_VARIANTS + 1)
      this.element.classList.add(`card-grid--variant-${randomNr}`)

      window.addEventListener('gsap-loaded', () => this.setupAnimation())
    }

    window.addEventListener('resize', () => {
      const isNotebook = window.innerWidth < 1480
      const crossedBreakpointL = window.innerWidth < 2260 && this.prevWidth >= 2260
      const crossedBreakpointXL = window.innerWidth >= 2260 && this.prevWidth < 2260

      if (isNotebook || (!crossedBreakpointXL && !crossedBreakpointL)) return

      this.shuffleOpenCall(crossedBreakpointL, crossedBreakpointXL)

      this.prevWidth = window.innerWidth
    })

    this.gridWrapper = this.element.querySelector('.card-grid__cards')

    if (this.gridWrapper) {
      this.openCallEl = this.gridWrapper.querySelector('.call-card__short-list')

      if (this.openCallEl && window.innerWidth >= 2260) {
        this.toggleCls()
        this.shuffleOpenCall(false, true)
      }
    }
  }

  shuffleOpenCall(crossedBreakpointL, crossedBreakpointXL) {
    if (!this.openCallEl || !this.gridWrapper) {
      return
    }

    const isXL = window.innerWidth >= 2260
    const node = this.gridWrapper.removeChild(this.openCallEl)

    if (isXL) {
      this.gridWrapper.children[1] ? this.gridWrapper.insertBefore(node, this.gridWrapper.children[1]) : this.gridWrapper.appendChild(node)
    } else {
      this.gridWrapper.children[5] ? this.gridWrapper.insertBefore(node, this.gridWrapper.children[5]) : this.gridWrapper.appendChild(node)
    }

    this.toggleCls()
  }

  toggleCls() {
    if (!this.openCallEl || !this.gridWrapper) {
      return
    }

    const isXL = window.innerWidth >= 2260
    this.openCallEl.classList.toggle('call-card__desktop-large', !isXL)
    this.openCallEl.classList.toggle('call-card__desktop-xl', isXL)
  }

  setupAnimation() {
    // Height values of card -CMT
    const endValues = [
      [90, 100, 80, 95, 85],
      [100, 80, 95, 77.5, 100],
      [85, 97.5, 80, 95, 77.5],
    ]

    // Pick random array -CMT
    const randomEnd = endValues[Math.floor(Math.random() * endValues.length)]

    // Apply ending to the animation values -CMT
    const animationVals = [
      {
        in: 'polygon(41% 8%, 96% 6%, 100% 100%, 10% 100%, 7% 9%)',
        out: `polygon(0% 0%, 100% 0%, 100% ${randomEnd[1]}%, 0% ${randomEnd[0]}%, 0% 24%)`,
      },

      {
        in: 'polygon(15% 8%, 90% 0%, 100% 79%, 14% 94%, 0% 76%)',
        out: `polygon(0% 0%, 100% 0%, 100% ${randomEnd[2]}%, 0% ${randomEnd[1]}%, 0% 24%)`,
      },

      {
        in: 'polygon(19% 0%, 100% 14%, 93% 100%, 9% 100%, 0% 24%)',
        out: `polygon(0% 0%, 100% 0%, 100% ${randomEnd[3]}%, 0% ${randomEnd[2]}%, 0% 24%)`,
      },

      {
        in: 'polygon(57% 8%, 96% 31%, 95% 100%, 5% 90%, 10% 19%)',
        out: `polygon(58% 0%, 100% 0%, 100% ${randomEnd[4]}%, 0% ${randomEnd[3]}%, 0% 0%)`,
      },

      {
        in: 'polygon(41% 8%, 96% 6%, 100% 100%, 10% 100%, 7% 9%)',
        out: `polygon(0% 0%, 100% 0%, 100% ${randomEnd[1]}%, 0% ${randomEnd[0]}%, 0% 24%)`,
      },
    ]

    const cards = this.element.querySelectorAll('.card__image')

    cards.forEach((card, index) => {
      const label = card.querySelector('.exhibition-label')
      const img = card.querySelector('.picture')
      const curIndex = index % (animationVals.length - 1)
      let duration = 0.5

      window.GSAP.set(img, { clipPath: animationVals[curIndex].in })

      this.reduceMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches

      if (this.reduceMotion) {
        duration = 0
      }

      const animation = window.GSAP.timeline()
      const cardAnim = window.GSAP.fromTo(img, { clipPath: animationVals[curIndex].in }, { clipPath: animationVals[curIndex].out, duration: duration })

      animation.add(cardAnim, 0)

      if (label && !this.reduceMotion) {
        const labelAnim = window.GSAP.from(label.querySelector('.exhibition-label__span'), { opacity: 0, duration: duration })
        animation.add(labelAnim, '-=.2')
      }

      window.ScrollTrigger.create({ trigger: card, start: `top 80%`, end: `bottom 80%`, scrub: true, animation })
    })
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.card-grid').forEach(element => {
    element.instance = element.instance || new CardGridComponent(element)
  })
)
