import Component from '../../../assets/scripts/modules/component'

export default class LogoComponent extends Component {
  init() {
    this.video = this.element.querySelector('video')
    this.reduceMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches

    this.doDohaAnimation()
  }

  doDohaAnimation() {
    if (!this.video || !this.element.classList.contains('logo--designdoha-animated') || this.reduceMotion) {
      return
    }

    const sourceWebm = this.video.getAttribute('data-source-webm') || ''
    const mimetypeWebm = this.video.getAttribute('data-mimetype-webm') || ''
    const sourceMp4 = this.video.getAttribute('data-source-mp4') || ''
    const mimetypeMp4 = this.video.getAttribute('data-mimetype-mp4') || ''

    if (!sourceWebm || !sourceMp4) {
      return
    }

    const sourceElement = document.createElement('source')

    const needsMp4 = !!(document.documentElement.classList.contains('is-safari') || document.documentElement.classList.contains('is-ios'))

    sourceElement.setAttribute('src', needsMp4 ? sourceMp4 : sourceWebm)
    sourceElement.setAttribute('type', needsMp4 ? mimetypeMp4 : mimetypeWebm)

    this.element.classList.add('logo--designdoha-animated-starting')

    this.video.appendChild(sourceElement)
    this.onDohaVideoLoaded()
  }

  onDohaVideoLoaded() {
    this.video.addEventListener('play', () => {
      window.requestAnimationFrame(() => {
        this.element.classList.remove('logo--designdoha-animated-starting')
        this.element.classList.add('logo--designdoha-animated-playing')
      })
    })

    this.video.addEventListener('ended', () => {
      window.requestAnimationFrame(() => {
        this.element.classList.remove('logo--designdoha-animated-playing')
        this.element.classList.add('logo--designdoha-animated-done')

        const heroContentElement = document.getElementById('hero-content')
        if (heroContentElement) {
          heroContentElement.classList.add('hero__content--animation')
        }
      })
    })

    this.video.play()
  }
}

window.addEventListener('init-immediate', () =>
  [...document.querySelectorAll('.logo')].forEach(element => {
    element.instance = element.instance || new LogoComponent(element)
  })
)
