import Component from '../../../assets/scripts/modules/component'

export default class HighlightHeaderComponent extends Component {
  init() {
    this.video = this.element.querySelector('.video__video')
    this.reduceMotion = window.matchMedia('(prefers-reduced-motion: reduce)')

    this.initVideo()
  }

  initVideo() {
    if (!this.video || this.reduceMotion.matches) {
      this.element.addEventListener('blur', () => this.video.pause())
    }
  }
}

window.addEventListener('init-immediate', () =>
  document.querySelectorAll('.highlight-header').forEach(element => {
    element.instance = element.instance || new HighlightHeaderComponent(element)
  })
)
