// For Dynamic CDN Asset Path
__webpack_public_path__ = window.webpackPublicPath || '/static/scripts/' /* eslint-disable-line camelcase, no-undef */

// import konamiCode from './utilities/konami-code.js'

// Import in reverse order from stylesheets
require('../../components/structures/whats-on-controller/whats-on-controller.js')
require('../../components/structures/tickets-controller/tickets-controller.js')
require('../../components/structures/skip-to-main/skip-to-main.js')
require('../../components/structures/notification-bar/notification-bar.js')
require('../../components/structures/menu-overlay/menu-overlay.js')
require('../../components/structures/menu-bar/menu-bar.js')
require('../../components/structures/document/document.js')
require('../../components/structures/creative-network-overlay/creative-network-overlay.js')
require('../../components/structures/cookie-bar/cookie-bar.js')
require('../../components/organisms/video-story-header/video-story-header.js')
require('../../components/organisms/tickets-summary/tickets-summary.js')
require('../../components/organisms/tickets-reserved/tickets-reserved.js')
require('../../components/organisms/tickets-payment-controller/tickets-payment-controller.js')
require('../../components/organisms/tickets-nav-bar/tickets-nav-bar.js')
require('../../components/organisms/tickets-action-bar/tickets-action-bar.js')
require('../../components/organisms/ticker-bar/ticker-bar.js')
require('../../components/organisms/test-duet-datepicker/test-duet-datepicker.js')
require('../../components/organisms/table-of-contents/table-of-contents.js')
require('../../components/organisms/storytelling-carousel/storytelling-carousel.js')
require('../../components/organisms/story-header/story-header.js')
require('../../components/organisms/simple-carousel/simple-carousel.js')
require('../../components/organisms/shared-ticker-bar/shared-ticker-bar.js')
require('../../components/organisms/selected-filters-bar-new/selected-filters-bar-new.js')
require('../../components/organisms/selected-filters-bar/selected-filters-bar.js')
require('../../components/organisms/polaroid-header/polaroid-header.js')
require('../../components/organisms/overview-header/overview-header.js')
require('../../components/organisms/overlay-story-video/overlay-story-video.js')
require('../../components/organisms/overlay-dropdown-filter-bar-mobile/overlay-dropdown-filter-bar-mobile.js')
require('../../components/organisms/object-slider-carousel/object-slider-carousel.js')
require('../../components/organisms/member-tier-selector/member-tier-selector.js')
require('../../components/organisms/mal-lawal-game/mal-lawal-game.js')
require('../../components/organisms/location-bar/location-bar.js')
require('../../components/organisms/highlight-header/highlight-header.js')
require('../../components/organisms/hero/hero.js')
require('../../components/organisms/filter-header/filter-header.js')
require('../../components/organisms/filter-date-bar/filter-date-bar.js')
require('../../components/organisms/filter-bar/filter-bar.js')
require('../../components/organisms/figure-overlay/figure-overlay.js')
require('../../components/organisms/exhibition-header/exhibition-header.js')
require('../../components/organisms/dropdown-filter-bar/dropdown-filter-bar.js')
require('../../components/organisms/composition-card-grid/composition-card-grid.js')
require('../../components/organisms/celebration-header/celebration-header.js')
require('../../components/organisms/card-overlay/card-overlay.js')
require('../../components/organisms/card-highlights-grid/card-highlights-grid.js')
require('../../components/organisms/card-grid/card-grid.js')
require('../../components/organisms/article-photo-carousel/article-photo-carousel.js')
require('../../components/organisms/article-micrio-embed/article-micrio-embed.js')
require('../../components/organisms/article-header-hubs/article-header-hubs.js')
require('../../components/organisms/article-header/article-header.js')
require('../../components/organisms/article-cookies/article-cookies.js')
require('../../components/organisms/accordeon-header/accordeon-header.js')
require('../../components/molecules/transcription-block/transcription-block.js')
require('../../components/molecules/loader/loader.js')
require('../../components/molecules/form/form.js')
require('../../components/molecules/datepicker-single-input/datepicker-single-input.js')
require('../../components/molecules/datepicker-range-input/datepicker-range-input.js')
require('../../components/molecules/composition-card/composition-card.js')
require('../../components/molecules/card/card.js')
require('../../components/molecules/call-card/call-card.js')
require('../../components/atoms/video-embed/video-embed.js')
require('../../components/atoms/video/video.js')
require('../../components/atoms/text-field/text-field.js')
require('../../components/atoms/share-button/share-button.js')
require('../../components/atoms/picture/picture.js')
require('../../components/atoms/multiselect-dropdown-field/multiselect-dropdown-field.js')
require('../../components/atoms/micrio-embed/micrio-embed.js')
require('../../components/atoms/logo/logo.js')
require('../../components/atoms/link-dropdown/link-dropdown.js')
require('../../components/atoms/iframe-embed/iframe-embed.js')
require('../../components/atoms/hubspot-embed/hubspot-embed.js')
require('../../components/atoms/duet-datepicker/duet-datepicker.js')
require('../../components/atoms/duet-datepicker/duet-date-picker.js')
require('../../components/atoms/datepicker-single-field/datepicker-single-field.js')
require('../../components/atoms/datepicker-range-field/datepicker-range-field.js')
require('../../components/atoms/datepicker-dropdown/datepicker-dropdown.js')
require('../../components/atoms/audio-embed/audio-embed.js')
require('../../components/atoms/add-to-calendar-button/add-to-calendar-button.js')

require('../../components/organisms/tickets-table/tickets-table.jsx')
require('../../components/organisms/tickets-datetime-picker/tickets-datetime-picker.jsx')
require('../../components/molecules/datepicker-range/datepicker-range.jsx')
require('../../components/molecules/datepicker/datepicker.jsx')

// Whats On Grid functionality
require('./whatson-grid/whatson-grid.js')

console.log('\n %cMade with %c♥%c by Fabrique \n', 'font: 16px serif;', 'font: 13px serif; color: #f00;', 'font: 16px serif;')

// There are 4 load events:
// - 'init-immediate': (compiles when the script is loaded; blocks rendering)
// - 'init-load': (on DOMContentLoaded event; does not block rendering)
// - 'init-after-load': (on Load event, slightly after DOMContentLoaded)
// - 'init-delayed-load': (after Load event, with a slight delay, for iframes and such)
// Usually, the 'init-load' event will suffice.
window.dispatchEvent(new CustomEvent('init-immediate'))
window.addEventListener('DOMContentLoaded', () => window.dispatchEvent(new CustomEvent('init-load')))
window.addEventListener('load', () => window.dispatchEvent(new CustomEvent('init-after-load')))
window.addEventListener('load', () => window.setTimeout(() => window.dispatchEvent(new CustomEvent('init-delayed-load')), 1000))

// Focus stuff
document.addEventListener('keydown', () => document.documentElement.classList.add('key-pressed'))
document.addEventListener('mousedown', () => document.documentElement.classList.remove('key-pressed'))
document.addEventListener('touchstart', () => document.documentElement.classList.remove('key-pressed'))

// This gives you vh-like values, but without scrollbars and addressbar and such
// This allows usage of document height / width in CSS
// NOTE: Only turn this on when needed - very heavy when resizing
// NOTE: Resize observer only from Safari 13.1 onwards, so back to window.onresize.
// new ResizeObserver(() => {
// ...
// }).observe(document.documentElement)
if (document.documentElement.classList.contains('is-ios') || document.documentElement.classList.contains('is-android')) {
  const onResizeHandler = () => {
    window.requestAnimationFrame(() => {
      document.documentElement.style.setProperty('--like100vw', `${document.documentElement.clientWidth}px`)
      document.documentElement.style.setProperty('--like100vh', `${document.documentElement.clientHeight}px`)
      document.documentElement.style.setProperty('--like100dw', `${document.documentElement.offsetWidth}px`)
      document.documentElement.style.setProperty('--like100dh', `${document.documentElement.offsetHeight}px`)
    })
  }
  window.addEventListener('resize', () => onResizeHandler())
  onResizeHandler()
}

// Show debug styles on L hotkey and grid on G hotkey when document is focussed.
// if (WEBPACK_ENV.ENV === 'development') {
if (window.location.href.indexOf('fabriquehq') !== -1 || window.location.href.indexOf('127.0.0.1') !== -1 || window.location.href.indexOf('0.0.0.0') !== -1) {
  document.addEventListener('keydown', event => {
    if (event.target !== document.body) {
      return
    }

    if ((event.ctrlKey || event.metaKey) && event.key === 'l') {
      document.documentElement.classList.toggle('debug-layout')
    }

    document.cookie = `debug-layout=${document.documentElement.classList.contains('debug-layout')}; path=/;`
  })

  document.documentElement.classList.toggle('debug-layout', document.cookie.indexOf('debug-layout=true') !== -1)
}
