import Component from '../../../assets/scripts/modules/component'

const mathLerp = (a, b, n) => (1 - n) * a + n * b
const mathDistance = (x1, y1, x2, y2) => Math.hypot(x2 - x1, y2 - y1)

class HeroComponent extends Component {
  init() {
    this.reduceMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches

    if (document.body.classList.contains('subsite--m7')) {
      window.addEventListener('gsap-loaded', () => this.initM7HeroAnimation())
    }

    if (document.body.classList.contains('subsite--firestation')) {
      window.addEventListener('gsap-loaded', () => this.initFireStationAnimation())
    }
  }

  initFireStationAnimation() {
    if (!this.element.classList.contains('hero--image') || this.reduceMotion) return

    const content = this.element.querySelector('.hero__content')
    const contentHeight = content.offsetHeight
    let duration = 4

    this.reduceMotion = window.matchMedia('(prefers-reduced-motion: reduce)')

    if (this.reduceMotion.matches) {
      duration = 0
    }

    window.GSAP.fromTo(content, { y: contentHeight }, { y: 0, duration: duration, delay: 0.35, ease: 'power3.out' })
  }

  initM7HeroAnimation() {
    this.DOM = { content: document.querySelector('.hero__images') }
    if (!this.DOM.content || this.reduceMotion) return

    class M7Image {
      constructor(el) {
        this.DOM = { el: el }
        this.defaultStyle = {
          scale: 1,
          x: 0,
          y: 0,
          opacity: 0,
        }
        this.getRect()
        this.initEvents()
      }

      initEvents() {
        window.addEventListener('resize', () => this.resize())
      }

      resize() {
        // window.GSAP.set(this.DOM.el, this.defaultStyle) // Enable this line to reset images on window resize -GHE
        this.getRect()
      }

      getRect() {
        this.rect = this.DOM.el.getBoundingClientRect()
      }

      isActive() {
        return window.GSAP.isTweening(this.DOM.el) || this.DOM.el.style.opacity !== 0
      }
    }

    this.images = []
    ;[...this.DOM.content.querySelectorAll('img')].forEach(img => this.images.push(new M7Image(img)))
    this.imagesTotal = this.images.length
    this.imgPosition = 0
    this.zIndexVal = 1
    this.threshold = 200
    this.placingImages = false

    this.mousePos = this.lastMousePos = this.cacheMousePos = { x: 0, y: 0 }

    window.addEventListener('resize', () => {
      if (this.checkSmallScreen() && !this.placingImages) {
        document.querySelector('.hero__images')?.removeEventListener('mousemove', ev => this.getMousePos(ev))
        this.autoPlaceImages()
      } else {
        document.querySelector('.hero__images')?.addEventListener('mousemove', ev => this.getMousePos(ev))
        requestAnimationFrame(() => this.renderImages())
      }
    })

    document.querySelector('.hero__images')?.addEventListener('mousemove', ev => this.getMousePos(ev))
    requestAnimationFrame(() => this.renderImages())
  }

  getMouseDistance() {
    return mathDistance(this.mousePos.x, this.mousePos.y, this.lastMousePos.x, this.lastMousePos.y)
  }

  getMousePos(ev) {
    let posx = 0
    let posy = 0
    if (!ev) ev = window.event
    if (ev.pageX || ev.pageY) {
      posx = ev.pageX
      posy = ev.pageY
    } else if (ev.clientX || ev.clientY) {
      posx = ev.clientX + document.body.scrollLeft
      posy = ev.clientY + document.body.scrollTop
    }
    this.mousePos = { x: posx, y: posy }
  }

  checkSmallScreen() {
    return window.innerWidth <= 800
  }

  autoPlaceImages() {
    if (!this.placingImages) {
      this.placingImages = true
      this.images.forEach(() => {
        const img = this.images[this.imgPosition]

        this.cacheMousePos.x = Math.floor(Math.random() * (img.rect.x - this.DOM.content.offsetWidth))
        this.cacheMousePos.y = Math.floor(Math.random() * (img.rect.y - this.DOM.content.offsetHeight))
        this.mousePos.x = Math.floor(Math.random() * this.DOM.content.offsetWidth)
        this.mousePos.y = Math.floor(Math.random() * this.DOM.content.offsetHeight)

        window.GSAP.killTweensOf(img.DOM.el)

        window.GSAP.timeline().set(
          img.DOM.el,
          {
            startAt: { opacity: 0, scale: 1 },
            opacity: 1,
            scale: 1,
            duration: 0,
            zIndex: this.zIndexVal,
            x: this.cacheMousePos.x,
            y: this.cacheMousePos.y,
          },
          this.imgPosition * 0.2
        )

        ++this.zIndexVal
        this.imgPosition = this.imgPosition < this.imagesTotal - 1 ? this.imgPosition + 1 : 0

        this.lastMousePos = this.mousePos
      })
    }
  }

  showNextImage() {
    const img = this.images[this.imgPosition]
    window.GSAP.killTweensOf(img.DOM.el)

    window.GSAP.timeline()
      .set(
        img.DOM.el,
        {
          startAt: { opacity: 0, scale: 1, duration: 0 },
          opacity: 1,
          scale: 1,
          duration: 0,
          zIndex: this.zIndexVal,
          x: this.cacheMousePos.x - img.rect.width / 2,
          y: this.cacheMousePos.y - img.rect.height / 2,
        },
        0
      )
      .to(
        img.DOM.el,
        0.9,
        {
          ease: 'expo.out',
          x: this.mousePos.x - img.rect.width / 2,
          y: this.mousePos.y - img.rect.height / 2,
        },
        0
      )
  }

  renderImages() {
    const distance = this.getMouseDistance()
    this.cacheMousePos.x = mathLerp(this.cacheMousePos.x || this.mousePos.x, this.mousePos.x, 0.1)
    this.cacheMousePos.y = mathLerp(this.cacheMousePos.y || this.mousePos.y, this.mousePos.y, 0.1)

    if (distance > this.threshold) {
      this.showNextImage()

      ++this.zIndexVal
      this.imgPosition = this.imgPosition < this.imagesTotal - 1 ? this.imgPosition + 1 : 0

      this.lastMousePos = this.mousePos
    }

    let isIdle = true
    for (const img of this.images) {
      if (img.isActive()) {
        isIdle = false
        break
      }
    }
    if (isIdle && this.zIndexVal !== 1) {
      this.zIndexVal = 1
    }

    if (this.checkSmallScreen()) {
      this.autoPlaceImages()
    } else {
      requestAnimationFrame(() => this.renderImages())
    }
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.hero').forEach(element => {
    element.instance = element.instance || new HeroComponent(element)
  })
)
