import Component from '../../../assets/scripts/modules/component'
import moment from 'moment'
import { createFocusTrap } from 'focus-trap'

const CUSTOM_FORMAT = 'DD-MM-YYYY' // Datepicker uses this format internally -RVP

class DatePickerRangeInputField extends Component {
  init() {
    this.fromDateInput = this.element.querySelector('.from-date__input')
    this.toDateInput = this.element.querySelector('.to-date__input')
    this.dateTo = this.toDateInput.dataset.dateRangeTo
    this.dateFrom = this.fromDateInput.dataset.dateRangeFrom
    this.calendar = this.element.querySelector('.datepicker-range-field__calendar-container')
    this.datepickerEl = this.element.querySelector('.datepicker-range')
    this.calendarButton = this.element.querySelector('.datepicker-range-field__calendar-icon')

    this.initAccessibility()
    this.initCalendar()
  }

  initCalendar() {
    this.dateFormat = this.datepickerEl.dataset.dateFormat
    this.datepickerEl.dataset.locale === 'ar' ? moment.locale('ar') : moment.locale('en-gb')

    const urlParams = new URLSearchParams(window.location.search)
    const toDate = urlParams.get('to_date')
    if (toDate) {
      this.toDateInput.value = moment(toDate).format(this.dateFormat || CUSTOM_FORMAT)
    }
    const fromDate = urlParams.get('from_date')
    if (fromDate) {
      this.fromDateInput.value = moment(fromDate).format(this.dateFormat || CUSTOM_FORMAT)
    }
    if (fromDate && toDate) {
      this.calendarButton.ariaLabel = `Change date range, ${this.fromDateInput.value} to ${this.toDateInput.value}`
    }
    this.calendarButton.addEventListener('click', event => this.onClickHandler(event, 'startDate'))
    this.fromDateInput.addEventListener('click', event => this.onClickHandler(event, 'startDate'))
    this.toDateInput.addEventListener('click', event => this.onClickHandler(event, 'endDate'))
    this.element.addEventListener('disableFocusTrap', () => {
      this.datePickerFocusTrap.deactivate()
    })
    this.datepickerAnimationInit = window.requestAnimationFrame(() => this.datepickerEl.dispatchEvent(new CustomEvent('init-load')))
  }

  initAccessibility() {
    this.datePickerFocusTrap = createFocusTrap(this.element, {
      onActivate: () => {
        this.datepickerAnimationActivate = window.requestAnimationFrame(() => this.datepickerEl.dispatchEvent(new CustomEvent('activate')))
        this.preventArrowScrollingWindow(true)
      },
      onDeactivate: () => {
        this.calendar.ariaHidden = 'true'
        this.fromDateInput.classList.remove('from-date__input--active')
        this.toDateInput.classList.remove('to-date__input--active')
        if (this.fromDateInput.value && this.toDateInput.value) {
          this.calendarButton.ariaLabel = `Change date range, ${this.fromDateInput.value} to ${this.toDateInput.value}`
        } else {
          this.calendarButton.ariaLabel = `Pick a date range`
        }
        this.calendarButton.focus()
        this.preventArrowScrollingWindow(false)
        window.cancelAnimationFrame(this.datepickerAnimationInit)
        window.cancelAnimationFrame(this.datepickerAnimationActivate)
      },
      escapeDeactivates: true,
      clickOutsideDeactivates: true,
      returnFocusOnDeactivate: true,
      initialFocus: this.todayField,
    })
  }

  stopWindowScrolling(e) {
    if (['Space', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].indexOf(e.code) > -1) {
      e.preventDefault()
    }
  }

  preventArrowScrollingWindow(prevent = true) {
    if (prevent) {
      window.addEventListener('keydown', this.stopWindowScrolling, false)
    } else {
      window.removeEventListener('keydown', this.stopWindowScrolling, false)
    }
  }

  onClickHandler(event, focusedDate) {
    event.preventDefault()
    if (this.calendar.ariaHidden === 'true') {
      this.calendar.ariaHidden = 'false'
      this.datepickerEl.dataset.focusedDate = focusedDate
      this.fromDateInput.classList.add('from-date__input--active')
      this.toDateInput.classList.add('to-date__input--active')
      this.datePickerFocusTrap.activate()
    } else {
      this.datePickerFocusTrap.deactivate()
      this.calendarButton.focus()
    }
  }

  disableFocusTrap() {
    this.datePickerFocusTrap.deactivate()
  }
}

window.addEventListener('mouseup', function (event) {
  const datePickerRange = document.querySelector('.datepicker-range-field')
  if (!datePickerRange) return
  const calendar = datePickerRange.querySelector('.datepicker-range-field__calendar-container')
  const fromDateInput = datePickerRange.querySelector('.datepicker-range-field__datepicker-field-wrapper .from-date__input')
  const toDateInput = datePickerRange.querySelector('.datepicker-range-field__datepicker-field-wrapper .to-date__input')
  const calendarIcon = datePickerRange.querySelector('.datepicker-range-field__calendar-icon')
  const calendarElements = [...calendar.querySelectorAll('*'), calendar, fromDateInput, toDateInput, ...calendarIcon.querySelectorAll('*')]

  if (!calendarElements.includes(event.target)) {
    calendar.ariaHidden = 'true'
    fromDateInput.classList.remove('from-date__input--active')
    toDateInput.classList.remove('to-date__input--active')
  }
})

window.addEventListener('init-load', () =>
  document.querySelectorAll('.datepicker-range-field').forEach(element => {
    element.instance = element.instance || new DatePickerRangeInputField(element)
  })
)
