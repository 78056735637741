import breakpoints from '../../../assets/scripts/modules/breakpoints'
import Component from '../../../assets/scripts/modules/component'
import { hasParentClassname } from '../../../assets/scripts/utilities/browse-parents'

class PictureComponent extends Component {
  init() {
    this.img = this.element.querySelector('img')
    this.sourceElements = this.element.querySelectorAll('source')
    this.reduceMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches

    if (!this.img || !this.sourceElements.length) {
      return
    }

    this.sources = []
    this.sourceElements.forEach(source => this.sources.push({ src: source.srcset, media: source.media || 'screen', style: { objectFit: source.style.objectFit, objectPosition: source.style.objectPosition } }))

    this.addMediaQueryListeners()
    this.applySource(this.sources.find(source => window.matchMedia(source.media).matches))

    if (this.img.currentSrc) {
      this.element.classList.add('picture--loaded')
    } else {
      this.img.addEventListener('load', this.onImageLoad.bind(this))
    }

    if (this.element.classList.contains('picture--caption')) {
      this.initCaption()
    }

    if (document.body.classList.contains('subsite--tasweer')) {
      window.addEventListener('gsap-loaded', () => this.initTasweerPictureAnimation())
    }

    if (document.body.classList.contains('subsite--rubaiyat')) {
      window.addEventListener('gsap-loaded', () => this.initRubaiyatPictureAnimation())
    }
  }

  applySource(source) {
    if (source.style.objectFit !== undefined) {
      this.img.style.objectFit = source.style.objectFit
    }

    if (source.style.objectPosition !== undefined) {
      this.img.style.objectPosition = source.style.objectPosition
    }
  }

  addMediaQueryListeners() {
    this.sources.forEach(source => window.matchMedia(source.media).addListener(result => result.matches && this.applySource(source)))
  }

  onImageLoad() {
    this.element.dispatchEvent(new CustomEvent('picture-loaded', { detail: this.img }))
    this.element.classList.add('picture--loaded')
    this.img.removeEventListener('load', this.onImageLoad.bind(this))
  }

  initCaption() {
    const iconBtn = this.element.querySelector('.picture__caption-icon-button')
    const captionWrapper = this.element.querySelector('.picture__caption-wrapper')

    if (!iconBtn || !captionWrapper) return

    this.captionActive = false

    iconBtn.addEventListener('click', () => {
      captionWrapper.classList.toggle('picture__caption-wrapper-expanded', !this.captionActive)
      this.captionActive = !this.captionActive
    })
  }

  initTasweerPictureAnimation() {
    // Solution to only include certain components in the animation.
    // Not ideal, but the other solution would be to add a 'shouldAnimate' property to picture component and have parents pass this.
    // Quite a drastic change in the templates for all subsites when only Tasweer wants these expressive animations at this time.
    // -RVP

    if (this.reduceMotion) {
      return
    }

    let component = null
    ;['hero', 'card', 'exhibition-header', 'article-header-hubs'].forEach(classname => {
      if (hasParentClassname(this.element, classname)) {
        component = classname
      }
    })

    const image = this.element.querySelector('.picture__image')

    if (!image || !component || !breakpoints.isLandscape()) {
      return
    }

    const clonedImage = image.cloneNode()
    clonedImage.classList.add('picture__image--cloned')
    image.parentNode.insertBefore(clonedImage, image)

    const fromScale = component === 'card' || component === 'hero' ? 0.8 : 0.9
    const timeline = window.GSAP.timeline({ paused: true })
    timeline.fromTo(clonedImage, { scale: fromScale }, { scale: 1, duration: 0.5 })

    image.addEventListener('mouseenter', () => {
      timeline.play()
    })

    image.addEventListener('mouseleave', () => {
      timeline.pause()
      timeline.reverse()
    })

    document.addEventListener('resize', () => {
      timeline.restart()
      timeline.pause()
    })
  }

  initRubaiyatPictureAnimation() {
    if (this.reduceMotion) {
      return
    }

    // Solution to only include certain components in the animation. Based on initTasweerPictureAnimation -CME
    const numberOfClones = 9
    const clonedImages = []
    const animationDuration = 0.75 // Time in seconds -CME
    const finalImageOffset = 40 // Distance in pixels -CME

    let component = null
    ;['card', 'composition-card'].forEach(classname => {
      if (hasParentClassname(this.element, classname)) {
        component = classname
      }
    })

    const image = this.element.querySelector('.picture__image')

    if (!image || !component || !breakpoints.isLandscape()) {
      return
    }

    const timeline = window.GSAP.timeline({ paused: true })

    for (let i = 0; i < numberOfClones; ++i) {
      clonedImages[i] = image.cloneNode()
      clonedImages[i].style.zIndex = (i + 1).toString()
      image.parentNode.insertBefore(clonedImages[i], image)
      timeline.fromTo(
        clonedImages[i],
        { x: 0, y: 0 },
        {
          duration: (animationDuration / numberOfClones) * (i + 1),
          x: (finalImageOffset / numberOfClones) * (i + 1).toString(),
          y: (finalImageOffset / numberOfClones) * (i + 1).toString(),
        },
        `>-${(animationDuration / numberOfClones) * i}`
      )
    }

    image.closest('.' + component).addEventListener('mouseenter', () => {
      timeline.play()
    })

    image.closest('.' + component).addEventListener('mouseleave', () => {
      timeline.pause()
      timeline.reverse()
    })

    document.addEventListener('resize', () => {
      timeline.restart()
      timeline.pause()
    })
  }
}

window.PictureComponent = PictureComponent

window.addEventListener('init-load', () =>
  document.querySelectorAll('.picture').forEach(element => {
    element.instance = element.instance || new PictureComponent(element)
  })
)
