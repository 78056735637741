import Component from '../../../assets/scripts/modules/component'

class FilterBarComponent extends Component {
  init() {
    this.initDisclosure()
  }

  initDisclosure() {
    this.disclosureButton = this.element.querySelector('.filter-bar__disclosure-button')
    this.disclosureRegion = this.element.querySelector('.filter-bar__disclosure-content-container')

    if (!this.disclosureButton || !this.disclosureRegion) {
      return
    }

    this.disclosureButton.addEventListener('click', () => this.toggleRegion(this.disclosureRegion, this.disclosureButton))
    this.setTabIndexes(this.disclosureRegion, true)
  }

  toggleRegion(region, button) {
    if (!region || !button) {
      return
    }

    const isCollapsing = button.getAttribute('aria-expanded') === 'true'

    region.setAttribute('aria-hidden', isCollapsing)
    button.setAttribute('aria-expanded', !isCollapsing)

    this.setTabIndexes(region, isCollapsing)
  }

  setTabIndexes(region, isCollapsed) {
    const focusableElements = [...region.querySelectorAll('a[href], button, input, select, textarea')]
    focusableElements.forEach(element => (isCollapsed ? element.setAttribute('tabindex', -1) : element.removeAttribute('tabindex')))
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.filter-bar').forEach(element => {
    element.instance = element.instance || new FilterBarComponent(element)
  })
)
