import breakpoints from '../../../assets/scripts/modules/breakpoints'
import Component from '../../../assets/scripts/modules/component'
import insertScript from '../../../assets/scripts/utilities/insert-script'
import LoaderComponent from '../../molecules/loader/loader'

const isLocalhost = window.location.href.indexOf('0.0.0.0') !== -1 || window.location.href.indexOf('127.0.0.1') !== -1 || window.location.href.indexOf('localhost') !== -1

const defaultFetchOptions = {
  cache: 'no-cache',
  redirect: 'follow',
  credentials: 'include',
  mode: isLocalhost ? 'same-origin' : 'cors',
}

// TODO: When developing locally you want to have a TST API endpoint that fetches locations data
// API endpoint to be provided by BE. -RvP 31/5/2023
export default class CreativeNetworkComponent extends Component {
  init() {
    this.apiKey = document.documentElement.getAttribute('data-maps-api-key') || ''

    this.closeButton = this.element.querySelector('.creative-network-overlay__close-button')
    this.closeButton.addEventListener('click', event => this.handleCloseOverlayButtonClick(event))
    this.selectedLocation = null
    this.introduction = this.element.querySelector('.creative-network-overlay__introduction')
    this.mapElement = this.element.querySelector('.creative-network-overlay__map')
    this.infoWindowsAndMarkers = []
    this.clusterMarkers = []
    this.panels = []
    this.locations = []
    this.locationWrapper = this.element.querySelector('.creative-network-overlay__locations-wrapper')

    this.initIntroduction()

    window.addEventListener('resize', () => this.adjustInfoWindowAfterScreenResize())
    window.addEventListener('open-network-map', event => {
      this.showLoader()
      this.handleOpenOverlayButtonClick(event)
    })
  }

  async initGoogleMaps() {
    this.google = window.google

    this.map = new this.google.maps.Map(this.mapElement, {
      center: { lat: 25.286106, lng: 51.534817 },
      zoom: 14,
      mapTypeId: 'satellite',
      disableDefaultUI: true,
    })

    this.mapBounderies = new this.google.maps.LatLngBounds()

    this.initMarkersAndInfowindow()
    this.initZoomButtons()
    this.initMarkerClusterer()

    this.map.fitBounds(this.mapBounderies)
  }

  async initMarkerClusterer() {
    const { MarkerClusterer, DefaultRenderer } = await import('@googlemaps/markerclusterer')

    const mapsReference = this.google.maps
    class NewRenderer extends DefaultRenderer {
      render({ count, position }, stats) {
        const color = '#000000'
        const svg = window.btoa(`
          <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
            <circle cx="120" cy="120" opacity="1" r="120" />
          </svg>`)

        return new mapsReference.Marker({
          position,
          icon: {
            url: `data:image/svg+xml;base64,${svg}`,
            scaledSize: new mapsReference.Size(45, 45),
          },
          label: {
            text: String(count),
            color: 'rgb(255,255,255)',
            fontSize: '24px',
            fontWeight: 'bold',
          },
          title: `Cluster of ${count} markers`,
          zIndex: Number(mapsReference.Marker.MAX_ZINDEX) + count,
        })
      }
    }

    this.markerCluster = new MarkerClusterer({ map: this.map, markers: this.clusterMarkers, renderer: new NewRenderer() })
  }

  initMarkersAndInfowindow() {
    this.locations.forEach(location => {
      // I would prefer these to be in a sprite or external -EKL
      const iconPinUrl = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' width='34' height='34' viewBox='0 0 34 34'%3E%3Ccircle cx='17' cy='17' r='17' fill='%23000'/%3E%3Cpath fill='%23fff' d='M16.97 7.93c-3.73 0-6.77 3-6.77 6.67-.02 5.38 6.51 11.83 6.77 12.01 0 0 6.8-6.63 6.77-12a6.73 6.73 0 0 0-6.77-6.68Z'/%3E%3Ccircle cx='17.17' cy='14.97' r='3.25' fill='%23000'/%3E%3C/svg%3E"
      const iconArtUrl = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' width='34' height='34' viewBox='0 0 34 34'%3E%3Ccircle cx='17' cy='17' r='17' fill='%23000'/%3E%3Cpath d='M19.14 19.16c-.43.42-.96.64-1.61.64a2.18 2.18 0 0 1-2.25-2.25 2.18 2.18 0 0 1 2.25-2.25 2.18 2.18 0 0 1 2.25 2.25c0 .65-.22 1.18-.64 1.6Z' fill='%23fff'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.53 23.75A9.2 9.2 0 0 0 23 22.02a9.9 9.9 0 0 0 3.53-4.47A9.9 9.9 0 0 0 23 13.08a9.2 9.2 0 0 0-5.47-1.74 9.2 9.2 0 0 0-5.48 1.74 9.9 9.9 0 0 0-3.52 4.47 9.9 9.9 0 0 0 3.52 4.47 9.2 9.2 0 0 0 5.48 1.73Zm0-1.16a4.88 4.88 0 0 1-3.6-1.46 4.88 4.88 0 0 1-1.45-3.58c0-1.42.49-2.62 1.46-3.6a4.88 4.88 0 0 1 3.59-1.45c1.42 0 2.61.49 3.58 1.46a4.88 4.88 0 0 1 1.46 3.59c0 1.42-.48 2.61-1.46 3.58a4.88 4.88 0 0 1-3.58 1.46Z' fill='%23fff'/%3E%3C/svg%3E"
      const iconHeritageUrl = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' width='34' height='34' viewBox='0 0 34 34'%3E%3Ccircle cx='17' cy='17' r='17' fill='%23000'/%3E%3Cpath d='M23.8 14.06H11.2V24h3.83v-4.45c0-1.44 1.13-2.6 2.52-2.6a2.56 2.56 0 0 1 2.51 2.6V24h3.73v-9.94ZM11.22 14.06h12.56V10h-2.1v2.53h-3.14V10h-2.08v2.53h-3.14V10h-2.1v4.06Z' fill='%23fff'/%3E%3C/svg%3E"

      const googleMapsMarker = new this.google.maps.Marker({
        position: { lat: parseFloat(location.latitude), lng: parseFloat(location.longitude) },
        optimized: false,
        map: this.map,
        label: {
          className: 'creative-network-overlay__marker-label',
          text: `${location.name}`,
        },
        icon: {
          className: 'creative-network-overlay__marker-icon',
          url: location.type === 'art' ? iconArtUrl : location.type === 'heritage' ? iconHeritageUrl : iconPinUrl,
          size: new this.google.maps.Size(34, 34),
          labelOrigin: new this.google.maps.Point(17, -28),
        },
      })

      const infowindow = new this.google.maps.InfoWindow({
        maxWidth: 400,
      })

      googleMapsMarker.addListener('click', event => {
        this.togglePanel(location)
        this.map.panTo(googleMapsMarker.getPosition())

        if (breakpoints.isPortrait()) {
          this.map.panBy(-250, 0)
        } else {
          this.map.panBy(0, (window.innerHeight - 250) / 2)
        }

        this.closeInfoWindows()
        this.hideOverlayCloseButton()
      })

      this.infoWindowsAndMarkers.push({ location: location, marker: googleMapsMarker, infowindow: infowindow })
      this.clusterMarkers.push(googleMapsMarker)
      this.mapBounderies.extend(googleMapsMarker.getPosition())
      this.setupInfoWindow(googleMapsMarker, location, infowindow)
    })
  }

  getEventsText(location, prefixEvents = '', prefixExhibitions) {
    if (!location) {
      return
    }

    this.singleEventTitle = this.singleEventTitle || this.element.getAttribute('data-single-event-title') || ''
    this.multipleEventsTitle = this.multipleEventsTitle || this.element.getAttribute('data-multiple-events-title') || ''
    this.singleExhibitionTitle = this.singleExhibitionTitle || this.element.getAttribute('data-single-exhibition-title') || ''
    this.multipleExhibitionsTitle = this.multipleExhibitionsTitle || this.element.getAttribute('data-multiple-exhibitions-title') || ''

    let eventsText = ''

    if (location.eventCount > 1 && this.multipleEventsTitle) {
      eventsText += `${location.eventCount.toString()} ${this.multipleEventsTitle}`
    } else if (location.eventCount === 1 && this.singleEventTitle) {
      eventsText += `${location.eventCount.toString()} ${this.singleEventTitle}`
    }

    eventsText = eventsText.length ? prefixEvents + eventsText : ''

    let exhibitionsText = ''

    if (location.exhibitionCount > 1 && this.multipleExhibitionsTitle) {
      exhibitionsText += `${location.exhibitionCount.toString()} ${this.multipleExhibitionsTitle}`
    } else if (location.exhibitionCount === 1 && this.singleExhibitionTitle) {
      exhibitionsText += `${location.exhibitionCount.toString()} ${this.singleExhibitionTitle}`
    }

    exhibitionsText = exhibitionsText.length ? prefixExhibitions + exhibitionsText : ''

    return `${eventsText} ${exhibitionsText}`
  }

  setupInfoWindow(googleMapsMarker, location, infowindow) {
    const infoWindowName = location.name ? `<span class="creative-network-overlay__infowindow-name"> ${location.name} </span>` : ''
    const infoWindowTitle = location.title ? `<span class="creative-network-overlay__infowindow-title"> ${location.title} </span>` : ''
    const infoWindowLogo = location.logo ? `<img src="${location.logo}" class="creative-network-overlay__infowindow-logo"/>` : ''
    const infoWindowImage = location.image ? `<img src="${encodeURI(location.image)}" class="creative-network-overlay__infowindow-image"/>` : ''
    const infoWindowText = location.subtitle ? location.subtitle : this.getEventsText(location, '', '· ')

    infowindow.setContent(`
      <div class="creative-network-overlay__infowindow">
        ${infoWindowImage}

        <div class="creative-network-overlay__infowindow-content">
          <div class="creative-network-overlay__infowindow-content-top">
            ${infoWindowName}
            ${infoWindowTitle}
            ${infoWindowText}
          </div>

          <div class="creative-network-overlay__infowindow-content-bottom">
            ${infoWindowLogo}
          </div>
        </div>
      </div>
    `)

    if (breakpoints.isLandscape()) {
      googleMapsMarker.addListener('mouseover', () => infowindow.open(this.map, googleMapsMarker))
      googleMapsMarker.addListener('mouseout', () => infowindow.close())
    }
  }

  initZoomButtons() {
    this.zoomInButton = this.element.querySelector('.button--zoom-in')
    this.zoomOutButton = this.element.querySelector('.button--zoom-out')

    if (this.zoomInButton) {
      this.zoomInButton.addEventListener('click', () => {
        this.map.setZoom(this.map.getZoom() + 1)
        this.zoomInButton.blur()
      })
    }

    if (this.zoomOutButton) {
      this.zoomOutButton.addEventListener('click', () => {
        this.map.setZoom(this.map.getZoom() - 1)
        this.zoomOutButton.blur()
      })
    }
  }

  async handleOpenOverlayButtonClick(event) {
    event.preventDefault()

    if (!this.element.dataset.locationsApi) {
      this.afterHandleAllLocationsResponse()
      return
    }

    // Fetch all locations -RVP
    try {
      const response = await fetch(this.element.dataset.locationsApi, defaultFetchOptions)
      const responseText = await response.text()

      this.handleAllLocationsResponse(responseText)
    } catch (error) {
      console.log('ERROR ::: ', error)
    }
  }

  adjustInfoWindowAfterScreenResize() {
    if (breakpoints.isLandscape()) {
      this.infoWindowsAndMarkers.forEach(element => {
        element.infowindow.close()

        element.marker.addListener('mouseover', () => element.infowindow.open(this.map, element.marker))
        element.marker.addListener('mouseout', () => element.infowindow.close())
      })
    } else {
      this.panels.forEach(panel => {
        // Reset styling which was added on mobile for dragging the panel -CME
        panel.style.bottom = null
        panel.scrollTop = 0
      })
    }
  }

  handleAllLocationsResponse(response) {
    this.locationWrapper.innerHTML = response

    this.afterHandleAllLocationsResponse()
  }

  async afterHandleAllLocationsResponse() {
    // After fetching locations HTML, re-init panels/locations based on old code. Adjust info window if necessary -RVP
    this.getLocationPanels()
    this.getLocationsData()

    if (!this.element.dataset.creativeNetworkApi) {
      this.handleSelectedLocationResponse()
      return
    }

    // Fetch last selected location -RVP
    try {
      const response = await fetch(this.element.dataset.creativeNetworkApi, defaultFetchOptions)
      const responseData = await response.json()

      this.handleSelectedLocationResponse(responseData)
    } catch (error) {
      this.handleError(error)
    }
  }

  handleSelectedLocationResponse(response) {
    if (!document.getElementById('gmaps')) {
      insertScript('gmaps', 'https://maps.googleapis.com/maps/api/js?key=' + this.apiKey, () => this.initGoogleMaps())
    }

    this.hideLoader()

    this.selectedLocation = response.selected_location
    this.locations.forEach(location => {
      if (parseInt(location.id) === response.selected_location) {
        this.togglePanel(location)
      }
    })
    this.introduction.classList.toggle('creative-network-overlay__introduction--hidden', response.closed_intro_ids.indexOf(parseInt(this.introduction.dataset.id)) >= 0)
    this.openOverlay()
  }

  handleError(error) {
    console.log(error)
    this.hideLoader()
  }

  handleCloseOverlayButtonClick(event) {
    event.preventDefault()

    this.closeOverlay()
  }

  handleClosePanelButtonClick(event, panel) {
    if (event) {
      event.preventDefault()
    }

    if (panel) {
      this.closePanel(panel)
    }

    this.setCurrentLocation()
    this.openMobileInfoWindows()
  }

  closeOverlay() {
    this.element.classList.remove('creative-network-overlay--visible')
    document.documentElement.classList.remove('prevent-scrolling')

    this.panels.forEach(panel => this.closePanel(panel, false))

    this.infoWindowsAndMarkers.forEach(item => {
      item.marker.label.className = 'creative-network-overlay__marker-label'

      // Force redraw of marker -EKL
      item.marker.setMap(null)
      item.marker.setMap(this.map)
    })
  }

  openOverlay() {
    this.element.classList.add('creative-network-overlay--visible')
    document.documentElement.classList.add('prevent-scrolling')

    this.infoWindowsAndMarkers.forEach(item => {
      item.marker.setAnimation(null)
    })
  }

  // Hydrate the location panels -RVP
  getLocationPanels() {
    this.panels = [...this.element.querySelectorAll('.creative-network-overlay__panel')]
    this.panels.forEach(panel => {
      panel.querySelector('.creative-network-overlay__panel-close-button').addEventListener('click', event => this.handleClosePanelButtonClick(event, panel))

      const pictures = [...panel.querySelectorAll('.picture')]
      pictures.forEach(picture => {
        picture.classList.add('picture--loaded')
      })
    })
  }

  getLocationsData() {
    this.locations = [...this.panels].map(location => ({
      id: location.dataset.id,
      name: location.dataset.name,
      title: location.dataset.title,
      logo: location.dataset.logo,
      address: location.dataset.address,
      latitude: location.dataset.latitude,
      longitude: location.dataset.longitude,
      image: location.dataset.image,
      eventCount: parseInt(location.dataset.eventCount),
      exhibitionCount: parseInt(location.dataset.exhibitionCount),
      subtitle: location.dataset.subtitle,
      type: location.dataset.type,
    }))
  }

  togglePanel(location) {
    this.toggleIntroduction(null, true)

    this.panels.forEach(panel => {
      const panelId = panel.getAttribute('data-id')

      if (panelId === location.id) {
        panel.classList.add('creative-network-overlay__panel--visible')
        this.setCurrentLocation(location.id)
      } else {
        this.closePanel(panel, false)
      }
    })

    this.infoWindowsAndMarkers.forEach(item => {
      item.marker.label.className = item.location.id === location.id ? 'creative-network-overlay__marker-label-selected' : 'creative-network-overlay__marker-label'

      // Force redraw of marker -EKL
      item.marker.setMap(null)
      item.marker.setMap(this.map)
    })
  }

  closePanel(panel, updateMarkers = true) {
    panel.classList.remove('creative-network-overlay__panel--visible')

    // If present, reset properties from dragging the panel on mobile -CME
    panel.style.bottom = null
    panel.scrollTop = 0

    if (updateMarkers) {
      this.infoWindowsAndMarkers.forEach(item => {
        item.marker.label.className = 'creative-network-overlay__marker-label'

        // Force redraw of marker -EKL
        item.marker.setMap(null)
        item.marker.setMap(this.map)
      })
    }

    this.showOverlayCloseButton()
  }

  areAllPanelsClosed() {
    return [...this.panels].every(panel => panel.classList.contains('creative-network-overlay__panel--visible') === false)
  }

  openMobileInfoWindows() {
    if (!breakpoints.isLandscape()) {
      this.infoWindowsAndMarkers.forEach(element => element.infowindow.open(this.map, element.marker))
    }
  }

  closeInfoWindows() {
    this.infoWindowsAndMarkers.forEach(element => element.infowindow.close())
  }

  initIntroduction() {
    const buttons = [...this.element.querySelectorAll('.button--toggle-introduction')]
    buttons.forEach(button => button.addEventListener('click', event => this.toggleIntroduction(event)))
  }

  async toggleIntroduction(event, forceClose = false) {
    if (event) {
      event.preventDefault()
      event.target.blur()
    }

    if (forceClose || !this.introduction.classList.contains('creative-network-overlay__introduction--hidden')) {
      this.introduction.classList.add('creative-network-overlay__introduction--hidden')
    } else {
      this.introduction.classList.remove('creative-network-overlay__introduction--hidden')
    }
  }

  async setCurrentLocation(locationId) {
    if (!this.element.dataset.creativeNetworkApi) {
      return
    }

    try {
      const response = await fetch(`${this.element.dataset.creativeNetworkApi}?location_id=${locationId || 0}`, defaultFetchOptions)
      const responseData = await response.json() // eslint-disable-line no-unused-vars
    } catch (error) {
      console.log(error)
    }
  }

  hideOverlayCloseButton() {
    this.closeButton.classList.add('panel-is-open')
  }

  showOverlayCloseButton() {
    this.closeButton.classList.remove('panel-is-open')
  }

  showLoader() {
    const loaderContainer = document.querySelector('.container--overlays')
    this.loader = this.loader ?? new LoaderComponent(loaderContainer, true)
  }

  hideLoader() {
    this.loader.destroy()
    this.loader = null
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.creative-network-overlay').forEach(element => {
    element.instance = element.instance || new CreativeNetworkComponent(element)
  })
)
