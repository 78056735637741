import Component from '../../../assets/scripts/modules/component'

const ANIMATION_DELAY = 16

export default class PolaroidHeaderComponent extends Component {
  init() {
    this.delayTimeout = null
    this.fallbackTimeout = null

    window.addEventListener('DOMContentLoaded', () => this.onPictureLoaded())
    this.element.classList.add('polaroid-header--preload')
  }

  onPictureLoaded() {
    window.clearTimeout(this.fallbackTimeout)

    this.delayTimeout = window.setTimeout(() => {
      this.element.classList.remove('polaroid-header--preload')
      this.element.classList.add('polaroid-header--loaded')
    }, ANIMATION_DELAY)
  }
}

window.addEventListener('init-immediate', () =>
  document.querySelectorAll('.polaroid-header').forEach(element => {
    element.instance = element.instance || new PolaroidHeaderComponent(element)
  })
)
