import Component from '../../../assets/scripts/modules/component'

export default class ArticleCookiesComponent extends Component {
  init() {
    this.buttonChange = this.element.querySelector('.button--change')
    this.buttonClear = this.element.querySelector('.button--clear')

    this.buttonChange.addEventListener('click', event => {
      event.preventDefault()
      window.dispatchEvent(new CustomEvent('show-cookie-details'))
    })

    this.buttonClear.addEventListener('click', event => {
      event.preventDefault()
      window.dispatchEvent(new CustomEvent('clear-cookie-settings'))
    })
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.article-cookies').forEach(element => {
    element.instance = element.instance || new ArticleCookiesComponent(element)
  })
)
