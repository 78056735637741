import Component from '../../../assets/scripts/modules/component'
import insertScript from '../../../assets/scripts/utilities/insert-script'
import randomHash from '../../../assets/scripts/utilities/random-hash'
import { getCookie } from '../../../assets/scripts/utilities/simple-cookies'

// NOTE: Default cookie name has been changed from `cookies-accepted` to `cookie-consent`! -EKL
const VIDEO_UNSTARTED = -1
const VIDEO_ENDED = 0
const VIDEO_PLAYING = 1
const VIDEO_PAUSED = 2
const VIDEO_BUFFERING = 3
const VIDEO_CUED = 5

export default class VideoEmbedComponent extends Component {
  init() {
    this.container = this.element.querySelector('.video-embed__container')
    this.hitTarget = this.element.querySelector('.video-embed__hit-target')
    this.autoplay = this.element.classList.contains('video-embed--autoplay')
    this.videoId = this.element.getAttribute('data-video-id')

    // Video player state -RVP
    this.videoWasFastForwarded = false
    this.currentVideoState = VIDEO_UNSTARTED

    // Tracking states -RVP
    this.trackingInterval = null
    this.prevCurrentTime = 0
    this.deltaAfterSkip = 0
    this.progressTrack = { time: 0, percentage: 0, tracked: false }

    if (!this.container || !this.hitTarget || !this.videoId) {
      return
    }

    this.iframeId = `youtube-iframe-${randomHash()}`
    this.player = null

    window.addEventListener('cookie-consent', () => this.checkCookie())
    this.checkCookie()

    window.addEventListener('youtube-video-replay', event => this.player.playVideo())
  }

  initVideo() {
    this.hitTarget.addEventListener('click', this.onHitTargetClick.bind(this))

    if (this.autoplay) {
      window.addEventListener('init-delayed-load', event => {
        this.hitTarget.click(event)
      })
    }
  }

  onHitTargetClick(event) {
    event.preventDefault()

    if (this.player) {
      this.player.playVideo()
    } else if (window.YT) {
      this.insertYouTubeContainer()
    } else {
      insertScript('youtube-api-script', '//www.youtube.com/iframe_api', this.insertYouTubeContainer.bind(this))
    }
  }

  insertYouTubeContainer() {
    const div = document.createElement('div')

    div.id = this.iframeId
    div.className = 'video-embed__iframe'

    this.hitTarget.parentNode.replaceChild(div, this.hitTarget)
    this.hitTarget = null

    const afterLoad = () => {
      this.player = new window.YT.Player(this.iframeId, {
        host: 'https://www.youtube.com',
        width: 640,
        height: 360,
        videoId: this.videoId,
        // enablejsapi: 1, // Unfortunately we can't auto-track with GA , so no reason to enable JS API -RVP
        playerVars: {
          autoplay: 1,
          hd: 1,
          showinfo: 0,
          modestbranding: 1,
          iv_load_policy: 3,
          rel: 0,
          origin: `${window.location.protocol}//${window.location.host}`,
        },
        events: {
          onReady: () => {
            this.element.classList.add('video-embed--loaded')
            this.player.setPlaybackQuality('hd1080')
            this.currentVideoState = VIDEO_CUED

            if (this.element.getAttribute('data-autoplay') !== null) {
              this.player.playVideo()
            }
          },
          onStateChange: this.onStateChange.bind(this),
        },
      })

      window.youtubeLoaded = true
    }

    if (!window.youtubeLoaded) {
      window.onYouTubeIframeAPIReady = afterLoad
    } else {
      afterLoad()
    }
  }

  // State changes for GA and for showing next up video at the end of video play -RVP
  onStateChange(e) {
    if (!this.player) return

    window.dataLayer = window.dataLayer || []
    const state = e.data
    const currentTime = this.player.getCurrentTime()
    const duration = this.player.getDuration()
    const deltaT = currentTime - this.prevCurrentTime

    // Default tracking data -RVP
    const trackingData = {
      video_current_time: Math.floor(currentTime),
      video_duration: Math.floor(duration),
      video_percent: +(currentTime / duration).toFixed(2),
      video_provider: 'YouTube',
      video_title: this.player.videoTitle,
      video_url: this.player.getVideoUrl(),
    }

    // Playing states -RVP
    const isReplaying = state === VIDEO_PLAYING && this.currentVideoState === VIDEO_ENDED
    const isFastForwardFromPauze = state === VIDEO_PLAYING && deltaT > 1
    const isFastForwardFromPlay = state === VIDEO_BUFFERING && this.currentVideoState === VIDEO_PAUSED
    const isFastForwardFromKeyboard = state === VIDEO_BUFFERING && this.currentVideoState === VIDEO_PLAYING

    const isResuming = (state === VIDEO_PLAYING && this.currentVideoState === VIDEO_PAUSED) || (state === VIDEO_PLAYING && this.videoWasFastForwarded)

    const isFirstPlay = (state === VIDEO_PLAYING && this.currentVideoState === VIDEO_CUED) || (state === VIDEO_PLAYING && this.currentVideoState === VIDEO_UNSTARTED) || (state === VIDEO_PLAYING && this.currentVideoState === VIDEO_BUFFERING)

    if (isFirstPlay && !this.videoWasFastForwarded) {
      window.dataLayer.push({
        event: 'video_start',
        ...trackingData,
      })
    }

    if (isResuming && !this.videoWasFastForwarded && !isFastForwardFromPauze) {
      window.dataLayer.push({
        event: 'video_resume',
        ...trackingData,
      })
    }

    if (isFastForwardFromPlay || isFastForwardFromPauze || isFastForwardFromKeyboard) {
      const skipTime = Math.floor(isFastForwardFromPlay ? this.deltaAfterSkip : deltaT)
      window.dataLayer.push({
        event: 'video_fast_forward', // There is no way to differentiate fastforward during play and skip during play other than entering this if statement and checking delta time -RVP
        video_skip_time: skipTime,
        ...trackingData,
      })
    }

    if (isReplaying) {
      window.dataLayer.push({
        event: 'video_replay',
        ...trackingData,
      })
    }

    if (state === VIDEO_PAUSED) {
      this.deltaAfterSkip = currentTime - this.progressTrack.time
      window.dataLayer.push({
        event: 'video_pause',
        ...trackingData,
      })
    }

    if (state === VIDEO_ENDED) {
      window.dispatchEvent(new CustomEvent('youtube-video-ended')) // Show next up video if it exists -RVP

      window.dataLayer.push({
        event: 'video_complete',
        ...trackingData,
      })
    }

    // Start interval to track progress, 4x per second -RVP
    if (state === VIDEO_PLAYING) {
      // eslint-disable-next-line smells/no-setinterval
      this.trackingInterval = setInterval(() => {
        const trackedTime = this.player.getCurrentTime()
        const percentage = +(trackedTime / duration).toFixed(2)

        // To prevent sending multiple dataLayer pushes for a certain percentage, track if progress event was sent for a given percentage -RVP

        // If progress hasn't been tracked, send dataLayer and mark current progress as tracked -RVP
        if (!this.progressTrack.tracked && (percentage === 0.25 || percentage === 0.5 || percentage === 0.75 || percentage === 0.9)) {
          window.dataLayer.push({
            event: 'video_progress',
            ...trackingData,
            video_current_time: trackedTime,
            video_percent: percentage,
          })
          this.progressTrack = { percentage, tracked: true }

          // If progress was tracked and current video playthrough percentage has moved past the previous tracked percentage, unflag as tracked for the next upcoming percentage -RVP
        } else if (this.progressTrack.tracked && percentage > this.progressTrack.percentage) {
          this.progressTrack = { percentage, tracked: false }
        }

        this.progressTrack.time = trackedTime
      }, 250)

      // Clear interval if the video is not playing -RVP
    } else {
      clearInterval(this.trackingInterval)
    }

    // Update state -RVP
    this.prevCurrentTime = currentTime
    this.videoWasFastForwarded = isFastForwardFromPlay || isFastForwardFromKeyboard
    this.currentVideoState = state
  }

  checkCookie() {
    const cookie = getCookie('cookie-consent')

    if (!cookie || cookie.split(',').indexOf('embeds') === -1) {
      this.showCookieNotice()
    } else {
      this.hideCookieNotice()
    }
  }

  showCookieNotice() {
    this.element.classList.add('video-embed--cookies-not-accepted')
  }

  hideCookieNotice() {
    this.element.classList.remove('video-embed--cookies-not-accepted')
    this.initVideo()
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.video-embed')) {
    element.instance = element.instance || new VideoEmbedComponent(element)
  }
})
