import { customEventNames } from '../../../utils/custom-event-names'

const fetchNewGrid = async () => {
  let url = new URL(window.location.href)
  const currentParams = new URLSearchParams(url.search)
  if (currentParams.size > 0) {
    url = window.location.href + '&format=json'
  } else {
    url = window.location.href + '?format=json'
  }
  const cardsGrid = document.querySelector('.card-grid')

  cardsGrid.classList.add('card-grid--loading')

  const response = await fetch(url)
  const data = await response.json()

  const selectedFilters = document.querySelector('.selected-filters-bar')
  selectedFilters.outerHTML = data['selected-filters']

  cardsGrid.outerHTML = data['card-grid']

  window.dispatchEvent(new Event('init-load'))
}

window.addEventListener(customEventNames.whatsOnUpdateGrid, fetchNewGrid)
