import Component from '../../../assets/scripts/modules/component'

class MemberTierSelectorComponent extends Component {
  init() {
    this.dropdown = this.element.querySelector('.member-tier-selector__dropdown')
    this.redirectUrl = this.element.dataset.redirectUrl
    this.redirectQueryParam = this.element.dataset.redirectQueryParam

    this.dropdown.addEventListener('change', e => {
      window.location.replace(`${this.redirectUrl}?${this.redirectQueryParam}=${e.target.value}`)
    })
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.member-tier-selector').forEach(element => {
    element.instance = element.instance || new MemberTierSelectorComponent(element)
  })
)
