import Component from '../../../assets/scripts/modules/component'

const SWIPER_OPTIONS = {
  autoPlay: false,
  controlBy: 'container',
  direction: 'horizontal',
  effect: 'slide',
  grabCursor: true,
  initialSlide: 0,
  loop: false,
  loopAdditionalSlides: 0,
  navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' },
  pagination: false,
  paginationClickable: true,
  preloadImages: false,
  resistanceRatio: 0,
  roundLengths: true,
  slidesPerView: 1,
  spaceBetween: 0,
  threshold: 5,
  touchRatio: 1,
  watchOverflow: true,
  centeredSlides: false,
}

export default class SimpleCarousel extends Component {
  async init() {
    await this.loadSwiper()
  }

  async loadSwiper() {
    const { Swiper, A11y, Navigation, Pagination } = (await import('../../../assets/scripts/plugins/swiper')).default()

    this.observer = null
    this.container = this.element.querySelector('.swiper-container')

    this.slidesPerView = this.element.getAttribute('data-slides-per-view') || '1'
    this.labelPrevious = this.element.getAttribute('data-label-previous') || ''
    this.labelNext = this.element.getAttribute('data-label-next') || ''

    let swiperOptions = {
      ...SWIPER_OPTIONS,
      navigation: {
        nextEl: this.element.querySelector('.swiper-button-next'),
        prevEl: this.element.querySelector('.swiper-button-prev'),
      },
      slidesPerView: this.slidesPerView,
      a11y: { prevSlideMessage: this.labelPrevious, nextSlideMessage: this.labelNext },
    }

    if ([...this.container.querySelectorAll('.swiper-slide')].length < +this.slidesPerView) {
      swiperOptions = { ...swiperOptions, noSwipe: true, loop: false }
      this.container.querySelectorAll('.swiper-button-prev, .swiper-button-next').forEach(button => button.parentNode.removeChild(button))
    }

    this.swiper = new Swiper(this.container, { ...swiperOptions, modules: [A11y, Navigation, Pagination] })
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.simple-carousel').forEach(element => {
    element.instance = element.instance || new SimpleCarousel(element)
  })
)
