import Component from '../../../assets/scripts/modules/component'

class AccordeonHeaderComponent extends Component {
  init() {
    this.disclosures = [...this.element.querySelectorAll('.accordeon-header__disclosure-content-container')]

    this.initAllDisclosures()
  }

  initAllDisclosures() {
    this.disclosures.forEach(disclosureRegion => this.initDisclosure(disclosureRegion))
  }

  closeAllDisclosures() {
    this.disclosures.forEach(disclosureRegion => this.toggleRegion(disclosureRegion, disclosureRegion.parentNode.querySelector('.button--show-more'), true))
  }

  initDisclosure(disclosureRegion) {
    if (!disclosureRegion) {
      return
    }

    const buttonMore = disclosureRegion.parentNode.querySelector('.button--show-more')
    const buttonLess = disclosureRegion.parentNode.querySelector('.button--show-less')

    if (!buttonMore) {
      return
    }

    buttonMore.addEventListener('click', () => this.toggleRegion(disclosureRegion, buttonMore))
    this.setTabIndexes(disclosureRegion, true)

    if (buttonLess) {
      buttonLess.addEventListener('click', () => buttonMore.click())
    }
  }

  toggleRegion(region, button, forceClose = false) {
    if (!region || !button) {
      return
    }

    const isCollapsing = forceClose || button.getAttribute('aria-expanded') === 'true'

    if (!forceClose && !isCollapsing) {
      this.closeAllDisclosures()
    }

    region.setAttribute('aria-hidden', isCollapsing)
    button.setAttribute('aria-expanded', !isCollapsing)

    const card = region.closest('.accordeon-header__card')

    if (card.classList.contains('accordeon-header__card--previous')) {
      card.classList.remove('accordeon-header__card--previous')
    } else if (card.classList.contains('accordeon-header__card--expanded')) {
      const cards = [...card.parentNode.children]
      cards.forEach(card => card.classList.remove('accordeon-header__card--previous'))

      card.classList.add('accordeon-header__card--previous')
    }

    if (card) {
      card.classList.toggle('accordeon-header__card--expanded', !isCollapsing)
    }

    this.setTabIndexes(region, isCollapsing)
    this.setContainerClass()
  }

  setTabIndexes(region, isCollapsed) {
    const focusableElements = [...region.querySelectorAll('a[href], button, input, select, textarea')]
    focusableElements.forEach(element => (isCollapsed ? element.setAttribute('tabindex', -1) : element.removeAttribute('tabindex')))
  }

  setContainerClass() {
    const container = this.element.querySelector('.accordeon-header__cards')

    if (!container) {
      return
    }

    const expandedRegion = this.element.querySelector('.accordeon-header__card--expanded')

    container.classList.toggle('accordeon-header__cards--has-expanded-card', !!expandedRegion)
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.accordeon-header').forEach(element => {
    element.instance = element.instance || new AccordeonHeaderComponent(element)
  })
)
