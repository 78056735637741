import Component from '../../../assets/scripts/modules/component'
import LoaderComponent from '../../molecules/loader/loader'

class FilterHeaderComponent extends Component {
  init() {
    this.loader = null
    this.initDynamicBackButton()
    this.initLoader()
  }

  initDynamicBackButton() {
    this.buttonBack = this.element.querySelector('button.button--back')
    this.buttonBack?.addEventListener('click', () => window.history.back())
  }

  initLoader() {
    this.element.querySelectorAll('ul a').forEach(element =>
      element.addEventListener('click', event => {
        event?.preventDefault()
        this.showLoader()
        window.history.replaceState(null, null, element.href)
        window.history.go()
      })
    )
  }

  showLoader() {
    document.documentElement.classList.add('prevent-scrolling')
    this.loader = this.loader ?? new LoaderComponent(this.element, true)
  }

  hideLoader() {
    this.loader.destroy()
    this.loader = null
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.filter-header').forEach(element => {
    element.instance = element.instance || new FilterHeaderComponent(element)
  })
)
