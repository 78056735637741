import Component from '../../../assets/scripts/modules/component'
import { initialize, processPayment } from '../../../assets/scripts/modules/google-pay'
import LoaderComponent from '../../molecules/loader/loader'

export default class TicketsPaymentControllerComponent extends Component {
  init() {
    this.loader = null
    this.initGooglePay()
  }

  async initGooglePay() {
    await initialize({})

    this.paymentButton = document.querySelector('.tickets-summary__button')
    this.paymentButton.addEventListener('click', event => this.onPaymentButtonClick(event))
  }

  async onPaymentButtonClick(event) {
    if (event) {
      event.preventDefault()
    }

    const paymentMethodRadio = document.querySelector('input[name="payment-methods"]:checked')
    const paymentMethod = paymentMethodRadio.value

    if (!paymentMethod) {
      // TODO: Should add validation message? -EKL
      console.error(new Error('No payment method selected'))
      return
    }

    this.showLoader()

    if (paymentMethod === 'creditcard') {
      window.Checkout?.showPaymentPage()
      this.paymentButton.dispatchEvent(new CustomEvent('ticket-payment-confirm', { detail: 'Credit Card' })) // GTM
    } else if (paymentMethod === 'applepay') {
      window.onApplePayButtonClicked() // Call external Apple Pay API function
      this.paymentButton.dispatchEvent(new CustomEvent('ticket-payment-confirm', { detail: 'Apple Pay' })) // GTM
    } else if (paymentMethod === 'googlepay') {
      this.paymentButton.dispatchEvent(new CustomEvent('ticket-payment-confirm', { detail: 'Google Pay' })) // GTM

      const responseData = await processPayment(this.element.getAttribute('data-amount'), this.element.getAttribute('data-mcsession')).catch(err => console.error(err))

      // This first step updates the Session on MPGS. 2nd step is initiate authentication, 3rd authenticate payer and after that we redirect to mpgs with a form. Finally we do a pay operation if all is well -MKR
      if (!responseData) {
        console.error(new Error('No payment response - user cancelled?'))
        this.hideLoader()
        // TODO: Should add validation message? -EKL
        return
      }

      const formData = new FormData()

      formData.append('mc_session', this.element.getAttribute('data-mcsession'))
      formData.append('googlepay_token', window.googletoken)

      try {
        const response = await fetch('/tickets/api/payment/googlepay/initiate_authentication/', { method: 'POST', body: formData })
        const responseData = await response.json()
        const recommendation = responseData.result.response.gatewayRecommendation

        if (recommendation === 'PROCEED') {
          this.googlepayAuthenticatePayer(responseData.result)
        }

        if (recommendation === 'DO_NOT_PROCEED') {
          this.hideLoader()
        }

        return true
      } catch (error) {
        console.log(error)
      }
    }
  }

  async googlepayAuthenticatePayer(response) {
    if (response.authentication.redirectHtml) {
      // Add this redirecthtml content in the last div on the page to communicate with MPGS -WWA
      // const newDiv = document.createElement('div')
      // newDiv.insertAdjacentHTML('beforeend', response.authentication.redirectHtml)
      // document.body.appendChild(newDiv)
      // eval(document.getElementById('initiate-authentication-script').text) // CSP needs to allow this for now -WWA
      // Circumvent CSP and crearte the form/iframe post -WWA

      const form = document.getElementById('gpay_init_auth')
      form.action = response.authentication.redirect.customized['3DS'].methodUrl
      const threedsData = document.getElementById('threeds_data')
      threedsData.value = response.authentication.redirect.customized['3DS'].methodPostData
      form.submit() // post the form into the iframe to init this stuff at MPGS -WWA

      const formData = new FormData()
      formData.append('mc_session', this.element.getAttribute('data-mcsession'))
      formData.append('googlepay_token', window.googletoken)
      formData.append('transaction_id', response.transaction.id)
      formData.append('user_agent', window.navigator.userAgent)
      formData.append('screen_height', window.screen.height)
      formData.append('screen_width', window.screen.width)

      const currentTime = new Date()
      const minutesoffset = currentTime.getTimezoneOffset()

      formData.append('utc_offset', minutesoffset)

      try {
        const response = await fetch('/tickets/api/payment/googlepay/authenticate_payer/', { method: 'POST', body: formData })
        const responseData = await response.json()
        const recommendation = responseData.result.response.gatewayRecommendation

        if (recommendation === 'PROCEED') {
          const form = document.getElementById('gpay_redirect')
          form.action = responseData.result.authentication.redirect.customized['3DS'].acsUrl
          const inputCreq = document.getElementById('creq_value')
          inputCreq.value = responseData.result.authentication.redirect.customized['3DS'].cReq
          form.submit() // Init redirect to mpgs -WWA
        }

        if (recommendation === 'DO_NOT_PROCEED') {
          this.hideLoader()
        }

        return true
      } catch (error) {
        console.log(error)
      }
    }
  }

  showLoader() {
    const loaderContainer = document.querySelector('.tickets-payment-methods')
    this.loader = this.loader ?? new LoaderComponent(loaderContainer)
  }

  hideLoader() {
    this.loader.destroy()
    this.loader = null
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.tickets-payment-controller').forEach(element => {
    element.instance = element.instance || new TicketsPaymentControllerComponent(element)
  })
)
