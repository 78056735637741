import Component from '../../../assets/scripts/modules/component'

export default class TicketsNavBarComponent extends Component {
  init() {
    this.observer = null
    this.activeElements = this.element.querySelectorAll('.tickets-nav-bar__item--link')
    this.list = this.element.querySelector('.tickets-nav-bar__items')

    if (this.activeElements && this.activeElements.length > 0 && this.list) {
      this.list.scrollLeft = this.activeElements[this.activeElements.length - 1].offsetLeft
    }
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.tickets-nav-bar').forEach(element => {
    element.instance = element.instance || new TicketsNavBarComponent(element)
  })
)
