import Component from '../../../assets/scripts/modules/component'
import { getCookie, setCookie } from '../../../assets/scripts/utilities/simple-cookies'

export default class NotificationBarComponent extends Component {
  init() {
    const shown = getCookie('notification-bar-dismissed')

    if (shown) {
      return
    }

    this.element.classList.add('notification-bar--visible')

    if (this.element.querySelector('.button--close') !== null) {
      this.element.querySelector('.button--close').addEventListener('click', () => {
        this.element.classList.remove('notification-bar--visible')
        setCookie('notification-bar-dismissed', true, 0)
      })
    }
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.notification-bar').forEach(element => {
    element.instance = element.instance || new NotificationBarComponent(element)
  })
)
