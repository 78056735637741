import Component from '../../../assets/scripts/modules/component'

class TableOfContentsComponent extends Component {
  init() {
    this.isNMOQ = document.querySelector('body').classList.contains('subsite--nmoq')
    this.buttons = [...this.element.querySelectorAll('.button[href^="#"]')]

    if (!this.buttons.length) {
      return
    }

    this.initButtons()

    this.element.querySelector('.table-of-contents__buttons > .button:first-of-type').classList.add('is-first')
    this.element.querySelector('.table-of-contents__buttons > .button:last-of-type').classList.add('is-last')
  }

  initButtons() {
    this.buttons.forEach(button => button.addEventListener('click', event => this.scrollToTarget(event, button.href.split('#')[1])))
  }

  scrollToTarget(event, id) {
    if (event) {
      event.preventDefault()
    }

    const target = document.getElementById(id)

    if (!target) {
      return
    }

    const scrollPositionTarget = target.getBoundingClientRect().top

    window.scroll({ top: window.pageYOffset + scrollPositionTarget, left: 0, behavior: 'smooth' })
    target?.focus()
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.table-of-contents').forEach(element => {
    element.instance = element.instance || new TableOfContentsComponent(element)
  })
)
