import breakpoints from '../../../assets/scripts/modules/breakpoints'
import Component from '../../../assets/scripts/modules/component'

// Calculate scrolling distance based on font size and screen width -EKL
const calculateScrollDistance = element => {
  if (!element) {
    return 0
  }

  let value = parseInt(window.getComputedStyle(element).fontSize, 10)

  if (breakpoints.isDesktopLarge()) {
    value *= 2
  } else if (breakpoints.isDesktopOnly()) {
    value *= 4
  } else if (breakpoints.isNotebookOnly()) {
    value *= 7
  } else if (breakpoints.isLandscapeOnly()) {
    value *= 11
  } else if (breakpoints.isPortraitOnly()) {
    value *= 16
  } else {
    value *= 22
  }

  return value
}

export default class TickerBarComponent extends Component {
  init() {
    window.addEventListener('gsap-loaded', () => this.initScrollTween())
    this.reduceMotion = window.matchMedia('(prefers-reduced-motion: reduce)')
  }

  initScrollTween() {
    if (!this.reduceMotion.matches) {
      this.writingDirection = getComputedStyle(document.body).direction

      this.initTitleTween()

      if (document.body.classList.contains('subsite--nmoq') || document.body.classList.contains('subsite--mathaf') || document.body.classList.contains('subsite--liwan') || document.body.classList.contains('subsite--tasweer') || document.body.classList.contains('subsite--firestation') || document.body.classList.contains('subsite--rubaiyat')) {
        this.initUspsTween()
      }
    }
  }

  initTitleTween() {
    const wrapper = this.element.querySelector('.ticker-bar__title-wrapper')
    const inner = this.element.querySelector('.ticker-bar__title')

    if (!wrapper || !inner) {
      return
    }

    // Clone content -EKL
    const children = [...inner.children]

    for (let i = 0, j = 10; i < j; i++) {
      children.forEach(child => inner.appendChild(child.cloneNode(true)))
    }

    // To work around ScrollTrigge bug -EKL
    inner.style.textDirection = 'ltr'

    // Calculate scroll distance - adjust values for the right feel -EKL
    let start = () => -(calculateScrollDistance(inner) * 0.25)
    let end = () => -(calculateScrollDistance(inner) * 0.75)

    if (this.writingDirection === 'rtl') {
      start = () => calculateScrollDistance(inner) * 0.25
      end = () => calculateScrollDistance(inner) * 0.75
    }

    // Set tween -EKL
    window.GSAP.set(inner, { force3D: true }) // We're gonna be needing the GPU real soon -EKL
    window.GSAP.fromTo(inner, { x: start }, { x: end, scrollTrigger: { trigger: wrapper, scrub: 0.15 }, ease: 'linear' })
  }

  initUspsTween() {
    const wrapper = this.element.querySelector('.ticker-bar__usps-wrapper')
    const inner = this.element.querySelector('.ticker-bar__usps-list')

    if (!wrapper || !inner) {
      return
    }

    // Clone content -EKL
    const children = [...inner.children]

    for (let i = 0, j = 10; i < j; i++) {
      children.forEach(child => inner.appendChild(child.cloneNode(true)))
    }

    // Calculate scroll distance - adjust values for the right feel -EKL
    let start = () => -(calculateScrollDistance(inner) * 5)
    let end = () => -(calculateScrollDistance(inner) * 2.5)

    if (this.writingDirection === 'rtl') {
      start = () => calculateScrollDistance(inner) * 5
      end = () => calculateScrollDistance(inner) * 2.5
    }

    // Set tween -EKL
    window.GSAP.set(inner, { force3D: true }) // We're gonna be needing the GPU real soon -EKL
    window.GSAP.fromTo(inner, { x: start }, { x: end, scrollTrigger: { trigger: wrapper, scrub: 0.15, ease: 'linear' } })
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.ticker-bar').forEach(element => {
    element.instance = element.instance || new TickerBarComponent(element)
  })
)
