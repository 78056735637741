import Component from '../../../assets/scripts/modules/component'

class TextFieldComponent extends Component {
  init() {
    if (this.element.classList.contains('text-field--uppercase')) {
      this.element.addEventListener('keypress', event => this.forceUppercase(event))
      this.element.addEventListener('input', event => this.pasteUppercase(event))
    }
  }

  forceUppercase(event) {
    const charInput = event.keyCode

    if (charInput >= 97 && charInput <= 122) {
      if (!event.ctrlKey && !event.metaKey && !event.altKey) {
        const newChar = charInput - 32
        const start = event.target.selectionStart
        const end = event.target.selectionEnd

        event.target.value = event.target.value.substring(0, start) + String.fromCharCode(newChar) + event.target.value.substring(end)
        event.target.setSelectionRange(start + 1, start + 1)

        event.preventDefault()
      }
    }
  }

  pasteUppercase(event) {
    const start = event.target.selectionStart
    const end = event.target.selectionEnd

    event.target.value = event.target.value.toUpperCase()
    event.target.selectionStart = start
    event.target.selectionEnd = end
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.text-field--uppercase').forEach(element => {
    element.instance = element.instance || new TextFieldComponent(element)
  })
)
