import breakpoints from '../../../assets/scripts/modules/breakpoints'
import { customEventNames } from '../../../utils/custom-event-names'

window.addEventListener('init-load', async () => {
  const CUSTOM_FORMAT = 'DD-MM-YYYY' // Datepicker uses this format internally -RVP
  const element = document.querySelector('.datepicker')

  if (!element) {
    return
  }

  const { React, ReactDOM } = (await import('../../../assets/scripts/plugins/react')).default()
  const { DayPickerSingleDateController, moment, toMomentObject } = (await import('../../../assets/scripts/plugins/react-dates-and-moment')).default()

  // Helper function to determine if date is inside given date range -RVP
  const isDayOutsideRange = (day, fromDate, toDate) => {
    return !moment(day, CUSTOM_FORMAT).isBetween(fromDate, toDate, 'day', '[]')
  }

  class DayPickerSingleDateControllerWrapper extends React.Component {
    constructor(props) {
      super(props)
      const urlParams = new URLSearchParams(window.location.search)
      const date = urlParams.get('date') || urlParams.get('from_date')
      const initalDate = date ? date : props.dataSet.initialDate

      this.state = {
        focused: true,
        date: initalDate ? moment(initalDate) : null,
        isRTL: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl',
      }

      this.onDateChange = this.onDateChange.bind(this)
      this.onFocusChange = this.onFocusChange.bind(this)
      this.responsivityHandler = this.responsivityHandler.bind(this)
      this.inputElement = document.getElementById(props.dataSet.inputId)

      window.addEventListener('clear-select-date-selection', () => {this.setState({ date: null })})
      window.addEventListener('reset-select-date-selection-preset', () => {this.setState({ date: initalDate ? moment(initalDate) : null })})
    }

    componentDidMount() {
      this.responsivityHandler()
      window.addEventListener('resize', this.responsivityHandler)
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.responsivityHandler)
    }

    responsivityHandler() {
      if (window.innerWidth < breakpoints.LANDSCAPE) {
        this.setState({ responsiveState: 'small' })
      } else if (window.innerWidth < breakpoints.NOTEBOOK) {
        this.setState({ responsiveState: 'medium' })
      } else {
        this.setState({ responsiveState: 'large' })
      }
    }

    onDateChange(date) {
      this.setState({ date })

      const { dateFormat } = this.props.dataSet

      if (date && this.inputElement) {
        this.inputElement.value = date.format(dateFormat || CUSTOM_FORMAT)
        this.inputElement.dispatchEvent(new Event('change'))
      }
    }

    onFocusChange() {
      // Force the focused states to always be truthy so that date is always selectable -PVI
      this.setState({ focused: true })
    }

    onSearchClick({ event, date }) {
      event.preventDefault()

      const urlParams = new URLSearchParams(location.search)

      const datePickerSingleInput = window.document.querySelector('.datepicker-single-field')
      if (datePickerSingleInput) {
        urlParams.set('date', moment(date).year() + '-' + (parseInt(moment(date).month()) + 1) + '-' + moment(date).date())
        window.history.pushState({}, '', `${location.pathname}?${urlParams}`)
        window.requestAnimationFrame(() => datePickerSingleInput.dispatchEvent(new CustomEvent('disableFocusTrap')))
      }
      const datePickerDropdown = window.document.querySelector('.datepicker-dropdown__panel')
      if (datePickerDropdown) {
        urlParams.set('from_date', moment(date).year() + '-' + (parseInt(moment(date).month()) + 1) + '-' + moment(date).date())
        urlParams.set('to_date', moment(date).year() + '-' + (parseInt(moment(date).month()) + 1) + '-' + moment(date).date())
        urlParams.set('period', 'select-date')
        window.history.pushState({}, '', `${location.pathname}?${urlParams}`)
        datePickerDropdown.querySelector('.datepicker-dropdown__calendar-container').ariaHidden = true

        dispatchEvent(new CustomEvent(customEventNames.whatsOnUpdateGrid))
        window.requestAnimationFrame(() => datePickerDropdown.dispatchEvent(new CustomEvent('disableFocusTrap')))
      }
    }

    onReset() {
      if (this.inputElement) {
        this.inputElement.value = ''
      }
      this.setState({ date: null })
      const datePickerDropdown = window.document.querySelector('.datepicker-dropdown')
      if (datePickerDropdown) {
        datePickerDropdown.querySelector('.datepicker-dropdown__date-container').ariaHidden = true
        window.requestAnimationFrame(() => datePickerDropdown.dispatchEvent(new CustomEvent('disableFocusTrap')))
      }
    }

    render() {
      const { focused, date, responsiveState, isRTL } = this.state
      const { numberOfMonths, dateRangeFrom, dateRangeTo, clearLabel, applyLabel, showButtons } = this.props.dataSet

      // Sets limit range for datepicker -RVP
      const limitFrom = toMomentObject(dateRangeFrom || moment(), CUSTOM_FORMAT) // Limit start date or today -RVP
      const limitTo = toMomentObject(dateRangeTo || moment().add(2, 'year'), CUSTOM_FORMAT) // Limit end date or today + 2 years -RVP

      return (
        <>
          <div className="datepicker__calendar-wrapper">
            <DayPickerSingleDateController onDateChange={this.onDateChange} onFocusChange={this.onFocusChange} numberOfMonths={(!responsiveState || responsiveState === 'large') && Number(numberOfMonths) === 2 ? 2 : 1} focused={focused} date={date} noBorder={true} orientation="horizontal" daySize={responsiveState === 'small' ? 40 : 50} hideKeyboardShortcutsPanel={true} isRTL={isRTL} isOutsideRange={day => isDayOutsideRange(day, limitFrom, limitTo)} />
          </div>
          {showButtons === 'true' && (
            <div className="datepicker__buttons-wrapper">
              <button type="button" className="button button--clean" onClick={() => this.onReset()}>
                <span className="button__span">{clearLabel}</span>
              </button>

              <button className="button button--tertiary" onClick={event => this.onSearchClick({ event, date })} disabled={!date}>
                <span className="button__span">{applyLabel}</span>
              </button>
            </div>
          )}
        </>
      )
    }
  }

  document.querySelectorAll('.datepicker').forEach(element => {
    moment.locale('en-gb')

    if (element.dataset.locale === 'ar') {
      moment.locale('ar')
    }

    element.addEventListener('activate', () => {
      ReactDOM.render(<DayPickerSingleDateControllerWrapper dataSet={element.dataset} element={element} />, element)
    })
  })
})
