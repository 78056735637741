import Component from '../../../assets/scripts/modules/component'

export default class TestDuetDatepickerComponent extends Component {
  init() {
    this.isOpen = false

    this.initButtons()
  }

  initButtons() {
    this.buttonOpen = this.element.querySelector('.button--open')
    this.datepickerContainer = this.element.querySelector('.duet-datepicker')

    this.buttonOpen?.addEventListener('click', event => this.handleButton(event, this.buttonOpen))
  }

  handleButton(event, button) {
    if (event) {
      event.preventDefault()
    }

    if (!this.datepickerContainer || !this.datepickerContainer.picker) {
      return
    }

    this.isOpen ? this.datepickerContainer.picker.hide() : this.datepickerContainer.picker.show()
    this.isOpen = !this.isOpen
  }
}

window.addEventListener('init-load', () =>
  [...document.querySelectorAll('.test-duet-datepicker')].forEach(element => {
    element.instance = element.instance || new TestDuetDatepickerComponent(element)
  })
)
