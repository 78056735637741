import insertScript from '../utilities/insert-script-async'

// Google Pay Wrapper
// see https://developers.google.com/pay/api/web/reference/

let paymentsClient = null // An initialized google.payments.api.PaymentsClient object or null if not yet set

const payEnvironment = document.getElementById('payment_data_object')?.getAttribute('data-payment-env')
const GmerchantID = document.getElementById('payment_data_object')?.getAttribute('data-gpay-prdmid')

let config = {
  // Google Pay API version
  baseRequest: { apiVersion: 2, apiVersionMinor: 0 },

  baseCardPaymentMethod: {
    type: 'CARD',
    parameters: {
      // Authentication methods and networks supported by your site and gateway
      allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
      allowedCardNetworks: ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA']
    }
  },

  // Describe your site's support for the CARD payment method (optional fields)
  cardPaymentMethod: {
    // Identify your gateway and your site's gateway merchant identifier.
    // The Google Pay API response will return an encrypted payment method capable of being charged by a supported gateway after payer authorization
    tokenizationSpecification: {
      type: 'PAYMENT_GATEWAY',
      parameters: {
        gateway: 'mpgs', // mastercard platform
        gatewayMerchantId: document.getElementById('payment_data_object')?.getAttribute('data-mpgsid') // TODO should we env this?
      }
    }
  },

  // Merchant information
  merchantInfo: {
    // merchantId is available for a production environment after approval by Google.
    // See: https://developers.google.com/pay/api/web/guides/test-and-deploy/integration-checklist|Integration%20checklist
    merchantId: GmerchantID,
    merchantName: 'Qatar Museums'
  },

  // Options set when initializing the google payments client
  paymentsClientOptions: {
    environment: payEnvironment
    // environment: 'PRODUCTION' or TEST
  },

  // Transaction information
  // TODO: Change currencyCode/countryCode/totalPriceStatus accordingly
  transactionInfo: {
    currencyCode: 'QAR',
    countryCode: 'QA',
    totalPriceStatus: 'FINAL',
    totalPrice: document.getElementById('payment_data_object')?.getAttribute('data-amount')
  }
}

// Configure support for the Google Pay API
const paymentDataRequest = {
  ...config.baseRequest,
  allowedPaymentMethods: [{ ...config.baseCardPaymentMethod, ...config.cardPaymentMethod }],
  transactionInfo: { ...config.transactionInfo },
  merchantInfo: config.merchantInfo
}

export async function initialize (customConfig = {}) {
  config = { ...config, ...customConfig }

  if (!window.google || !window.google.payments) {
    await insertScript('gpay', 'https://pay.google.com/gp/p/js/pay.js').catch(err => console.error(err))
  }

  paymentsClient = paymentsClient ?? new window.google.payments.api.PaymentsClient(config.paymentsClientOptions)
  await onGooglePayLoaded()
}

// Initialize Google PaymentsClient after Google-hosted JavaScript has loaded
async function onGooglePayLoaded () {
  const paymentRequest = { ...config.baseRequest, ...{ allowedPaymentMethods: [config.baseCardPaymentMethod] } }
  const response = await paymentsClient.isReadyToPay(paymentRequest).catch(err => console.error(err))

  if (!response || !response.result) {
    return
  }

  paymentsClient.prefetchPaymentData({ ...paymentDataRequest, transactionInfo: { ...config.transactionInfo, totalPriceStatus: 'NOT_CURRENTLY_KNOWN' } })
}

// Process payment data returned by the Google Pay API
export async function processPayment (basketAmount = '', mastercardSessionID = '') {
  const paymentRequest = { ...paymentDataRequest, transactionInfo: { ...config.transactionInfo, totalPrice: basketAmount } }
  const paymentData = await paymentsClient.loadPaymentData(paymentRequest).catch(err => console.error(err))
  const formData = new FormData()

  if (!paymentData) {
    return new Promise((resolve, reject) => reject(new Error('No payment data')))
  }

  window.googletoken = paymentData?.paymentMethodData?.tokenizationData?.token

  formData.append('googlepay_token', paymentData?.paymentMethodData?.tokenizationData?.token)
  formData.append('mc_session', mastercardSessionID)

  const response = await fetch('/tickets/api/payment/googlepay/update_session/', { method: 'POST', body: formData }).catch(error => console.error(error))
  const responseData = await response.json()

  return new Promise((resolve, reject) => {
    if (!responseData) {
      reject(new Error('No data from API'))
    }

    // Resolve if the API returns something, whether its paid or not
    resolve(responseData)
  })
}
