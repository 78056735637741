// Definizione delle classi
class Player {
  constructor(p5Instance, x, y, width, height, ominoLeftSprite, ominoRightSprite, collideRectRect, ominoDownRightSprite, ominoDownLeftSprite) {
    this.x = x
    this.y = y
    this.width = width
    this.height = height
    this.speed = 2.5
    this.sprite = ominoLeftSprite
    this.leftSprite = ominoLeftSprite
    this.rightSprite = ominoRightSprite
    this.downLeftSprite = ominoDownLeftSprite
    this.downRightSprite = ominoDownRightSprite
    this.facingRight = false
    this.p5Instance = p5Instance
    this.collideRectRect = collideRectRect

    // Flag per i movimenti
    this.movingUp = false
    this.movingDown = false
    this.movingLeft = false
    this.movingRight = false

    this.addEventListeners() // Aggiungi gli event listeners
  }

  addEventListeners() {
    // Event listeners per i bottoni
    document.getElementById('up-btn').addEventListener('mousedown', () => {
      this.movingUp = true

      if (this.movingRight) {
        this.sprite = this.rightSprite
      } else {
        this.sprite = this.leftSprite
      }
    })
    document.getElementById('down-btn').addEventListener('mousedown', () => {
      this.movingDown = true

      if (this.movingLeft) {
        this.sprite = this.downLeftSprite
      } else {
        this.sprite = this.downRightSprite
      }
    })
    document.getElementById('left-btn').addEventListener('mousedown', () => {
      this.movingLeft = true
      this.sprite = this.leftSprite
      this.facingRight = false
    })
    document.getElementById('right-btn').addEventListener('mousedown', () => {
      this.movingRight = true
      this.sprite = this.rightSprite
      this.facingRight = true
    })

    // Interrompi movimento quando il pulsante viene rilasciato
    document.getElementById('up-btn').addEventListener('mouseup', () => {
      this.movingUp = false
    })
    document.getElementById('down-btn').addEventListener('mouseup', () => {
      this.movingDown = false
    })
    document.getElementById('left-btn').addEventListener('mouseup', () => {
      this.movingLeft = false
    })
    document.getElementById('right-btn').addEventListener('mouseup', () => {
      this.movingRight = false
    })

    // Touch support (per dispositivi mobili)
    document.getElementById('up-btn').addEventListener('touchstart', e => {
      e.preventDefault()
      this.movingUp = true
    })
    document.getElementById('down-btn').addEventListener('touchstart', e => {
      e.preventDefault()
      this.movingDown = true
    })
    document.getElementById('left-btn').addEventListener('touchstart', e => {
      e.preventDefault()
      this.movingLeft = true
      this.sprite = this.leftSprite
      this.facingRight = false
    })
    document.getElementById('right-btn').addEventListener('touchstart', e => {
      e.preventDefault()
      this.movingRight = true
      this.sprite = this.rightSprite
      this.facingRight = true
    })

    document.getElementById('up-btn').addEventListener('touchend', () => {
      this.movingUp = false
    })
    document.getElementById('down-btn').addEventListener('touchend', () => {
      this.movingDown = false
    })
    document.getElementById('left-btn').addEventListener('touchend', () => {
      this.movingLeft = false
    })
    document.getElementById('right-btn').addEventListener('touchend', () => {
      this.movingRight = false
    })
  }

  display() {
    if (this.sprite && this.x && this.y && this.width && this.height) {
      this.p5Instance.image(this.sprite, this.x, this.y, this.width, this.height)
    }
  }

  move(obstacles) {
    let newX = this.x
    let newY = this.y
    // const moved = false

    // Aggiungi logica per muovere l'omino con i bottoni
    if (this.p5Instance.keyIsDown(this.p5Instance.LEFT_ARROW) || this.movingLeft) {
      newX -= this.speed
      this.sprite = this.leftSprite
      this.facingRight = false
      // moved = true
    }
    if (this.p5Instance.keyIsDown(this.p5Instance.RIGHT_ARROW) || this.movingRight) {
      newX += this.speed
      this.sprite = this.rightSprite
      this.facingRight = true
      // moved = true
    }
    if (this.p5Instance.keyIsDown(this.p5Instance.UP_ARROW) || this.movingUp) {
      newY -= this.speed
      // moved = true

      if (this.movingRight) {
        this.sprite = this.rightSprite
      } else {
        this.sprite = this.leftSprite
      }
    }
    if (this.p5Instance.keyIsDown(this.p5Instance.DOWN_ARROW) || this.movingDown) {
      newY += this.speed
      // moved = true

      if (this.movingLeft) {
        this.sprite = this.downLeftSprite
      } else {
        this.sprite = this.downRightSprite
      }
    }

    // Controlla le collisioni con gli ostacoli
    if (!this.wouldCollide(newX, newY, obstacles)) {
      this.x = newX
      this.y = newY
    } else {
      // Prova a muoverti solo in una direzione se collidi
      if (!this.wouldCollide(this.x, newY, obstacles)) {
        this.y = newY
      } else if (!this.wouldCollide(newX, this.y, obstacles)) {
        this.x = newX
      }
    }
  }

  wouldCollide(x, y, obstacles) {
    for (const obstacle of obstacles) {
      if (this.collideRectRect(x, y, this.width, this.height, obstacle.x, obstacle.y, obstacle.width, obstacle.height)) {
        return true
      }
    }
    if (this.collideRectRect(x, y, this.width, this.height, 0, 0, 0, this.p5Instance.height)) {
      return true
    }
    if (this.collideRectRect(x, y, this.width, this.height, 0, 0, this.p5Instance.width, 0)) {
      return true
    }
    return false
    // }
  }
}

export default Player
