import Component from '../../../assets/scripts/modules/component'

const GUTTER = 5
class StoriesFilterBlockComponent extends Component {
  init() {
    const panel = this.element.querySelector('ul')
    const btn = this.element.querySelector('button')
    const icon = this.element.querySelector('.link-dropdown__button-icon')
    const links = this.element.querySelectorAll('.link-dropdown__panel-item-link')

    // Make disclosure pattern accessible
    const id = `link-panel-${this.index + 1}`
    panel.setAttribute('id', id)
    btn.setAttribute('aria-controls', id)

    const isRtl = () => {
      const direction = window.getComputedStyle(document.body).direction

      return direction === 'rtl'
    }

    const panelIsOpen = () => panel.classList.contains('link-dropdown__panel--open')

    const setInitialPanelValues = () => {
      links.forEach(link => link.classList.remove('link-dropdown__panel-item-link--text-wrap'))

      panel.style.top = btn.getBoundingClientRect().height + 'px'
      panel.style.width = 'fit-content'
      panel.style.left = '0px'
      panel.style.minWidth = btn.getBoundingClientRect().width + 'px'
    }

    const placePanelOnRightPlace = () => {
      const screenWidth = window.innerWidth
      const screenHeight = window.innerHeight

      const panelFallsOfScreen = panel.getBoundingClientRect().right > screenWidth

      if (panelFallsOfScreen) {
        panel.style.left = btn.getBoundingClientRect().width - panel.getBoundingClientRect().width + 'px'
        const panelIsToBigForScreen = panel.getBoundingClientRect().width > btn.getBoundingClientRect().right

        if (panelIsToBigForScreen) {
          panel.style.left = -btn.getBoundingClientRect().left + GUTTER + 'px'
          panel.style.width = screenWidth - GUTTER * 2 + 'px'
          links.forEach(link => link.classList.add('link-dropdown__panel-item-link--text-wrap'))
        }
      }

      const bottomOfPanelIsBelowScreen = panel.getBoundingClientRect().bottom > screenHeight
      if (bottomOfPanelIsBelowScreen) {
        // Based on the RTL margin-block start
        const extraGutter = isRtl() ? 13 : 0
        panel.style.top = -(btn.getBoundingClientRect().top - screenHeight + panel.getBoundingClientRect().height + GUTTER * 2 + extraGutter) + 'px'
      }
    }

    const openPanel = () => {
      panel.classList.add('link-dropdown__panel--open')
      icon.classList.add('link-dropdown__button-icon--open')
      btn.setAttribute('aria-expanded', 'true')
      placePanelOnRightPlace()
    }

    const closePanel = () => {
      icon.classList.remove('link-dropdown__button-icon--open')
      panel.classList.remove('link-dropdown__panel--open')
      btn.setAttribute('aria-expanded', 'false')
      // Reset arrow navigation
      lastIndex = -1
    }

    document.addEventListener('click', e => {
      // Determine these values within this callbacks' scope otherwise it's not right
      setInitialPanelValues()

      if ((btn.contains(e.target) || icon.contains(e.target)) && !panelIsOpen()) {
        openPanel()
      } else if (e.target !== panel && panelIsOpen()) {
        closePanel()
      }
    })

    let lastIndex = -1

    document.addEventListener('keydown', event => {
      if (event.key === 'Escape') {
        closePanel()
      } else if (event.key === 'ArrowUp' && panelIsOpen()) {
        event.preventDefault()
        if (lastIndex !== -1) lastIndex--
        if (lastIndex === -1) lastIndex = links.length - 1
        links[lastIndex].focus()
      } else if (event.key === 'ArrowDown' && panelIsOpen()) {
        event.preventDefault()
        lastIndex++
        if (lastIndex === links.length) lastIndex = 0
        links[lastIndex].focus()
      }
    })

    function _debounce(callback, delay) {
      let timer
      return function () {
        clearTimeout(timer)
        timer = setTimeout(() => {
          callback()
        }, delay)
      }
    }

    window.addEventListener(
      'resize',
      _debounce(() => {
        setInitialPanelValues()
        setTimeout(() => {
          placePanelOnRightPlace()
        }, 200)
      }, 400)
    )
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.link-dropdown').forEach((element, index) => {
    element.instance = element.instance || new StoriesFilterBlockComponent(element, index)
  })
)
