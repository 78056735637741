import Component from '../../../assets/scripts/modules/component'

class OverlayVideoStoryComponent extends Component {
  init() {
    this.initDynamicBackButton()
    this.initVideo()
  }

  initVideo() {
    this.videoEmbed = this.element.querySelector('.article-video-embed')
    this.replayButton = this.element.querySelector('.overlay-story-video__next-video-replay')
    this.nextVideo = this.element.querySelector('.overlay-story-video__next-video-wrapper')

    window.addEventListener('youtube-video-ended', () => this.onVideoEnded())
    this.replayButton?.addEventListener('click', () => {
      this.videoEmbed.setAttribute('aria-hidden', false)
      this.nextVideo.setAttribute('aria-hidden', true)

      window.dispatchEvent(new CustomEvent('youtube-video-replay'))
    })
  }

  initDynamicBackButton() {
    this.buttonBack = this.element.querySelector('button.button--back')
    this.buttonBack?.addEventListener('click', () => window.history.back())
  }

  onVideoEnded() {
    if (!this.nextVideo) return

    this.nextVideo.setAttribute('aria-hidden', false)
    this.videoEmbed.setAttribute('aria-hidden', true)
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.overlay-story-video').forEach(element => {
    element.instance = element.instance || new OverlayVideoStoryComponent(element)
  })
)
