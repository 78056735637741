import Component from '../../../assets/scripts/modules/component'

class ArticleHeaderComponent extends Component {
  init() {
    this.video = this.element.querySelector('.video__video')
    this.reduceMotion = window.matchMedia('(prefers-reduced-motion: reduce)')
    this.initDynamicBackButton()

    this.initVideo()
  }

  initVideo() {
    if (!this.video || this.reduceMotion.matches) {
      this.element.addEventListener('blur', () => this.video.pause())
    }
  }

  initDynamicBackButton() {
    this.buttonBack = this.element.querySelector('button.button--back')
    this.buttonBack?.addEventListener('click', () => window.history.back())
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.article-header').forEach(element => {
    element.instance = element.instance || new ArticleHeaderComponent(element)
  })
)
