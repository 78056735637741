import Component from '../../../assets/scripts/modules/component'

export default class CardHighlightsGridComponent extends Component {
  init() {
    this.button = this.element.querySelector('.card-highlights-grid__button')

    this.button.addEventListener('click', this.handleButton.bind(this))
  }

  handleButton(e) {
    e.preventDefault()
    this.filterBar = document.querySelector('.whats-on-controller__title')

    if (this.filterBar) {
      this.filterBar.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.card-highlights-grid').forEach(element => {
    element.instance = element.instance || new CardHighlightsGridComponent(element)
  })
)
